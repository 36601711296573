import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ReactComponent as DragIndicator } from '../../../static/images/drag_indicator.svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './RestaurantPhotosList.scss';

const RestaurantPhotosList =
    ({
        photos,
        isPhotoCoveredFn,
        onListUpdate,
        onListItemClick,
        photosAreBeingUploaded
    }) => {
        const [ hasDraggingStarted, setHasDraggingStarted ] = useState(false);

        const onDragStart = () => {
            setHasDraggingStarted(true);
        };

        const onDragEnd = result => {
            setHasDraggingStarted(false);

            const { destination, source } = result;

            if (!destination) {
                return;
            }

            if (destination.droppableId === source.droppableId && destination.index === source.index) {
                return;                
            }

            const newPhotos = Array.from(photos);
            const [removed] = newPhotos.splice(source.index, 1);
            newPhotos.splice(destination.index, 0, removed);

            onListUpdate(newPhotos);
        };

        return (
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <Droppable droppableId="droppableContainer">
                    {provided => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {photos.map((item, index) => (
                                photosAreBeingUploaded.indexOf(item.uuid) === -1
                                && <RestaurantPhotosListItem
                                        key={item.uuid}
                                        photo={item}
                                        isPhotoCoveredFn={isPhotoCoveredFn}
                                        index={index}
                                        hasDraggingStarted={hasDraggingStarted}
                                        onListItemClick={onListItemClick}
                                    />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

const RestaurantPhotosListItem =
    ({
        photo,
        isPhotoCoveredFn,
        index,
        hasDraggingStarted,
        onListItemClick
    }) => {
        const [isPhotoCovered, setIsPhotoCovered] = useState(false);

        useEffect(() => {
            var isCovered = isPhotoCoveredFn(photo);
            if (isCovered === isPhotoCovered) {
                return;
            }

            setIsPhotoCovered(isCovered);
        });

        return (
            isPhotoCovered
                ? <CoveredListItem photo={photo} />
                : <DraggableListItem
                    photo={photo}
                    index={index}
                    onClick={onListItemClick}
                    hasDraggingStarted={hasDraggingStarted}
                />
        );
    };

const DraggableListItemActions = ({ dragHandleProps }) => {
    return (
        <div
            className="action drag-action"
            {...dragHandleProps}
        >
            <DragIndicator className="drag-action-image" />
        </div>
    );
};

const DraggableListItem = ({ photo, index, onClick, hasDraggingStarted }) => {
    const getClassNames = isItemDragging => classNames(
        {
            "dragging": isItemDragging,
            "dragging-disabled": hasDraggingStarted && !isItemDragging
        }
    );

    return (
        <Draggable draggableId={photo.uuid} index={index}>
            {(provided, snapshot) => (
                <ListItemTemplate
                    photo={photo}
                    actions={<DraggableListItemActions dragHandleProps={provided.dragHandleProps} />}
                    className={getClassNames(snapshot.isDragging)}
                    onClick={() => onClick(photo.uuid)}
                    innerProps={provided.draggableProps}
                    ref={provided.innerRef}
                />
            )}
        </Draggable>
    );
};

const CoveredListItemIndicators = () => {
    const [t] = useTranslation();

    return (
        <div className="indicator covered-indicator">
            <span className="primary_neon">{t('cover')}</span>
        </div>
    );
};

const CoveredListItem = ({ photo }) => {
    return (
        <ListItemTemplate indicators={<CoveredListItemIndicators />} photo={photo} className="covered"/>
    );
};

const ListItemTemplate = React.forwardRef(
    ({
        photo,
        indicators,
        actions,
        className,
        innerProps,
        onClick
    }, ref) => {
        return (
            <div
                className={classNames("restaurant-photos-list-item roboto fs-16", className)}
                onClick={onClick}
                ref={ref}
                {...innerProps}
            >
                <div className="image">
                    <img src={photo.src} />
                </div>

                <div className="info">{photo.file.name}</div>

                <div className="right-panel">
                    <div className="indicators-panel">
                        {indicators}
                    </div>
                    <div className="actions-panel">
                        {actions}
                    </div>
                </div>
            </div>
        );
    });

export default RestaurantPhotosList;