import AccordionRow from './AccrordionRow';

const SimpleAccordionRowMobile = ({title, children, open, onOpen}) => {
    return (
        <AccordionRow
            title={<span className={`fs-16 bold poppins ${open ? 'additional_grey_1' : 'additional_grey_2'}`}>{title}</span>}
            onOpen={onOpen}
            open={open}
        >
            {children}
        </AccordionRow>
    );
};

export default SimpleAccordionRowMobile;