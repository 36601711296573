import React, {useContext, useEffect, useState} from 'react';
import RestaurantMainInfo from './RestarantMainInfo';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import RestaurantPhotos from './RestaurantPhotos';
import {getRestaurants, Send} from '../../Misc/backend';
import classNames from 'classnames';
import './index.scss';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import {useHistory, useLocation} from 'react-router-dom';
import queryString from 'query-string';
import RestaurantPaymentOptionsWrapper from './RestaurantPaymentOptionsWrapper';
import EmployerInfo from './EmployerInfo';
import {Images} from '../../static/images';
import {Box, StepConnector, StepContent, StepIcon} from '@material-ui/core';
import {EmployerContext} from '../../Contexts/EmployerContext';
import {useMediaQuery} from 'react-responsive';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';
import {AppContext} from '../../Contexts/AppContext';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    line: {
        //border: 'none',
        borderColor: '#DFE4ED',
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
});

function OnboardingStepIcon(props) {
    const { stepNumber, active, completed } = props;
    return (<>
        {active && <Images.StepActiveIcon />}
        {completed && <Images.StepCompletedIcon />}
        {!completed && !active && <NotCompletedStepIcon stepNumber={stepNumber} />}
    </>);
}

const NotCompletedStepIcon = ({stepNumber, ...props}) => {
    return <svg className="MuiSvgIcon-root MuiStepIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <circle cx="12" cy="12" r="12"></circle>
        <text className="MuiStepIcon-text" x="12" y="16" text-anchor="middle">{stepNumber}</text>
    </svg>;
};

const Onboarding = () => {
    const {employerState, employerDispatch} = useContext(EmployerContext);
    const {appState} = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [mainInfo, setMainInfo] = useState(null);
    const [mainInfoErrors, setMainInfoErrors] = useState(null);
    const [savedRestaurantInfo, setSavedRestaurantInfo] = useState(null);
    const { hash } = useLocation();
    const params = queryString.parse(window.location.search);
    const history = useHistory();
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);

    const classes = useStyles();

    useEffect(() => {
        if (employerState?.restaurants?.length > 0) {
            history.push('/');
        }
    }, [])

    useEffect(()=>{
        if(hash){
            const stepNumber = Number(hash.slice(1));
            if (stepNumber === 3) {
                setActiveStep(stepNumber);
            }
            setActiveStep(1);
        }
    }, [hash]);

    useEffect(()=>{
        if(typeof params.savedRestaurantId !== 'undefined'){
            setSavedRestaurantInfo({id: params.savedRestaurantId});
        }
    }, [params.savedRestaurantId]);

    const handleMainInfoSubmitSuccess = (values) => {
        setActiveStep(2);
        setMainInfo({...values, email: appState.user?.email});
    };

    const handlePhotosSubmitSuccess = async (values) => {
        setLoading(true);
        try{
            let data = {...mainInfo, ...values, phoneNumber: mainInfo.phoneNumber[0] === '+' ? mainInfo.phoneNumber : '+'+mainInfo.phoneNumber};
            if (savedRestaurantInfo?.id) {
                data.id = savedRestaurantInfo.id
            }
            const restaurant = await saveRestaurant(data);

            if (savedRestaurantInfo?.id) {
                const updatedEmployerState = employerState.restaurants.map((rest, index) => {
                    if (rest.id === restaurant.id) {
                        return restaurant;
                    }
                });
                employerDispatch({
                    type: 'setRestaurants',
                    payload: [...updatedEmployerState],
                });
            } else {
                employerDispatch({
                    type: 'setRestaurants',
                    payload: [...employerState.restaurants, restaurant],
                });
            }

            setSavedRestaurantInfo(restaurant);
            setActiveStep(3);
        } catch (e) {
            if(e.error_code === 'municipality_is_not_supported'){
                setMainInfoErrors({address: e.detail});
            }

            if(e.errors){
                setMainInfoErrors(e.errors);
            }
        }
        setLoading(false);
    };

    const handleFinish = () => {
        history.push('/');
    };

    return (
        <div className="page onboarding">
            <OverlayLoader loading={loading}>
                {/*desktop view onboarding*/}

                {isDesktop && <div>
                    <Box mb={9} className="align-center">
                        <img src={Images.LogoLarge} width={55} />
                    </Box>

                    <Stepper activeStep={activeStep} connector={<StepConnector classes={{
                        line: classes.line,
                    }}/>}>
                        <Step key={0}>
                            <StepLabel icon={<OnboardingStepIcon stepNumber={1} active={activeStep === 0} completed={0 < activeStep}/>}>Account</StepLabel>
                        </Step>
                        <Step key={1}>
                            <StepLabel icon={<OnboardingStepIcon stepNumber={2} active={activeStep === 1} completed={1 < activeStep}/>}>Restaurant</StepLabel>
                        </Step>
                        <Step key={2}>
                            <StepLabel icon={<OnboardingStepIcon stepNumber={3} active={activeStep === 2} completed={2 < activeStep}/>}>Photos</StepLabel>
                        </Step>
                        <Step key={3}>
                            <StepLabel icon={<OnboardingStepIcon stepNumber={4} active={activeStep === 3} completed={3 < activeStep}/>}>Payment info</StepLabel>
                        </Step>
                    </Stepper>
                    <EmployerInfo
                        className={classNames({
                            'display-none': activeStep !== 0,
                        })}
                        handleForward={()=>setActiveStep(1)}
                    />
                    <RestaurantMainInfo
                        onSubmitSuccess={handleMainInfoSubmitSuccess}
                        errors={mainInfoErrors}
                        className={classNames({
                            'display-none': activeStep !== 1,
                        })}
                        handleBack={()=>setActiveStep(0)}
                    />
                    <RestaurantPhotos
                        onSubmitSuccess={handlePhotosSubmitSuccess}
                        className={classNames({
                            'display-none': activeStep !== 2,
                        })}
                        handleBack={()=>setActiveStep(1)}
                    />
                    {savedRestaurantInfo?.id && <RestaurantPaymentOptionsWrapper
                        className={classNames({
                            'display-none': activeStep !== 3,
                        })}
                        successUrl={'/'}
                        cancelUrl={`/onboarding?paymentAddSuccess=false&savedRestaurantId=${savedRestaurantInfo?.id}#3`}
                        restaurantId={savedRestaurantInfo?.id}
                        handleBack={()=>setActiveStep(2)}
                        handleFinish={handleFinish}
                    />}
                </div>}

                {/*mobile view onboarding*/}

                {!isDesktop && <div>
                    <Box mb={2} className="align-center">
                        <img src={Images.LogoLarge} width={55} />
                    </Box>
                    <Stepper activeStep={activeStep} orientation="vertical" connector={<StepConnector classes={{
                        line: {
                            border: 'none',
                        },
                    }}/>}>
                        <Step key={0}>
                            <StepLabel icon={<OnboardingStepIcon stepNumber={1} active={activeStep === 0} completed={0 < activeStep}/>}>Account</StepLabel>
                            <Box mb={2}>
                                <EmployerInfo
                                    className={classNames({
                                        'display-none': activeStep !== 0,
                                    })}
                                    handleForward={()=>setActiveStep(1)}
                                />
                            </Box>
                        </Step>
                        <Step key={1}>
                            <StepLabel icon={<OnboardingStepIcon stepNumber={2} active={activeStep === 1} completed={1 < activeStep}/>}>Restaurant</StepLabel>
                            <Box mb={2}>
                                <RestaurantMainInfo
                                    onSubmitSuccess={handleMainInfoSubmitSuccess}
                                    errors={mainInfoErrors}
                                    className={classNames({
                                        'display-none': activeStep !== 1,
                                    })}
                                    handleBack={()=>setActiveStep(0)}
                                    handleForward={()=>setActiveStep(2)}
                                />
                            </Box>
                        </Step>
                        <Step key={2}>
                            <StepLabel icon={<OnboardingStepIcon stepNumber={3} active={activeStep === 2} completed={2 < activeStep}/>}>Photos</StepLabel>
                            <Box mb={2}>
                                <RestaurantPhotos
                                    onSubmitSuccess={handlePhotosSubmitSuccess}
                                    savedRestaurantId={savedRestaurantInfo && savedRestaurantInfo.id}
                                    className={classNames({
                                        'display-none': activeStep !== 2,
                                    })}
                                    handleBack={()=>setActiveStep(1)}
                                />
                            </Box>
                        </Step>
                        <Step key={3}>
                            <StepLabel icon={<OnboardingStepIcon stepNumber={4} active={activeStep === 3} completed={3 < activeStep}/>}>Payment Methods</StepLabel>
                            <Box mb={2}>
                                {savedRestaurantInfo?.id && <RestaurantPaymentOptionsWrapper
                                    className={classNames({
                                        'display-none': activeStep !== 3,
                                    })}
                                    successUrl={'/'}
                                    cancelUrl={`/onboarding?paymentAddSuccess=false&savedRestaurantId=${savedRestaurantInfo?.id}#3`}
                                    restaurantId={savedRestaurantInfo?.id}
                                    handleBack={()=>setActiveStep(2)}
                                    handleFinish={handleFinish}
                                />}
                            </Box>
                        </Step>
                    </Stepper>
                </div>}
            </OverlayLoader>
        </div>
    );
};

const saveRestaurant = async (data) => {
    let method = 'post';
    let url = '/employers/me/restaurants';
    console.log("DATA ID", data.id)
    if(data.id) {
        method = 'put';
        url += '/'+ data.id;
    }
    return await Send({
        method,
        url,
        data,
    });
};


export default Onboarding;
