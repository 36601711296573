import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import GoogleMap from 'google-map-react';

import { Send } from '../../Misc/backend';
import LocationSearchInput from '../LocationSearchInput/LocationSearchInput';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import restaurantTemplate from '../../Misc/restaurant';
import {EmployerContext} from '../../Contexts/EmployerContext';

const phoneRegExp = /^\+[1-9]\d{1,14}$/;

const validationSchema = yup.object({
    name: yup
        .string()
        .required('Name is required'),
    phoneNumber: yup
        .string()
        .matches(phoneRegExp, 'Invalid Phone Number ')
        .required('Phone is required'),
    address: yup
        .string()
        .required('Address is required'),
});

const RestaurantForm = ({onClose, entity = restaurantTemplate}) => {
    const {employerState , employerDispatch} = useContext(EmployerContext);

    const handleSaveRestaurant = (restaurant, replace = false) => {
        if (replace) {
            employerDispatch({
                type: 'setRestaurants',
                payload: employerState.restaurants.map(item => {
                    return item.id === restaurant.id ? restaurant : item;
                }),
            });
        } else {
            employerDispatch({
                type: 'setRestaurants',
                payload: [...employerState.restaurants, restaurant],
            });
        }
    };

    const formik = useFormik({
        initialValues: entity,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try{
                handleSaveRestaurant(await saveRestaurant({...entity, ...values}), !!entity.id);
                onClose();
            } catch (e) {

            }
        },
    });

    const selectAddress = (value) => {
        formik.setFieldValue('address', value.address);
        formik.setFieldValue('latitude', value.lat);
        formik.setFieldValue('longitude', value.lng);
    };

    return (
        <OverlayLoader loading={formik.isSubmitting}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle id="form-dialog-title">{entity.name || 'Create new restaurant'}</DialogTitle>
                <DialogContent>
                    <Box my={2}>
                        <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="Name"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Box>
                    <Box my={2}>
                        <TextField
                            fullWidth
                            id="phoneNumber"
                            name="phoneNumber"
                            label="Phone Number"
                            variant="outlined"
                            value={formik.values.phoneNumber}
                            onChange={formik.handleChange}
                            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                        />
                    </Box>
                    <Box my={2}>
                        <LocationSearchInput
                            fullWidth
                            id="address"
                            name="address"
                            label="Address"
                            variant="outlined"
                            onSelect={selectAddress}
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                        />
                    </Box>
                    <Box my={2}>
                        <TextField
                            fullWidth
                            disabled
                            id="latitude"
                            name="latitude"
                            label="Latitude"
                            type="number"
                            variant="outlined"
                            value={formik.values.latitude}
                            onChange={formik.handleChange}
                            error={formik.touched.latitude && Boolean(formik.errors.latitude)}
                            helperText={formik.touched.latitude && formik.errors.latitude}
                        />
                    </Box>
                    <Box my={2}>
                        <TextField
                            fullWidth
                            disabled
                            id="longitude"
                            name="longitude"
                            label="Longitude"
                            type="number"
                            variant="outlined"
                            value={formik.values.longitude}
                            onChange={formik.handleChange}
                            error={formik.touched.longitude && Boolean(formik.errors.longitude)}
                            helperText={formik.touched.longitude && formik.errors.longitude}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary">
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" fullWidth type="submit"  size="large">
                        {entity.id ? 'Save' : 'Create'}
                    </Button>

                </DialogActions>
            </form>
        </OverlayLoader>
    );
};

const saveRestaurant = async (data) => {
    let method = 'post';
    let url = '/employers/me/restaurants';
    if(data.id) {
        method = 'put';
        url += '/'+ data.id;
    }
    return await Send({
        method,
        url,
        data,
    });
};


export default RestaurantForm;
