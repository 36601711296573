import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {withStyles} from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';

import {AppContext} from '../../../../Contexts/AppContext';

const StyledRating = withStyles({
    iconFilled: {
        color: '#ffb400',
    },
    iconHover: {
        // color: '#3ef3e9',
    },
})(Rating);

const DetailsRating = ({rating, reviewsCount}) => {
    const {appState} = useContext(AppContext);
    const [t] = useTranslation();

    return(
        <div>
            <StyledRating
                readOnly
                value={rating}
                name="simple-controlled"
                className="fs-16 align-top"
            />
            <span className="fs-12 additional_grey_2 align-top"> {rating} / {reviewsCount} {t('review(s)')}</span>
        </div>
    );
};

export default DetailsRating;
