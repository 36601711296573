import React from 'react';
import {useTranslation} from 'react-i18next';
import Box from '@material-ui/core/Box';

import './index.scss';


const ApplicationsBanner = () => {
    const [t] = useTranslation();

    return (
        <Box className="banner">
            <Box className="text-block">
                <Box className="additional_grey_1 fs-24 text-block-header">
                    Easy way to check your gigs.
                </Box>
                <Box className="additional_grey_2 fs-12">
                    {t(
                        'do_not_forget_to_check_applications_for_gigsler_out',
                    )}
                </Box>
                <Box className=" primary_black text-block-footer ">
                    <a className="primary_black fs-14 bold" href="https://apps.apple.com/se/app/gpg-app/id6476983854?l=en-GB">IOS App</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a className="primary_black fs-14 bold" href="https://play.google.com/store/apps/details?id=com.gigsly.gpg.app">Android App</a>
                </Box>
            </Box>
        </Box>
    );
};

export default ApplicationsBanner;


