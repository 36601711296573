import React, {useEffect, useState} from 'react';
import {Box} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


const AccordionRow = ({title, children, open = false, onOpen = ()=>{}}) => {
    const [show, setShow] = useState(open);

    useEffect(()=>{
        setShow(open);
    }, [open]);

    const handleOnClick = () => {
        setShow(!show);
        onOpen();
    };

    return (
        <Box className="accordion-row">
            <Box className="title pointer flex-align-horizontal-middle" onClick={handleOnClick}>
                {title} &nbsp;
                {show
                    ? <KeyboardArrowUpIcon onClick={()=>setShow(false)} className="pointer"/>
                    : <KeyboardArrowDownIcon onClick={()=>setShow(true)} className="pointer"/>
                }

            </Box>
            <div className={show ? 'display-block' : 'display-none'}>
                {children}
            </div>
        </Box>
    );};

export default AccordionRow;
