import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import moment from 'moment';

import GigTile from '../../../Components/Gig/GigTile';
import OverlayLoader from '../../../Components/OverlayLoader/OverlayLoader';
import RestaurantAccordionItemList from '../../../Components/RestaurantAccordionItem/RestaurantAccordionItemList';
import { AppContext } from '../../../Contexts/AppContext';
import { EmployerContext } from '../../../Contexts/EmployerContext';
import { getGigs } from '../../../Misc/backend';
import { IS_DESKTOP_MEDIA_QUERY } from '../../../Misc/constants';
import { usePrevious } from '../../../Misc/usePrevious';

import './GigListPage.scss';

const GigListPage = () => {
    const { employerState, employerDispatch } = useContext(EmployerContext);
    const { appState, appDispatch } = useContext(AppContext);
    const [layoutVariant, setLayoutVariant] = useState(0);
    const [loading, setLoading] = useState(false);
    const [t] = useTranslation();
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const [gigsWithNewApplicants, setGigsWithNewApplicants] = useState(
        new Set(),
    );

    const prevGigs = usePrevious(employerState.gigs);

    useEffect(() => {
        employerState.gigs.forEach((item) => {
            const prevState = prevGigs?.find((gig) => gig.id === item.id);
            if (prevState?.applicationsCount < item.applicationsCount) {
                setGigsWithNewApplicants((prev) => new Set(prev).add(item.id));
            }
        });
    }, [employerState.gigs]);

    useEffect(() => {
        let newConnection;

        async function loadGigs() {
            setLoading(true);
            try {
                const gigs = await getGigs();
                employerDispatch({
                    type: 'setGigs',
                    payload: gigs,
                });
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }

        async function socketInit() {
            const token = window.localStorage.getItem('token');
            const host =
                typeof process.env.REACT_APP_PUBLIC_API_DOMAIN === 'string' &&
                process.env.REACT_APP_PUBLIC_API_DOMAIN.length
                    ? process.env.REACT_APP_PUBLIC_API_DOMAIN
                    : null;

            try {
                newConnection = new HubConnectionBuilder()
                    .withUrl(host + '/moonlitehub', {
                        accessTokenFactory: () => `${token}`,
                        skipNegotiation: true,
                        transport: HttpTransportType.WebSockets,
                    })
                    .withAutomaticReconnect()
                    .build();

                await newConnection.start();

                newConnection.on(
                    'OnGigApplicationSubmitted',
                    (message, user) => {
                        setGigsWithNewApplicants((prev) =>
                            new Set(prev).add(message),
                        );
                        appDispatch({
                            type: 'addSnack',
                            payload: {
                                id: `new_application_${message}`,
                                variant: 'success',
                                message: (
                                    <div>
                                        {t('you_have_new_application')}.{' '}
                                        {t('go_to')}
                                        &nbsp;
                                        <NavLink to={`/gig/${message}`}>
                                            {t('gig_page')}
                                        </NavLink>
                                        &nbsp;{t('and_check_it_out')}.
                                    </div>
                                ),
                                persist: true,
                            },
                        });
                    },
                );
            } catch (ex) {
                console.error('WebSocket init exception -> ', ex);
            }
        }

        loadGigs();
        socketInit();

        return () => {
            if (newConnection) {
                newConnection.stop();
            }
        };
    }, []);

    const actions = (restaurant) => {
        return (
            <NavLink to={'/gig/edit/new?restaurantId=' + restaurant.id}>
                <Button color="primary" variant="contained">
                    <AddOutlinedIcon />
                </Button>
            </NavLink>
        );
    };

    return (
        <OverlayLoader loading={loading}>
            <Helmet>
                <title>GPG App - Gig list</title>
            </Helmet>
            <div className="page drafts">
                <div className="filters">
                    <div className="flex-align-horizontal-middle select">
                        <span className="fs-14 additional_grey_2">
                            {t('show_by')}:{' '}
                        </span>

                        <FormControl>
                            <Select
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                id="layout-select"
                                value={layoutVariant}
                                onChange={(e) =>
                                    setLayoutVariant(e.target.value)
                                }
                            >
                                <MenuItem value={0}>{t('restaurant')}</MenuItem>
                                <MenuItem value={1}>{t('calendar')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {layoutVariant === 1 && !loading && (
                        <div className="actions">
                            <NavLink to={'/gig/edit/new'}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                >
                                    <AddOutlinedIcon />
                                    {isDesktop && <>&nbsp;{t('add_a_gig')}</>}
                                </Button>
                            </NavLink>
                        </div>
                    )}
                </div>
                {layoutVariant === 0 && !loading && (
                    <div className="accordion-list">
                        {employerState?.restaurants?.map(
                            (restaurant, index, restaurants) => {
                                return (
                                    <RestaurantAccordionItemList
                                        restaurant={restaurant}
                                        customActions={actions(restaurant)}
                                        expanded={restaurants.length === 1}
                                    >
                                        {employerState?.gigs
                                            ?.filter(
                                                (gig) =>
                                                    restaurant.id ===
                                                    gig.restaurantId,
                                            )
                                            .sort((a, b) => {
                                                if (
                                                    moment(a.start).isBefore(
                                                        moment(b.start),
                                                    )
                                                )
                                                    return 1;
                                                return -1;
                                            })
                                            .map((gig) => (
                                                <GigTile
                                                    hasNewApplicants={gigsWithNewApplicants.has(
                                                        gig.id,
                                                    )}
                                                    gig={gig}
                                                    skills={appState?.skillsMap}
                                                />
                                            ))}
                                    </RestaurantAccordionItemList>
                                );
                            },
                        )}
                    </div>
                )}
                {layoutVariant === 1 && (
                    <div className="tile-list">
                        {employerState?.gigs
                            .sort((a, b) => {
                                if (moment(a.start).isBefore(moment(b.start)))
                                    return 1;
                                return -1;
                            })
                            .map((gig) => (
                                <GigTile
                                    hasNewApplicants={gigsWithNewApplicants.has(
                                        gig.id,
                                    )}
                                    gig={gig}
                                    skills={appState?.skillsMap}
                                />
                            ))}
                    </div>
                )}
            </div>
        </OverlayLoader>
    );
};

export default GigListPage;
