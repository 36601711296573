export const roundAccurately = (number, decimalPlaces = 2) => Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);


export const getEmployerTaxes = (price, maxEmployerTax) => {
    if (typeof price === 'number' && typeof maxEmployerTax === 'number')  {
        return roundAccurately(
            (
                price
                /
                (1 - maxEmployerTax / 100)
            ) * (maxEmployerTax / 100),
            2,
        );
    } else {
        return '0';
    }
};

export const getEmployeeTaxes = (price, maxEmployerTax, localTaxRateTotal) => {
    if (typeof price === 'number' && typeof maxEmployerTax === 'number' && typeof localTaxRateTotal === 'number')  {
        return roundAccurately(
            price
            * (localTaxRateTotal / 100),
            2,
        );
    } else {
        return '0';
    }
};

export const getTotalCost = (price, maxEmployerTax, localTaxRateTotal, fee = 0) => {
    if (typeof price === 'number' && typeof maxEmployerTax === 'number' && typeof localTaxRateTotal === 'number')  {
        return roundAccurately(
            price -
            roundAccurately(
                price
                * (localTaxRateTotal / 100),
                2,
            ),
            2,
        );
    } else {
        return '0';
    }
};
