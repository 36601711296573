import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from 'moment';

import {AppContext} from '../../Contexts/AppContext';
import {roundAccurately} from '../../Misc/calculations';
import {Images} from '../../static/images';
import Application from '../Application/Application';
import Contract from '../Contract/Contract';

const GigViewCollapsedInfoDesktop = ({gig, setGig, duration, gigStatus}) => {
    const [value, setValue] = useState(0);
    const [newApplicants, setNewApplicants] = useState([]);
    const {appState} = useContext(AppContext);
    const [t] = useTranslation();

    useEffect(()=>{
        if(gig && value === 0){
            //handle new applicants
            let currentValue = localStorage.getItem('knownApplicants');
            if(currentValue){
                currentValue = JSON.parse(currentValue);
                //find new applicants
                const oldApplicants = currentValue[gig.id] || [];
                //set to state
                setNewApplicants(gig.applications.filter(item => oldApplicants.indexOf(item.moonlighter.id) === -1).map(item=>item.moonlighter.id));

                //save to local storage
                localStorage.setItem('knownApplicants', JSON.stringify({
                    ...currentValue,
                    [gig.id]: [...gig.applications.map(item=>item.moonlighter.id)],
                }));
            } else {
                //if no value in local storage
                setNewApplicants(gig.applications.map(item=>item.moonlighter.id));
                localStorage.setItem('knownApplicants', JSON.stringify({
                    [gig.id]: [...gig.applications.map(item=>item.moonlighter.id)],
                }));
            }
        }
    }, [value, gig]);


    return (
        <Fragment>
            <Tabs
                value={value}
                onChange={(event, newValue)=>setValue(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                style={{maxWidth: '100%'}}
            >
                <Tab className="poppins additional_grey_2 bold fs-16" label={
                    <span>{t('all_applicants')} <span className="additional_grey_3">({gig?.applications?.length})</span></span>
                }/>
                <Tab className="poppins additional_grey_2 bold fs-16" label={
                    <span>{t('hired_cap')} <span className="additional_grey_3">({gig?.contracts?.length})</span></span>
                }/>
                <Tab className="poppins additional_grey_2 bold fs-16" label={t('gig_information')}/>
            </Tabs>
            <Box  mt={7}>
                {value === 0 && gig && <div className="tile-list">
                    {gig?.applications?.map(application => <Application
                        gig={gig}
                        application={application}
                        newApplicants={newApplicants}
                        setGig={setGig}
                        staticHost={appState.staticHost}
                        key={application.id}
                    />)}
                    {gig?.applications?.length === 0 && <div className="additional_grey_2 lh-46 fs-16">{t('no_new_applicants')}</div>}
                </div>}

                {value === 1 && gig && <div className="tile-list">
                    {gig?.contracts?.map(contract => <Contract key={contract.id} contract={contract} gig={gig} staticHost={appState.staticHost} setGig={setGig} gigStatus={gigStatus}/>)}
                    {gig?.contracts?.length === 0 && <div className="additional_grey_2 lh-46 fs-16">{t('no_contracts_yet')}.</div>}
                </div>}
                {value === 2 && gig && <div className="gig-info">
                    <Box className="stats">

                        <div className="flex-row stat-card card double">
                            <Box mr={2} className="additional_grey_2">
                                <AccessTimeIcon/>
                            </Box>
                            <div className="timespan">
                                <div className="stat">
                                    <Box mb={1} className="additional_grey_2 name fs-14 roboto">{t('start_date')}</Box>
                                    <div className="value primary_black fs-24 poppins">{moment.utc(gig.start).local().format('DD MMM YYYY, HH:mm')}</div>
                                </div>
                                <div className="mdash"/>
                                <div className="stat">
                                    <Box mb={1} className="additional_grey_2 name fs-14 roboto">{t('end_date')}</Box>
                                    <div className="value primary_black fs-24 poppins">{moment.utc(gig.start).local().add(duration).format('DD MMM YYYY, HH:mm')}</div>
                                </div>
                            </div>
                            {/*<Box ml={2} className="primary_neon pointer">*/}
                            {/*    <EditIcon/>*/}
                            {/*</Box>*/}
                        </div>
                        <div className="flex-row stat-card card single">
                            <Box mr={2}>
                                <img src={Images.Duration}/>
                            </Box>
                            <div className="stat">
                                <Box mb={1} className="additional_grey_2 name fs-14 roboto">{t('duration')}</Box>
                                <div className="value primary_black fs-24 poppins">{roundAccurately(duration?.asHours(), 2)}<span className="additional_grey_3 fs-24">h</span></div>
                            </div>
                        </div>
                        <div className="flex-row stat-card card single">
                            <Box mr={2}>
                                <img src={Images.Payments}/>
                            </Box>
                            <div className="stat">
                                <Box mb={1} className="additional_grey_2 name fs-14 roboto">{t('total_price')}</Box>
                                <div className="value primary_black fs-24 poppins">{gig.price}<span className="additional_grey_3 fs-24">SEK</span></div>
                            </div>
                        </div>
                    </Box>
                    <Box mt={6}>
                        <Box mb={1} className="additional_grey_2 name fs-14 roboto">{t('description')}</Box>
                        <div className="pre-wrap">
                            {gig.description}
                        </div>
                    </Box>
                </div>}
            </Box>
        </Fragment>
    );
};

export default GigViewCollapsedInfoDesktop;
