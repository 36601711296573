import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../Contexts/AppContext';
import { EmployerContext } from '../../Contexts/EmployerContext';
import { getGigs, getRestaurants, getSkills, Send } from '../../Misc/backend';
import { parseJwt } from '../Authorization/Authorization';
import SmileLoader from '../SmileLoader/SmileLoader';

import './ContextLoader.scss';

const modules = [];

const ContextLoader = (props) => {
    const [percent, setPercent] = useState(0);
    const [moduleTitle, setModuleTitle] = useState('');
    const { appDispatch } = useContext(AppContext);
    const { employerDispatch } = useContext(EmployerContext);
    const staticHost =
        typeof process.env.REACT_APP_STATIC_FILE_SERVER_URI === 'string' && process.env.REACT_APP_STATIC_FILE_SERVER_URI.length
            ? process.env.REACT_APP_STATIC_FILE_SERVER_URI
            : null;

    let redirectUrl = null;

    useEffect(() => {
        async function getRestaurantPaymentMethods(restaurantId) {
            return await getPaymentMethods(restaurantId);
        }

        appDispatch({
            type: 'setStaticHost',
            payload: staticHost,
        });

        modules.push({
            title: 'User',
            function: async () => {
                const user = await getUser();
                user['roles'] = parseJwt(window.localStorage.getItem('token')).roles;
                appDispatch({
                    type: 'setUser',
                    payload: user,
                });
            },
        });

        modules.push({
            title: 'Gigs',
            function: async () => {
                const gigs = await getGigs();
                employerDispatch({
                    type: 'setGigs',
                    payload: gigs,
                });
            },
        });

        modules.push({
            title: 'Skills',
            function: async () => {
                const skills = await getSkills(props.language);
                appDispatch({
                    type: 'setSkills',
                    payload: skills,
                });
            },
        });

        modules.push({
            title: 'Drafts',
            function: async () => {
                const drafts = await getDrafts();
                employerDispatch({
                    type: 'setDrafts',
                    payload: drafts,
                });
            },
        });

        modules.push({
            title: 'Configuration',
            function: async () => {
                const config = await getConfig();

                appDispatch({
                    type: 'setConfig',
                    payload: config,
                });
            },
        });

        modules.push({
            title: 'Time input field type',
            function: async () => {
                const response = await getTimeInputFieldType();

                appDispatch({
                    type: 'setTimeInputFieldType',
                    payload: response.timeInputFieldType,
                });
            },
        });

        modules.push({
            title: 'Restaurants',
            function: async () => {
                const restaurants = await getRestaurants();
                employerDispatch({
                    type: 'setRestaurants',
                    payload: restaurants,
                });
            },
        });

        loadModules(modules).then((r) => props.update(redirectUrl));
    }, []);

    const loadModules = async (modules) => {
        const step = Math.floor(100 / modules.length);
        let i = 0;
        for (const module of modules) {
            i++;
            setModuleTitle(module.title);
            if (typeof module.function === 'function') {
                await module.function();
                setPercent(i === modules.length ? 100 : i * step);
            }
        }

        setModuleTitle('Done.');
    };

    return (
        <div className="ContextLoader">
            <div className="content">
                <SmileLoader />
                <div className="title fs-24">Loading</div>
                <div className="modules additional_grey_2">Create and manage gigs for your restaurants</div>
            </div>
        </div>
    );
};

// TODO: Implement method for recalling auth token

const getUser = async () => {
    const user = await Send({
        method: 'get',
        url: '/employers/me',
    });

    return user || {};
};

const getConfig = async () => {
    const config = await Send({
        method: 'get',
        url: '/config',
    });

    return config || {};
};

const getTimeInputFieldType = async () => {
    const timeInputFieldType = await Send({
        method: 'get',
        url: '/config/place',
    });

    return timeInputFieldType || {};
};

const getDrafts = async () => {
    const user = await Send({
        method: 'get',
        url: '/employers/me/drafts',
    });

    return user || {};
};

const getPaymentMethods = async (restaurantId) => {
    const methods = await Send({
        method: 'get',
        url: `/employers/me/restaurants/${restaurantId}/card-payment-methods`,
    });

    return methods || [];
};

export default ContextLoader;
