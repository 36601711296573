export const draftTemplate = {
    'restaurantId': '',
    'start': null,
    'startDate': null,
    'startTime': '00:00',
    'endTime': '00:00',
    'price': 152.5,
    'description': '',
    'skillTagId': '',
};

export default draftTemplate;
