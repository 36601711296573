import React, {Fragment, useEffect, useState} from 'react';
import {Box} from '@material-ui/core';
import {useMediaQuery} from 'react-responsive';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


const FooterColumn = ({title, children}) => {
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const [show, setShow] = useState (isDesktop);

    return (
        <div className="align-left">
            {!isDesktop
                ? <Box mb={2} className="primary_black fs-16 bold poppins pointer flex-align-horizontal-middle" onClick={() => setShow(!show)}>
                    {title} &nbsp;
                    {show
                        ? <KeyboardArrowUpIcon onClick={()=>setShow(false)} className="pointer"/>
                        : <KeyboardArrowDownIcon onClick={()=>setShow(true)} className="pointer"/>
                    }
                </Box>
                : <Box mb={2} className="primary_black fs-16 bold poppins">{title}</Box>
            }
            {show && children}
        </div>
    );};

export default FooterColumn;
