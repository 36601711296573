import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import {useRouteMatch} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import {withStyles} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import EditIcon from '@material-ui/icons/Edit';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import {useSnackbar} from 'notistack';

import Application from '../../../Components/Application/Application';
import BackButton from '../../../Components/BackButton/BackButton';
import Contract from '../../../Components/Contract/Contract';
import GigViewCollapsedInfoDesktop from '../../../Components/GigViewCollapsedInfo/GigViewCollapsedInfoDesktop';
import GigViewCollapsedInfoMobile from '../../../Components/GigViewCollapsedInfo/GigViewCollapsedInfoMobile';
import OverlayLoader from '../../../Components/OverlayLoader/OverlayLoader';
import RestaurantAccordionItem from '../../../Components/RestaurantAccordionItem/RestaurantAccordionItem';
import {AppContext} from '../../../Contexts/AppContext';
import {EmployerContext} from '../../../Contexts/EmployerContext';
import {getGig, Send} from '../../../Misc/backend';
import {roundAccurately} from '../../../Misc/calculations';
import {IS_DESKTOP_MEDIA_QUERY} from '../../../Misc/constants';
import {getGigStatus} from '../../../Misc/gig';
import {timespanStringToMomentDuration} from '../../../Misc/timespan';
import {Images} from '../../../static/images';

import './index.scss';


const StyledRating = withStyles({
    iconFilled: {
        color: '#3ef3e9',
    },
    iconHover: {
        color: '#3ef3e9',
    },
})(Rating);

const GigViewPage = (params) => {
    const {appState} = useContext(AppContext);
    const {employerState} = useContext(EmployerContext);
    const [gig, setGig] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const [restaurant, setRestaurant] = useState(null);
    const [duration, setDuration] = useState(null);
    const id = useRouteMatch('/gig/:id');
    const [t] = useTranslation();
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const { closeSnackbar } = useSnackbar();


    useEffect(()=>{
        async function loadGig(gigId) {
            setLoading(true);
            try{
                setGig(await getGig(gigId));
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }
        if(id?.params?.id){
            loadGig(id?.params?.id);
        }
    }, [id?.params?.id]);

    useEffect(()=>{
        if(gig){
            closeSnackbar(`new_application_${gig.id}`);
            setStatus(getGigStatus(gig));
            setDuration(timespanStringToMomentDuration(gig.duration));
            setRestaurant(employerState.restaurants.find((item => item.id === gig.restaurantId)));
        }
    }, [gig]);

    useEffect(()=>{
        let updateStatusInterval = setInterval(()=>{
            if(gig){
                setStatus(getGigStatus(gig));
            };
        }, 60000);


        return ()=>{
            clearInterval(updateStatusInterval);
        };
    },[gig]);

    return <OverlayLoader loading={loading}>
        <Helmet>
            <title>GPG App - Gig</title>
        </Helmet>
        <div className="page gig">
            <BackButton url="/"/>
            <div className="page-header">
                {/*<img src={status?.icon} className="status-icon"/>*/}
                <span className="poppins">{appState?.skillsMap?.get(gig?.skillTagId)}</span>
            </div>
            {restaurant && <RestaurantAccordionItem
                restaurant={restaurant}
                customActions={isDesktop &&<div>
                    <div className="primary_black poppins fs-18 align-right">{t('gig_total')}:</div>
                    <div className="additional_grey_2 fs-16">{gig?.contracts?.length} {t('hired')}, {roundAccurately(gig?.price * gig?.contracts?.length, 2)} SEK</div>
                </div>}
                addressPrepend={<span>
                    <img src={status?.icon} height={20} width={20} className="align-sub"/> {status?.status}
                </span>}
            />}
            {isDesktop
                ? <GigViewCollapsedInfoDesktop
                    gig={gig}
                    setGig={setGig}
                    duration={duration}
                    gigStatus={status}
                />
                : <GigViewCollapsedInfoMobile
                    gig={gig}
                    setGig={setGig}
                    duration={duration}
                    gigStatus={status}
                />
            }
        </div>
    </OverlayLoader>;
};


export default GigViewPage;
