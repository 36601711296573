export default (state, action) => {
    try {
        return actions[action.type](state, action.payload);
    }
    catch(err) {
        throw err;
    }
};

const actions = {
    setRestaurants(state, data) {
        const restaurantsMap = new Map();
        data.forEach(item => {
            restaurantsMap.set(item.id, item);
        });
        return {...state, restaurants: data, restaurantsMap};
    },
    setGigs(state, data) {
        const sorted = [...data];
        sorted.sort((a, b) => a.start > b.start ? 1 : -1);
        return {...state, gigs: sorted};
    },
    setSkills(state, data) {
        return {...state, skills: data};
    },
    setDrafts(state, data) {
        const sorted = [...data];
        sorted.sort((a, b) => a.start > b.start ? 1 : -1);
        return {...state, drafts: sorted};
    },
};
