import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import Box from '@material-ui/core/Box';
import {withStyles} from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';

import {AppContext} from '../../../../../Contexts/AppContext';
import {EmployerContext} from '../../../../../Contexts/EmployerContext';
import {Images} from '../../../../../static/images';

import './ReviewForm.scss';

const StyledRating = withStyles({
    iconFilled: {
        color: '#ffb400',
    },
    iconHover: {
        color: '#ffb400',
    },
})(Rating);

const ReviewCompleted = ({contract}) => {
    const [t] = useTranslation();
    const {employerState} = useContext(EmployerContext);
    const {appState} = useContext(AppContext);

    const restaurant = employerState?.restaurants.find(x => x.id === contract?.gigSubject?.restaurantId);
    const startTime = moment(contract?.gigSubject?.start);
    const [hours, minutes, seconds] = contract?.gigSubject?.duration.split(':');
    const endTime = moment(startTime).add(hours, 'h').add(minutes, 'm').format('HH:mm');

    if(!contract) return;
    console.log(contract);

    return  <div className="review-completed">
        <div className="flex-align-vertical-middle align-center">
            <Box my={2} className="feedback-wrapper">
                <Box className="fs-16 bold" mb={2}>
                    Your review:
                </Box>
                <Box className="fs-14">
                    {`"${contract.moonlighterFeedback?.comment}"`}
                </Box>
                <Box className="rating-wrapper">
                    <StyledRating
                        value={contract.moonlighterFeedback?.rating}
                        label="Rating"
                        disabled
                    />
                    <span className="fs-16 additional_grey_2 numeric bold"> {contract.moonlighterFeedback?.rating}.0</span>
                </Box>
            </Box>
        </div>
    </div>;
};

export default ReviewCompleted;
