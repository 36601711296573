import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { SaveOutlined } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import {getEmployeeBindings, getMoonlighters, Send} from '../../Misc/backend';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';
import OverlayLoader from '../OverlayLoader/OverlayLoader';

import BindingsTable from './Components/BindingsTable';
import IntegrationsTable from './Components/IntegrationsTable';

import './Integration.scss';

export const INVITE_VIEW_KEY = 'inviteView';
const Integration = (restaurantId) => {
    const [loading, setLoading] = useState(false);
    const [tokenValue, setTokenValue] = useState('');
    const [personalkollenStaff, setPersonalkollenStaff] = useState([]);
    const [moonlightersList, setMoonlightersList] = useState([]);
    const [t] = useTranslation();
    const [bindings, setBindings]=useState([]);
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const [newBindings, setNewBindings] = useState({});
    const [itemsToDelete, setItemsToDelete] = useState([]);
    const [tokenIsSet, setTokenIsSet] = useState(false);

    useEffect(()=> {
        async function loadRestaurantSettings() {
            setLoading(true);
            try{
                const settings = await getRestaurantSettings(restaurantId.restaurantId);
                setTokenIsSet(settings.isApiKeySet);
            }  catch (e) {
                console.log(e);
            }
            setLoading(false);
        }
        loadRestaurantSettings();

    }, [restaurantId.restaurantId]);

    useEffect(()=>{
        async function loadPersonalkollenStaff(){
            setLoading(true);
            try{
                setPersonalkollenStaff(await getPersonalkollenStaff(restaurantId.restaurantId));
                const moonlighters = await getMoonlighters();
                setMoonlightersList (moonlighters);
                setBindings(await getEmployeeBindings(restaurantId.restaurantId));
            } catch (e) {
                setPersonalkollenStaff([]);
                console.log(e);
            }
            setLoading(false);
        }
        if (tokenIsSet) {
            loadPersonalkollenStaff();
        } else {
            setPersonalkollenStaff([]);
        }
    },[tokenIsSet]);
    const handleSaveRestaurantToken = async () => {
        setTokenIsSet(false);
        await saveRestaurantToken(restaurantId.restaurantId, tokenValue);
        setTokenIsSet(true);
        setTokenValue('');
    };
    const handleResetToken = async () => {
        if (confirm('Do you want to reset the token?') == true){
            try {
                await setTokenIsSet(false);
                setTokenValue('');
            } catch (e) {
                console.log(e);
            }
        }
    };

    const handleSaveNewBindings = async () => {
        for await (const [externalId, internalId] of Object.entries(newBindings)) {
            await saveNewBindings({externalId, internalId}, restaurantId.restaurantId);
            setNewBindings((prevState)=>{
                const d = {...prevState};
                delete d[externalId];
                return d;
            });
        }
        setBindings(await getEmployeeBindings(restaurantId.restaurantId));
    };

    const handleDeleteBinding = async () => {
        for await (const internalId of itemsToDelete) {
            await deleteEmployeeBindings( restaurantId.restaurantId, internalId);
        }
        setBindings(await getEmployeeBindings(restaurantId.restaurantId));
        setItemsToDelete([]);
    };

    const existedBindingsInternalIds = bindings.map((item) => item.internalId);

    const filteredMoonlighters = moonlightersList.filter((moonlighter) => {
        return (
            !existedBindingsInternalIds.includes(moonlighter.id)
        );
    });

    const newBindingsExternalIds = Object.keys(newBindings);
    const existedBindingsExternalIds = bindings.map((item) => item.externalId);

    const filteredPersonalkollenStaff = personalkollenStaff.filter((staff)=> {
        return (
            !newBindingsExternalIds.includes(staff.externalId) &&
            !existedBindingsExternalIds.includes(staff.externalId)
        );
    });

    return (
        <OverlayLoader loading={loading}>
            <div className="integration card card-large">
                <Box mb={2} className="integration-form">
                    {personalkollenStaff.length === 0 && <div>
                        <Box mb={2} className="fs-16 bold primary_black">{t('set_new_token')}</Box>

                        <Box mb={2} className="token-input">
                            <Box>
                                <TextField
                                    id="token"
                                    name="token"
                                    className="token-input-text"
                                    variant="outlined"
                                    color="primary"
                                    placeholder="Type token here"
                                    value={tokenValue}
                                    onChange={(event) => {
                                        setTokenValue(event.target.value);
                                    }}
                                />
                            </Box>
                            <Box ml={1}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    className="token-input-button"
                                    //disabled={(tokenValue.length === 0) ? true : false}
                                    onClick={handleSaveRestaurantToken}
                                >
                                    {t('save')}
                                </Button>
                            </Box>
                        </Box>
                        <Box mb={2} className="fs-14 additional_grey_2">{t('to_see_integrations')}</Box>


                    </div>}
                    {personalkollenStaff.length !== 0 && <Box mb={2} className="token-is-set">
                        <CheckCircleOutlineIcon fontSize="large"/>
                        <div className="fs-18 bold primary_black">&nbsp;&nbsp;{t('token_is_set')}</div>
                        <Box ml={1}>
                            <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                className="token-is-set-button"
                                //disabled={(tokenValue.length === 0) ? true : false}
                                onClick={handleResetToken}
                            >
                                {t('reset_token')}
                            </Button>
                        </Box>
                    </Box>}
                    {personalkollenStaff.length !== 0 &&
                        <>
                            <div className="binding-button-wrapper">
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    size="large"
                                    className="binding-button"
                                    title='Save binding'
                                    onClick={handleSaveNewBindings}
                                >
                                    <SaveOutlined/>
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    size="large"
                                    className="binding-button"
                                    title='Delete binding'
                                    onClick={handleDeleteBinding}
                                >
                                    <DeleteOutlineOutlinedIcon />
                                </Button>
                            </div>

                            <Box className="space-between">
                                <Box mb={2} className="fs-16 bold primary_black width30">Personalkollen</Box>
                                <Box mb={2} className="fs-16 bold primary_black width30">Gigslers</Box>
                            </Box>
                            <Divider/>
                        </>
                    }
                    <BindingsTable
                        staff={personalkollenStaff}
                        moonlighters={moonlightersList}
                        bindings={bindings}
                        setItemsToDelete={setItemsToDelete}
                    />
                    <IntegrationsTable
                        newBindings={newBindings}
                        setNewBindings={setNewBindings}
                        staff={filteredPersonalkollenStaff}
                        moonlighters={filteredMoonlighters}
                    />
                </Box>
            </div>
        </OverlayLoader>
    );
};

const getPersonalkollenStaff = async (restrauntId) => {
    let method = 'get';
    let url = `/employers/me/external-data/restaurants/${restrauntId}/personalkollen/staffs`;

    return await Send({
        method,
        url,
    });
};

const saveRestaurantToken = async (restrauntId, tokenValue) => {
    let method = 'put';
    let url = `/employers/me/restaurants/${restrauntId}/settings`;
    return await Send({
        method,
        url,
        data: {'updatePersonalkollenApiKey': true,
            'personalkollenApiKey': tokenValue},
    });
};

const saveNewBindings = async (item, restrauntId) => {
    let method = 'post';
    let url = `/employers/me/restaurants/${restrauntId}/employee-bindings`;

    return await Send({
        method,
        url,
        data: item,
    });
};

const deleteEmployeeBindings = async (restrauntId, internalId) => {
    let method = 'delete';
    let url = `/employers/me/restaurants/${restrauntId}/employee-bindings/${internalId}`;

    return await Send({
        method,
        url,
    });
};

const getRestaurantSettings = async (restrauntId) => {
    let method = 'get';
    let url = `/employers/me/restaurants/${restrauntId}/settings`;
    return await Send({
        method,
        url,
    });
};

export default Integration;
