import React, {useContext, useEffect, useState} from 'react';
import {NavLink, useRouteMatch} from 'react-router-dom';
import queryString from 'query-string';
import {PER_PAGE} from '../../Misc/constants';
import {getRestaurants, Send, SendWithPagination} from '../../Misc/backend';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import {EmployerContext} from '../../Contexts/EmployerContext';

const InvitedManagerLanding = (props) => {
    const match = useRouteMatch('/employers/me/restaurants/:id/invitations');
    const params = queryString.parse(props.location.search);
    const { employerDispatch } = useContext(EmployerContext);

    const [successResponse, setSuccessResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(()=>{
        async function loadInvitations(restaurantId, inviteId){
            setLoading(true);
            try{
                const response = await getInvitations(restaurantId, inviteId);
                await setManagers(restaurantId, inviteId);
                const restaurants = await getRestaurants();

                employerDispatch({
                    type: 'setRestaurants',
                    payload: restaurants,
                });
                setSuccessResponse(response);
            } catch (e) {
                setError(e.detail);
            }
            setLoading(false);
        }

        if(match?.params?.id && params?.inviteId){
            loadInvitations(match.params.id, params.inviteId);
        }
    }, [match.params.id, params.inviteId]);

    return (
        <OverlayLoader loading={loading}>
            <div className="page">
                {loading && <h2 className="poppins additional_grey_1">
                    We're proceeding your request. Please Wait.
                </h2>}
                {successResponse && <h2 className="poppins additional_grey_1">
                    You have been successfully added to restaurant <NavLink to={'/restaurant/edit/'+successResponse.invitedToRestId}>{successResponse.restaurantName}</NavLink> managers.
                </h2>}
                {error && <h2 className="poppins additional_grey_1 system_error">
                    {error}
                </h2>}
            </div>
        </OverlayLoader>
    );
};


const getInvitations = async (restaurantId, inviteId) => {
    let method = 'get';
    let url = `/employers/me/restaurants/${restaurantId}/invitations?invitationId=${inviteId}`;

    return  await Send({
        method,
        url,
    });
};



const setManagers = async (restaurantId, invitationId) => {
    let method = 'post';
    let url = `/employers/me/restaurants/${restaurantId}/managers`;

    return  await Send({
        method,
        url,
        data: {
            invitationId,
        },
    });
};

export default InvitedManagerLanding;
