import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Box} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import customHistory from '../../Misc/history';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';

const BackButton = ({url}) => {
    const [t] = useTranslation();
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const history = useHistory();

    const handleBackClick = () => {
        if(url){
            history.push(url);
        } else {
            customHistory.back();
        }
    };

    return <Box mb={1} onClick={handleBackClick} className="fs-14 additional_grey_2 pointer">
        {isDesktop && <><ArrowBackIosIcon className="fs-12"/>{t('back')}</>}
    </Box>;
};

export default BackButton;
