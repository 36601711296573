import React, {useContext, useState, Fragment, useEffect} from 'react';
import {userTemplate} from '../../Misc/user';
import { AppContext } from '../../Contexts/AppContext';
import { Send } from '../../Misc/backend';
import {useFormik} from 'formik';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './index.scss';
import {useTranslation} from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import FormHelperText from '@material-ui/core/FormHelperText';
import {mapServerValidationToFormik} from '../../Misc/validationsErrors';
import {useMediaQuery} from 'react-responsive';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';
import {ArrowRightIcon} from '@material-ui/pickers/_shared/icons/ArrowRightIcon';

const EmployerInfo = ({handleForward,  ...rest}) => {
    const [t] = useTranslation();
    const {appState, appDispatch} = useContext(AppContext);
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const [phoneLength, setPhoneLength] = useState(11);

    const validationSchema = yup.object({
        fullName: yup
            .string()
            .nullable()
            .required('First Name is required'),
        phoneNumber: yup
            .string()
            .nullable()
            .matches(new RegExp(`\\d{${phoneLength}}`), t('invalid_phone_number'))
            .required('Phone number is required'),
    });

    const formik = useFormik({
        initialValues: appState.user || userTemplate,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try{
                await UpdateEmployer({...values, phoneNumber: values.phoneNumber[0] === '+' ? values.phoneNumber : '+'+values.phoneNumber});
                appDispatch({
                    type: 'setUser',
                    payload: values,
                });
                if(typeof handleForward === 'function') {
                    handleForward();
                }
            } catch (e) {
                console.log(e);
                if(e.response?.data?.errors){
                    formik.setErrors(mapServerValidationToFormik(e.response.data.errors));
                }
            }
        },
    });

    return (
        <OverlayLoader loading={formik.isSubmitting} {...rest}>
            <div className="employer-info-wrapper">
                <form onSubmit={formik.handleSubmit} className="profile-form">
                    <div className="profile-info align-left">
                        <div className="profile-info-row">
                            <div className="flex-row space-between">
                                <div className="additional_grey_1 bold fs-16">{t('email')}</div>
                            </div>
                            <TextField
                                fullWidth
                                id="fullName"
                                name="email"
                                variant="outlined"
                                color="secondary"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                                helperText={formik.touched.fullName && formik.errors.fullName}
                            />
                        </div>
                        <div className="profile-info-row">
                            <div className="flex-row space-between">
                                <div className="additional_grey_1 bold fs-16">{t('full_name')}</div>
                            </div>
                            <TextField
                                fullWidth
                                id="fullName"
                                name="fullName"
                                variant="outlined"
                                color="secondary"
                                value={formik.values.fullName}
                                onChange={formik.handleChange}
                                error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                                helperText={formik.touched.fullName && formik.errors.fullName}
                            />
                        </div>
                        <div className="profile-info-row">
                            <div className="flex-row space-between">
                                <div className="additional_grey_1 bold fs-16">{t('phone_number')}</div>
                            </div>
                            <>
                                <PhoneInput
                                    country={'se'}
                                    value={formik.values.phoneNumber}
                                    onChange={phone => formik.setFieldValue('phoneNumber', phone)}
                                    placeholder={''}
                                    preferredCountries={['us', 'se']}
                                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                    isValid={(value, country) => {
                                        const requiredLength = country.format.match(/\./g).length;
                                        setPhoneLength(requiredLength);
                                        if (value.length !== requiredLength) {
                                            return false;
                                        }
                                        else {
                                            return true;
                                        }
                                    }}
                                />
                                {(formik.errors.phoneNumber && formik.touched.phoneNumber) &&
                                    <FormHelperText style={{marginLeft: 14}} error>{formik.errors.phoneNumber}</FormHelperText>
                                }
                            </>
                        </div>
                    </div>
                    <div className="btns">
                        {isDesktop && <Button color="primary" variant="contained" size="large" onClick={handleForward} disabled={!formik.isValid}>
                            <span>{t('next_step')}</span> <ArrowRightIcon/>
                        </Button>}
                        {!isDesktop && <Button fullWidth color="primary" variant="contained" size="large" onClick={handleForward}>
                            <span>{t('next_step')}</span> <ArrowRightIcon/>
                        </Button>}
                    </div>

                </form>
            </div>
        </OverlayLoader>
    );
};

const UpdateEmployer = async (employer) => {
    return await Send({
        method: 'put',
        url: '/employers/me',
        data: employer,
    });
};

export default EmployerInfo;
