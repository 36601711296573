import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import moment from 'moment';
import { v4 } from 'uuid';

import DraftTile from '../../../Components/Draft/DraftTile';
import DraftForm from '../../../Components/DraftForm/DraftForm';
import OverlayLoader from '../../../Components/OverlayLoader/OverlayLoader';
import RestaurantAccordionItem from '../../../Components/RestaurantAccordionItem/RestaurantAccordionItem';
import {AppContext} from '../../../Contexts/AppContext';
import {EmployerContext} from '../../../Contexts/EmployerContext';
import {Send} from '../../../Misc/backend';
import draftTemplate from '../../../Misc/draft';
import {timespanStringToMomentDuration} from '../../../Misc/timespan';

import './index.scss';


const DraftsPage = () => {
    const {employerState, employerDispatch} = useContext(EmployerContext);
    const {appState, appDispatch} = useContext(AppContext);
    const [draftFormVisible, setDraftFormVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editDraft, setEditDraft] = useState(draftTemplate);
    const [layoutVariant, setLayoutVariant] = useState(0);
    const [t] = useTranslation();


    //TODO: uncomment if you want more efficiency

    // const [draftsTree, setDraftsTree] =  useState(null);
    //
    // useEffect(()=>{
    //     const tree = {};
    //     drafts.forEach(item => {
    //         if(typeof tree[item.restaurantId] === 'undefined'){
    //             tree[item.restaurantId] = [item];
    //         } else {
    //             tree[item.restaurantId].push(item);
    //         }
    //     });
    //     setDraftsTree(tree);
    //     console.log(tree);
    //
    // }, [drafts]);

    const handleFormClose = () => {
        setEditDraft(draftTemplate);
        setDraftFormVisible(false);
    };

    const onItemAddClick = (restaurant) => {
        setEditDraft({...draftTemplate, restaurantId: restaurant.id});
        setDraftFormVisible(true);
    };

    const onItemEditClick = (draft) => {
        const duration = timespanStringToMomentDuration(draft.duration);
        setEditDraft({...draft, duration: moment().startOf('day').local(true).add(duration)});
        setDraftFormVisible(true);
    };

    const onItemDeleteClick = async (id) => {
        try{
            setLoading(true);
            await deleteDraft(id);
            employerDispatch({
                type: 'setDrafts',
                payload: employerState.drafts.filter(item => item.id !== id),
            });
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };

    const onItemPublishClick = async (draft) => {
        try{
            setLoading(true);
            await publishDraft(draft.id);
            employerDispatch({
                type: 'setDrafts',
                payload: employerState.drafts.filter(item => item.id !== draft.id),
            });
            employerDispatch({
                type: 'setGigs',
                payload:  [...employerState.gigs, draft],
            });
            appDispatch({
                type: 'addSnack',
                payload: {
                    id: v4(),
                    variant: 'success',
                    message: <>Draft was published successfully. Go to&nbsp;<NavLink to="/">gigs page</NavLink>&nbsp;and check it out.</>,
                },
            });

        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };

    const actions = (restaurant) => {return (<NavLink to={'/gig/edit/new?restaurantId='+restaurant.id}>
        <Button
            color="primary"
            variant="contained"
            size="large"
        >
            <AddOutlinedIcon/>&nbsp;Add a gig
        </Button>
    </NavLink>);};

    return <OverlayLoader loading={loading}>
        <Helmet>
            <title>GPG App - Drafts</title>
        </Helmet>
        <div className="page drafts">
            <Dialog
                open={draftFormVisible}
                onClose={handleFormClose}
                aria-labelledby="form-dialog-title"
            >
                <DraftForm
                    entity={editDraft}
                    onClose={handleFormClose}
                />
            </Dialog>
            <div className="filters">
                <span className="fs-14 additional_grey_2">{t('show_by')}: </span>
                <FormControl>
                    <Select
                        id="layout-select"
                        value={layoutVariant}
                        onChange={(e) => setLayoutVariant(e.target.value)}
                    >
                        <MenuItem value={0}>{t('restaurant')}</MenuItem>
                        <MenuItem value={1}>{t('calendar')}</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {layoutVariant === 0 &&  <div className="accordion-list">
                {employerState?.restaurants?.map(restaurant => {
                    return <RestaurantAccordionItem restaurant={restaurant} customActions={actions(restaurant)}>
                        {/*{draftsTree[restaurant.id]?.map(draft => draft.id)}*/}
                        {employerState?.drafts?.filter(draft => restaurant.id === draft.restaurantId)
                            .map(draft => <DraftTile
                                draft={draft}
                                skills={appState?.skillsMap}
                                onItemEditClick={onItemEditClick}
                                onItemDeleteClick={onItemDeleteClick}
                                onItemPublishClick={onItemPublishClick}
                            />)
                        }
                    </RestaurantAccordionItem>;
                })}
            </div>}

            {layoutVariant === 1 && <div className="tile-list">
                {employerState?.drafts?.map(draft => <DraftTile
                    draft={draft}
                    skills={appState?.skillsMap}
                    onItemEditClick={onItemEditClick}
                    onItemDeleteClick={onItemDeleteClick}
                    onItemPublishClick={onItemPublishClick}
                />)
                }
            </div>}
        </div>
    </OverlayLoader>;
};

const deleteDraft = async (id) => {
    let method = 'delete';
    let url = '/employers/me/drafts/'+ id;

    return await Send({
        method,
        url,
    });
};


//TODO: replace body with {gigId: _value_}
const publishDraft = async (id) => {
    let method = 'post';
    let url = '/employers/me/gigs';

    return await Send({
        method,
        url,
        data: `\"${id}\"`,
    });
};

export default DraftsPage;
