import React from 'react';

export const appInitState = {
    appName: 'GPG App',
    appVersion: '0.1',
    user: null,
    restaurants: [],
    skills: [],
    skillsMap: new Map(),
    snacks: [],
    staticHost: null,
    paymentMethods: [],
    stripePaymentActionsModalState: null,
    timeInputFieldType: 0,
};

export const AppContext = React.createContext(appInitState);
