export default (state, action) => {
    try {
        return actions[action.type](state, action.payload);
    }
    catch(err) {
        throw err;
    }
};

const actions = {
    setOffers(state, data) {
        return {...state, offers: data};
    },
};
