export default {
    'en': {
        'translations': {
            'persons': ' persons',
            'person': ' person',
            'select_date': 'Select Date',
            'select_time': 'Select Time',
            'start_date': 'Start Date',
            'start_time': 'Start Time',
            'end_date': 'End Date',
            'end_time': 'End Time',
            'contact_information': 'Contact Information',
            'back': 'Back',
            'book': 'Book',
            'first_name': 'First Name',
            'last_name': 'Last Name',
            'email': 'Email',
            'phone': 'Phone',
            'phone_number': 'Phone Number',
            'required': 'Required',
            'invalid_phone': 'Phone number is not valid',
            'invalid_email': 'Invalid email format',
            'select_restaurant': 'Select restaurant.',
            'comment': 'Comment',
            'your_review': 'Your review',
            'restaurant': 'Restaurant',
            'restaurants': 'Restaurants',
            'my_restaurants': 'My Restaurants',
            'add_a_restaurant':'Add a restaurant',
            'calendar': 'Calendar',
            'show_by': 'Show by',
            'published': 'Published',
            'published_gigs': 'Gigs',
            'report': 'Report',
            'price_per_hour': 'Price per hour',
            'fixed_price': 'Fixed price after taxes',
            'total': 'Total',
            'answers': 'Answers',
            'approved': 'Approved',
            'finished': 'Finished',
            'in_progress': 'In Progress',
            'hiring': 'Hiring',
            'choose_restaurant': 'Choose Restaurant',
            'type_of_professional': 'Type of professional',
            'description': 'Description',
            'main_information': 'Main information',
            'time': 'Time',
            'add_a_gig': 'Add a gig',
            'no_payment_method_defined': 'No payment method defined',
            'please_define_at_least_one_payment_method': 'Please define at least one payment method for restaurant',
            'then_you_will_be_able_to_create_a_gig': 'Then you will be able to create a gig',
            'cancel':'Cancel',
            'go_to_payment_settings': 'Go to payment settings',
            'you_have_new_application': 'You have new application',
            'go_to': 'Go to',
            'gig_page': 'gig page',
            'and_check_it_out':'and check it out',
            'profile':'Profile',
            'site':'Site',
            'drafts': 'Drafts',
            'archive': 'Archive',
            'employers': 'Employers',
            'about_gigsly': 'About Gigsly',
            'founders_letter': 'Founder’s Letter',
            'my_gigsly':'My GPG App',
            'personal_info':'Personal Info',
            'payment_options':'Payment options',
            'reports':'Reports',
            'settings':'Settings',
            'support':'Support',
            'help_centre':'Help Center',
            'cancellation_options':'Cancellation Options',
            'privacy':'Privacy',
            'terms':'Terms',
            'gdpr':'GDPR',
            'do_not_forget_to_check_applications_for_gigsler_out':'Do not forget to check applications for gigslers out',
            'all_rights_reserved':'All rights reserved',
            'name':'Name',
            'restaurant_name':'Restaurant name',
            'address':'Address',
            'website':'Website',
            'organisation_number':'Organisation number',
            'open':'Open',
            'close':'Close',
            'gallery':'Gallery',
            'drag_n_drop':'Drag ‘n’ drop some files here, or click to select',
            'set_as_cover':'Set as cover',
            'cover':'Cover',
            'save':'Save',
            'create':'Create',
            'edit':'Edit',
            'edit_profile_info':'Edit profile info',
            'opening_hours':'Opening Hours',
            'new_restaurant':'New restaurant',
            'edit_restaurant': 'Edit restaurant',
            'name_is_required':'Name is required',
            'this_field_will_be_used_as':'This field will be used as Gig’s name for Employee',
            'add_information_about_work':'Add information about work',
            'gig_price':'Gig price after taxes',
            'fee':'Fee',
            'approximate_employee_tax':'Approximate employee tax',
            'estimated_employee_tax':'Estimated Employee Tax',
            'employer_tax':'Employer tax',
            'estimated_employer_tax':'Estimated Employer Tax',
            'total_cost':'Total Cost',
            'estimated_total_cost': 'Estimated Total Cost',
            'paid_with_stripe':'Paid with Stripe',
            'employee_tax':'Employee Tax',
            'super_total':'Super total',
            'full_name':'Full Name',
            'date_of_birth':'Date Of Birth',
            'gender':'Gender',
            'info':'Info',
            'payment_methods':'Payment methods',
            'add_payment_method':'Add payment method',
            'set_as_primary':'Set as primary',
            'remove':'Remove',
            'primary':'Primary',
            'gig_total':'Gig total',
            'hired':'hired',
            'gig_information':'Gig information',
            'all_applicants':'All applicants',
            'hired_cap':'Hired',
            'duration':'Duration',
            'recent_jobs':'Recent Jobs',
            'no_reviews_here_yet':'No reviews here yet',
            'no_description_was_provided':'No description was provided',
            'hire_employee':'Hire Employee',
            'review(s)':'review(s)',
            'review':'Review',
            'hire':'Hire',
            'payments':'Payments',
            'show':'Show',
            'more':'more',
            'less':'less',
            'your_review_will_help_others':'Your review will help others',
            'date':'Date',
            'status':'Status',
            'amount':'Amount',
            'withdrawal':'Withdrawal',
            'cancelled':'CANCELLED',
            'approve_payout':'Approve payout',
            'sek_per_hour':'SEK per hour',
            'new':'New',
            'invalid_phone_number':'Invalid Phone Number',
            'phone_is_required':'Phone is required',
            'invalid_website_url':'Invalid Website URL',
            'address_is_required':'Address is required',
            'email_is_required':'Email is required',
            'invalid_organisation_number':'Invalid Organisation number',
            'organisation_number_is_required':'Organisation number is required',
            'description_is_required':'Description is required',
            'payment_information_sent_by':'Payment information sent by',
            'issued_for_payment_recipient':'Issued for payment recipient (Moonliter)',
            'date_of_transaction':'Date of Transaction',
            'payment_issued_by':'Payment issued by',
            'pay_for_help_provided_through_ad':'Pay for help provided through ad',
            'restaurant_is_required':'Restaurant is required',
            'start_time_is_required':'Start time is required',
            'price_is_required':'Price is required',
            'professional_type_is_required':'Professional type is required',
            'gig_is_in_progress_do_you':'Gig is in progress. Do you really want to approve payout?',
            'received': 'Received',
            'available': 'Available',
            'no_new_applicants':'No new applicants',
            'no_contracts_yet':'No contracts yet',
            'user_info':'User Info',
            'sign_out':'Sign Out',
            'download_report':'Download report',
            'please_select_date_and_time':'Please select date and time before price',
            'history':'History',
            'invitations':'Invitations',
            'yes':'Yes',
            'no':'No',
            'are_you_sure_you_want_to_cancel_this_hired':'Are you sure you want to cancel this hired? This cannot be undone.',
            'retry_payout':'Retry Payout',
            'group_by_employee': 'Group by employee',
            'total_price': 'Total price',
            'info_will_be_unlocked_after_hiring':'Info will be unlocked after hiring',
            'use_fixed_price':'Use fixed price',
            'default':'Default',
            'set_default':'Set Default',
            'please_add_payment_methods_for_the_restaurant':'Please add payment methods for the restaurant. You can left this as is, but it is mandatory for gig creation.',
            'add_and_manage_your_payment_methods_using_our_secure_payment_system':'Add and manage your payment methods using our secure payment system',
            'next_step':'next step',
            'create_restaurant':'create restaurant',
            'save_restaurant':'Save restaurant',
            'no_data':'No data',
            'try_to_create_a_gig_first':'Try to create a gig first',
            'managers':'Managers',
            'pending':'Pending',
            'owner':'Owner',
            'send':'send',
            'send_invite':'send invite',
            'invite_by_email':'Invite',
            'remove_manager':'Remove manager',
            'generate_report': 'Generate a report',
            'skip_for_now': 'Skip for now',
            'restaurant_info': 'Restaurant info',
            'manager_with_the_same_email_already_exists': 'Manager with the same email already exists',
            'meal_deduction':'Meal Deduction',
            'ob_base_compensation':'OB Base Compensation',
            'ob_night_compensation':'OB Night Compensation',
            'gross_salary':'Gross salary',
            'net_salary':'Net salary',
            'integration':'Integration',
            'set_new_token':'Set New Token',
            'token_is_set':'Token Is Set',
            'reset_token':'Reset Token',
            'rating':'Rating',
            'blocked':'Blocked',
            'binded_with':'Binded with',
            'last_signed_in':'Last Signed in',
            'to_see_integrations':'To see integrations, please type a token',
            'want_to_reset_token':'Do you want to reset the token?',
            'no_gigslers_found':'No gigslers found',
        },
    },
    'sv': {
        'translations': {
            'persons': ' personer',
            'person': ' person',
            'select_date': 'Välj Datum',
            'select_time': 'Välj tid',
            'start_date': 'Startdatum',
            'start_time': 'Starttid',
            'end_date': 'Slutdatum',
            'end_time': 'Sluttid',
            'contact_information': 'Kontaktinformation',
            'back': 'Tillbaka',
            'book': 'Boka',
            'first_name': 'Förnamn',
            'last_name': 'Efternamn',
            'email': 'Epost',
            'phone': 'Telefon',
            'phone_number': 'Telefonnummer',
            'required': 'Obligatoriskt',
            'invalid_phone': 'Telefonnummer är inte giltigt',
            'invalid_email': 'Ogiltigt e-postformat',
            'select_restaurant': 'Välj restaurang.',
            'comment': 'Kommentar',
            'your_review': 'Din recension',
            'restaurant': 'Restaurang',
            'restaurants': 'Restauranger',
            'my_restaurants': 'Mina Restauranger',
            'add_a_restaurant':'Lägg till en restaurang',
            'calendar': 'Kalender',
            'show_by': 'Visa efter',
            'published': 'Publicerad',
            'published_gigs': 'Gig',
            'report': 'Rapport',
            'price_per_hour': 'Pris per timme efter skatt',
            'fixed_price': 'Fast pris efter skatt',
            'total': 'Total',
            'answers': 'Svar',
            'approved': 'Godkänd',
            'finished': 'Färdiga',
            'in_progress': 'Pågående',
            'hiring': 'Anställning pågår',
            'choose_restaurant': 'Välj restaurang',
            'type_of_professional': 'Typ av arbete',
            'description': 'Beskrivning',
            'main_information': 'Basinformation',
            'time': 'Tid',
            'add_a_gig': 'Skapa ett gig',
            'no_payment_method_defined': 'Ingen betalningsmetod definierad',
            'please_define_at_least_one_payment_method': 'Definiera minst en betalningsmetod för restaurangen',
            'then_you_will_be_able_to_create_a_gig': 'Då kommer du att kunna skapa gig',
            'cancel':'Avbryt',
            'go_to_payment_settings': 'Gå till betalningsinställningar',
            'you_have_new_application': 'Du har en ny ansökan',
            'go_to': 'Gå till',
            'gig_page': 'gigs sidan',
            'and_check_it_out':'och kolla in det',
            'profile':'Profil',
            'site':'Webbplats',
            'drafts': 'Utkast',
            'archive': 'Arkiv',
            'employers': 'Arbetsgivare',
            'about_gigsly': 'Om Gigsly',
            'founders_letter': 'Grundarens brev',
            'my_gigsly':'Min Gigsly',
            'personal_info':'Personlig information',
            'payment_options':'Betalningsalternativ',
            'reports':'Rapporter',
            'settings':'Inställningar',
            'support':'Support',
            'help_centre':'Hjälpcenter',
            'cancellation_options':'Avbokningsalternativ',
            'privacy':'Integritetspolicy',
            'terms':'Villkor',
            'gdpr':'GDPR',
            'do_not_forget_to_check_applications_for_gigsler_out':'Glöm inte att kolla in applikationer för gigslers',
            'all_rights_reserved':'Alla rättigheter förbehållna',
            'name':'Namn',
            'restaurant_name':'Restaurangnamn',
            'address':'Adress',
            'website':'Hemsida',
            'organisation_number':'Organisationsnummer',
            'open':'Öppna',
            'close':'Stäng',
            'gallery':'Galleri',
            'drag_n_drop':'Dra och släpp några filer här, eller klicka för att välja',
            'set_as_cover':'Skivomslag',
            'cover':'Omslag',
            'save':'Spara',
            'create':'Skapa',
            'edit':'Redigera',
            'edit_profile_info':'Redigera profilinformation',
            'opening_hours':'Öppettider',
            'new_restaurant':'Ny restaurang',
            'edit_restaurant': 'Redigera restaurang',
            'name_is_required':'Namn krävs',
            'this_field_will_be_used_as':'Det här fältet kommer att användas som Gigs namn för anställd',
            'add_information_about_work':'Lägg till information om arbetet',
            'gig_price':'Gigpris efter skatt',
            'fee':'Avgift',
            'approximate_employee_tax':'Ungefärlig skatt',
            'estimated_employee_tax':'Beräknad personalskatt',
            'employer_tax':'Arbetsgivaravgift',
            'estimated_employer_tax':'Beräknad arbetsgivarskatt',
            'total_cost':'Total kostnad',
            'estimated_total_cost': 'Beräknad total kostnad',
            'paid_with_stripe':'Betalas med Stripe',
            'employee_tax':'Skatt',
            'super_total':'Super totalt',
            'full_name':'Fullständig Namn',
            'date_of_birth':'Födelsedatum',
            'gender':'Kön',
            'info':'Info',
            'payment_methods':'Betalningsmetoder',
            'add_payment_method':'Lägg till betalningsmetod',
            'set_as_primary':'Ange som primär',
            'remove':'Ta bort',
            'primary':'Primär',
            'gig_total':'Gig total',
            'hired':'Anställda',
            'gig_information':'Gig information',
            'all_applicants':'Alla sökande',
            'hired_cap':'Anlitade',
            'duration':'Varaktighet',
            'recent_jobs':'Senaste jobb',
            'no_reviews_here_yet':'Inga recensioner ännu',
            'no_description_was_provided':'Ingen beskrivning tillhandahölls',
            'hire_employee':'Anställ',
            'review(s)':'Recension(er)',
            'review':'Recension',
            'hire':'Anställ',
            'show':'Visa',
            'more':'mer',
            'less':'mindre',
            'payments':'Betalningar',
            'your_review_will_help_others':'Din recension kommer att hjälpa andra',
            'date':'Datum',
            'status':'Status',
            'amount':'Belopp',
            'withdrawal':'Uttag',
            'cancelled':'INSTÄLLT',
            'approve_payout':'Godkänn utbetalning',
            'sek_per_hour':'SEK per timme',
            'new':'Ny',
            'invalid_phone_number':'Ogiltigt Telefonnummer',
            'phone_is_required':'Telefon krävs',
            'invalid_website_url':'Ogiltig Webbadress',
            'address_is_required':'Adress krävs',
            'email_is_required':'E-post krävs',
            'organisation_number_is_required':'Organisationsnummer krävs',
            'invalid_organisation_number':'Ogiltigt Organisationsnummer',
            'description_is_required':'Beskrivning krävs',
            'payment_information_sent_by':'Betalningsinformation skickad av',
            'issued_for_payment_recipient':'Utfärdad för betalningsmottagare',
            'date_of_transaction':'Datum för transaktion',
            'payment_issued_by':'Betalning utfärdad av',
            'pay_for_help_provided_through_ad':'Betala för hjälp som tillhandahålls via annonsering',
            'restaurant_is_required':'Restaurang krävs',
            'start_time_is_required':'Starttid krävs',
            'price_is_required':'Pris krävs',
            'professional_type_is_required':'Typ av profession krävs',
            'gig_is_in_progress_do_you':'Gig pågår. Vill du verkligen godkänna utbetalningen?',
            'received': 'Mottagen',
            'available': 'Tillgänglig',
            'no_new_applicants':'Inga nya sökande',
            'no_contracts_yet':'Inga kontrakt ännu',
            'user_info':'Användarinformation',
            'sign_out':'Logga ut',
            'download_report':'Ladda ner rapport',
            'please_select_date_and_time':'Välj datum och tid före pris',
            'history':'Historia',
            'invitations':'Inbjudningar',
            'yes':'Ja',
            'no':'Nej',
            'are_you_sure_you_want_to_cancel_this_hired':'Är du säker på att du vill avbryta den anställda? Detta kan inte göras ogjort.',
            'retry_payout':'Försök igen med utbetalning',
            'group_by_employee': 'Gruppera efter anställd',
            'total_price': 'Totalbelopp',
            'info_will_be_unlocked_after_hiring':'Information kommer att låsas upp efter anställning',
            'use_fixed_price':'Använd fast pris',
            'default':'Standard',
            'set_default':'Sätta som normalt',
            'please_add_payment_methods_for_the_restaurant':'Lägg till betalningsmetoder för restaurangen. Du kan lämna detta som det är, men det är obligatoriskt för spelning.',
            'add_and_manage_your_payment_methods_using_our_secure_payment_system':'Lägg till och hantera dina betalningsmetoder med vårt säkra betalningssystem',
            'next_step':'Nästa steg',
            'create_restaurant':'Skapa Restaurang',
            'save_restaurant':'Spara Restaurang',
            'no_data':'Inga data',
            'try_to_create_a_gig_first':'Försök att skapa en gig först',
            'managers':'Chefer',
            'pending':'I väntan på',
            'owner':'Ägare',
            'send':'skicka',
            'send_invite':'skicka inbjudan',
            'invite_by_email':'Bjud in via e-post',
            'remove_manager':'Ta bort chefen',
            'generate_report': 'Generera en rapport',
            'skip_for_now': 'Hoppa över nu',
            'restaurant_info': 'Restauranginformation',
            'manager_with_the_same_email_already_exists': 'Chef med samma e-postadress finns redan',
            'meal_deduction':'Måltidsavdrag',
            'ob_base_compensation':'Ordinarie OB-ersättning',
            'ob_night_compensation':'OB-ersättning vid nattarbete',
            'gross_salary':'Lön före skatt',
            'net_salary':'Lön efter skatt',
            'integration':'Integration',
            'set_new_token':'Ställ in ny token',
            'to_see_integrations':'För att se integrationer, skriv en token',
            'token_is_set':'Token är satt',
            'reset_token':'Återställ token',
            'want_to_reset_token':'Vill du återställa token?',
            'rating':'Betyg',
            'last_signed_in':'Senast inloggad',
            'blocked':'Blockerad',
            'binded_with':'Bind med',
            'no_gigslers_found':'Inga Gigslers hittades',

        },
    },
};
