import { createContext, useContext, useReducer } from "react";

const reducer = (state, action) => {
    try {
        return actions[action.type](state, action.payload);
    }
    catch(err) {
        throw err;
    }
};

const actions = {
    setView(state, viewKey) {
        let newState = { ...state };
        newState[viewKey] = defaultSharedView;

        return newState;
    },
    updateViewProps(state, { viewKey, props }) {
        var newState = { ...state };
        newState[viewKey] = { ...newState[viewKey], props };

        return newState;
    },
    updateViewContainer(state, { viewKey, container }) {
        var newState = { ...state };
        newState[viewKey] = { ...newState[viewKey], container };

        return newState;
    },
};

const defaultSharedView = { props: null, container: null };
const defaultSharedViews = {};

export const SharedViewsContext = createContext(defaultSharedViews);

export const useSharedViewsContext = (viewKey) => useContext(SharedViewsContext).sharedViewsState[viewKey];
export const useSharedViewPropsValue = (viewKey) => useSharedViewsContext(viewKey)?.props || {};
export const useSharedViewContainerValue = (viewKey) => useSharedViewsContext(viewKey)?.container || null;

export const SharedViewsContextProvider = ({ children }) => {
    const [sharedViewsState, sharedViewsDispatch] = useReducer(reducer, defaultSharedViews);

    return (
        <SharedViewsContext.Provider value={{ sharedViewsState, sharedViewsDispatch }}>
            {children}
        </SharedViewsContext.Provider>
    );
};