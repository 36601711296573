export const normalizeTime = (value, previousValue) => {
    if (value !== '')
        if (/^([01]{1}[0-9]|2[0-3]):[0-5]{1}[0-9]{1}$/.test(value)) {
            previousValue = value;
            return value;
        } else if(/[^0-9:]/.test(value)){
            return previousValue;
        } else if(previousValue === value) {
            //blur event
            let split = value.split(':');
            if(split.length === 1 || split.length === 2 && split[1] === '') {
                return split[0] + ':00';
            } else if(split.length === 2 && split[1].length === 1){
                //input example: 12.5
                if (split[1] < 6 ) {
                    return value + '0';
                } else {
                    return split[0] + ':59';
                }
            } else if(split.length === 2 && split[0].length === 1){
                //input example: 9:30
                if(split[0] < 10){
                    split[0] =  '0'+parseInt(split[0]);
                    return split[0]+':'+split[1];
                }
            }
        } else {
            var split = value.split(':');
            var currentValueContainSemicolon = value.indexOf(':') !== -1;

            var previousValueContainSemicolon = previousValue.indexOf(':') !== -1;

            if (split.length == 1){
                if (parseFloat(split[0], 10) > 23){
                    if (split[0].length == 2) {
                        split[0] = 23;
                    } else if(split[0].length > 2) {
                        split[0] = value[0] + '' + value[1];
                        split[1] = value.substr(2);
                    }
                }

                if (split[0].length < 2) {
                    let output = split[0];
                    return output;
                }

                if (previousValueContainSemicolon && !currentValueContainSemicolon){
                    let output = split[0];
                    return output;
                }

                let output = split[0] + ':';
                return output;

            } else {
                if (parseFloat(split[0], 10) > 23) split[0] = 23;
                if (parseFloat(split[1], 10) > 59) split[1] = 59;
                let output = split[0] + ':' + split[1];
                return output;
            }
        } else {
        return '';
    }
};
