import React from 'react';
import Button from '@material-ui/core/Button';
import {Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {ArrowLeftIcon} from '@material-ui/pickers/_shared/icons/ArrowLeftIcon';
import {useMediaQuery} from 'react-responsive';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';
import RestaurantPaymentButton from './RestaurantPaymentButton';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const RestaurantPaymentOptionsWrapper = ({handleFinish, className, handleBack, ...rest})=>{
    const [t] = useTranslation();
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);


    return <div className={className}>
        <Box className="flex-align-horizontal-middle full-width payment-tip">
            <ErrorOutlineIcon color="primary"/>
            <Box ml={2}>
                Please add payment methods for restaurant. You can left it as is but it’s mandatory for creating gigs.
            </Box>
        </Box>
        <RestaurantPaymentButton {...rest}/>
        {isDesktop && <Box my={2} className="space-between">
            <Button color="secondary" variant="outlined" onClick={handleBack} size="large">
                <ArrowLeftIcon/> {t('back')}
            </Button>
            <Button color="secondary" variant="outlined" size="large" onClick={handleFinish}>
                {t('skip_for_now')}
            </Button>
        </Box>}

        {!isDesktop && <Box my={2} className="flex-column">
            <Button color="secondary" variant="outlined" onClick={handleBack} size="large">
                <ArrowLeftIcon/> {t('back')}
            </Button>
            <Button color="secondary" variant="outlined" size="large" onClick={handleFinish}>
                {t('skip_for_now')}
            </Button>
        </Box>}
    </div>;
};

export default RestaurantPaymentOptionsWrapper;
