import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/core/styles';
import {CompareArrows, CompareArrowsOutlined, CompareArrowsTwoTone} from '@material-ui/icons';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

import '../Integration.scss';

const BindingsTable = ({staff, moonlighters, bindings, setItemsToDelete}) => {
    const bindStaffId = bindings.map((item) => item.externalId);

    const extIntIdsMap = {};
    bindings.forEach((item) => extIntIdsMap[item.externalId] = item.internalId);

    const moonlightersMap = {};
    moonlighters.forEach((item) => moonlightersMap[item.id] = item);

    return (<div>
        {staff?.map(({firstName, lastName, email, userPicUrl, externalId, personalNumber}) => {
            return bindStaffId.includes(externalId) ?
                <div key={externalId}>
                    <div className="space-between flex-align-horizontal-middle">
                        <div className="left-side-staff">
                            <FormControlLabel
                                required
                                control={
                                    <Checkbox
                                        onClick={(event)=>{
                                            if (event.target.checked) {
                                                setItemsToDelete(prevState => [...prevState, extIntIdsMap[externalId]]);
                                            } else {
                                                setItemsToDelete(prevState => prevState.filter((item) => item !== extIntIdsMap[externalId]));
                                            }

                                        }}
                                    />}
                            />
                            <div className="avatar">
                                {userPicUrl
                                    ? <img src={userPicUrl + '.jpg'}/>
                                    : <AccountCircleOutlinedIcon/>
                                }
                            </div>
                            <div className="integration-staff">
                                <div className="primary_black fs-16">
                                    {firstName}&nbsp;{lastName}
                                </div>
                                <div className="additional_grey_2 fs-12">
                                    {email}
                                </div>
                                <div className="additional_grey_2 fs-12">
                                id:&nbsp;{personalNumber}
                                </div>
                            </div>
                        </div>
                        <CompareArrows/>
                        <div className="right-side-gigsler">
                            <div className="integration-select">
                                <div className="primary_black fs-16">
                                    {moonlightersMap[extIntIdsMap[externalId]].fullName}
                                </div>
                                <div className="additional_grey_2 fs-12">
                                    {moonlightersMap[extIntIdsMap[externalId]].email}
                                </div>
                                <div className="additional_grey_2 fs-12">
                                    id:&nbsp;{moonlightersMap[extIntIdsMap[externalId]].id}
                                </div>
                            </div>

                        </div>
                    </div>

                    <Divider/>
                </div> : null ;
        })}

    </div>
    );
};

export default BindingsTable;
