import React from 'react';
import { Redirect, Route,Switch } from 'react-router-dom';

import MainLayout from '../../Layouts/MainLayout/MainLayout';
import UnauthorizedLayout from '../../Layouts/UnauthorizedLayout/UnauthorizedLayout';
import DraftsPage from '../../Pages/Employer/DraftsPage/DraftsPage';
import GigViewPage from '../../Pages/Employer/DraftsPage/GigViewPage';
import GigListPage from '../../Pages/Employer/GigListPage/GigListPage';
import HistoryPage from '../../Pages/Employer/HistoryPage/HistoryPage';
import ManagersPage from '../../Pages/Employer/ManagersPage/ManagersPage';
import MoonlightersPage from '../../Pages/Employer/MoonlightersPage/MoonlightersPage';
import ReceiptPage from '../../Pages/Employer/ReceiptPage/ReceiptPage';
import ReportPage from '../../Pages/Employer/ReceiptPage/ReportPage';
import RestaurantEditPage from '../../Pages/Employer/Restaurant/RestaurantEditPage';
import UserProfilePage from '../../Pages/Employer/UserProfilePage/UserProfilePage';
import EmployerRestaurantList from '../EmployerRestaurantList/EmployerRestaurantList';
import GigEdit from '../GigEdit/GigEdit';
import InvitedManagerLanding from '../InvitedManagerLanding/InvitedManagerLanding';
import Onboarding from '../Onboarding/Onboarding';
import Privacy from '../Privacy/Privacy';
import Terms from '../Terms/Terms';

import AppRoute from './AppRoute';

const AppRouter = () => {
    return (
        <Switch>
            <Route path={'/auth/employer/email-magic-link'}  exact render={() => <Redirect to="/" />} />
            <AppRoute path={'/'} exact layout={MainLayout} component={GigListPage} />
            <AppRoute path={'/onboarding'} exact layout={UnauthorizedLayout} component={Onboarding} />
            <AppRoute path={'/drafts'} exact layout={MainLayout} component={DraftsPage} />
            <AppRoute path={'/gig/:id'} exact layout={MainLayout} component={GigViewPage} />
            <AppRoute path={'/gig/edit/:id'} exact layout={MainLayout} component={GigEdit}/>
            <AppRoute path={'/profile'} exact layout={MainLayout} component={UserProfilePage} />
            <AppRoute path={'/restaurants'} exact layout={MainLayout} component={EmployerRestaurantList} onRestaurantClick={()=>{}}/>
            <AppRoute path={'/restaurant/edit/:id'} exact layout={MainLayout} component={RestaurantEditPage}/>
            <AppRoute path={'/receipt'} exact layout={MainLayout} component={ReceiptPage}/>
            <AppRoute path={'/report'} exact layout={MainLayout} component={ReportPage}/>
            <AppRoute path={'/terms'} exact layout={MainLayout} component={Terms}/>
            <AppRoute path={'/privacy'} exact layout={MainLayout} component={Privacy}/>
            <AppRoute path={'/history'} exact layout={MainLayout} component={HistoryPage}/>
            <AppRoute path={'/employers/me/restaurants/:id/invitations'} exact layout={MainLayout} component={InvitedManagerLanding}/>
            <AppRoute path={'/gigslers'} exact layout={MainLayout} component={MoonlightersPage}/>
            <AppRoute path={'/managers'} exact layout={MainLayout} component={ManagersPage}/>
        </Switch>
    );
};

export default AppRouter;
