import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';

import './index.scss';

const ActionsOverlay = ({removeHandler, children}) => {
    const [t] = useTranslation();

    return (
        <div className="remove-overlay">
            <div className="loader">
                <div className="remove-circle pointer" onClick={(e) => {e.stopPropagation(); removeHandler();}}>
                    <CloseIcon/>
                </div>
            </div>
            {children}
        </div>
    );
};

export default ActionsOverlay;
