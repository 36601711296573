import React from 'react';
import Box from '@material-ui/core/Box';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const EmployeeInfoBar = ({contract}) => {

    return (
        <div className="fs-14 right-column additional_grey_1">
            <div>
                <Box>
                    {contract?.moonlighter?.phoneNumber &&
                    <Box className="flex-align-horizontal-middle fs-14">
                        <SmartphoneIcon className="additional_grey_3"/>&nbsp;
                        <a className="additional_grey_1" href={`tel:${contract.moonlighter.phoneNumber}`}>{contract.moonlighter.phoneNumber}</a>
                    </Box>}
                </Box>
                <Box>
                    {contract?.moonlighter?.email &&
                <Box className="flex-align-horizontal-middle fs-14">
                    <MailOutlineIcon  className="additional_grey_3"/>&nbsp;
                    <a className="additional_grey_1" href={`mailto:${contract.moonlighter.email}`}>{contract.moonlighter.email}</a>
                </Box>}
                </Box>
            </div>
        </div>
    );
};

export default EmployeeInfoBar;
