import React, {Fragment, useContext, useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import {Images} from '../../static/images';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import {AppContext} from '../../Contexts/AppContext';
import {EmployerContext} from '../../Contexts/EmployerContext';
import './index.scss';

const RestaurantHeader = ({restaurantId}) => {
    const [restaurant, setRestaurant] = useState(null);
    const {appState} = useContext(AppContext);
    const {employerState} = useContext(EmployerContext);

    useEffect(() => {
        setRestaurant(employerState.restaurants.find((item => item.id === restaurantId)));
    }, []);
    return (
        <div className="restaurant-header">
            <Box mr={2} className="image-circle">
                <img src={restaurant?.coverPhotoHash ? appState.staticHost+'/'+restaurant.coverPhotoHash+'.jpg?size=160' : Images.RestaurantLogoFish}/>
            </Box>
            <div>
                <div className="primary_black fs-34">
                    <span> {restaurant?.name} </span>
                </div>
                <div className="additional_grey_2 fs-14 restaurant-location flex-align-horizontal-middle">
	                <LocationOnOutlinedIcon className="location-icon"/><span>&nbsp;{restaurant?.address}</span>
                </div>
            </div>
        </div>
    );
};

export default RestaurantHeader;
