import { useContext, useEffect, useState } from 'react';

import { SharedViewsContext } from './SharedViewsContext';

export const SharedViewContainer = ({ viewKey, ...viewsProps }) => {
    const { sharedViewsDispatch } = useContext(SharedViewsContext);
    const [portalContainer, setPortalContainer] = useState(null);

    const updateViewProps = props => {
        sharedViewsDispatch({
            type: 'updateViewProps',
            payload: { viewKey, props },
        });
    };

    const updateViewContainer = container => {
        sharedViewsDispatch({
            type: 'updateViewContainer',
            payload: { viewKey, container },
        });
    };

    useEffect(() => updateViewProps(viewsProps), []);

    useEffect(() => updateViewContainer(portalContainer), [portalContainer]);

    return (
        <div ref={setPortalContainer} />
    );
};

export const SharedView = ({ viewKey, ContentView }) => {
    const { sharedViewsState, sharedViewsDispatch } = useContext(SharedViewsContext);

    useEffect(() => {
        sharedViewsDispatch({
            type: 'setView',
            payload: viewKey,
        });
    }, []);

    const contentViewProps = sharedViewsState[viewKey] == undefined || sharedViewsState[viewKey].props == undefined
        ? {}
        : sharedViewsState[viewKey].props;

    return (<>
        <ContentView {...contentViewProps} />
    </>);
};
