import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import {useRouteMatch} from 'react-router-dom';
import {Box} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import HistoryDetails from '../../../Components/HistoryDetails/HistoryDetails';
import HistoryList from '../../../Components/HistoryList';
import usePageScrollLock from '../../../Hooks/usePageScrollLock';
import {IS_DESKTOP_MEDIA_QUERY} from '../../../Misc/constants';

import './HistoryPage.scss';

const HistoryPage = () => {
    const [completedContracts, setCompletedContracts] = useState([]);
    const [selectedContract, setSelectedContract] = useState('');
    const [layoutVariant, setLayoutVariant] = useState(0);
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const [t] = useTranslation();


    const loadCompletedContracts = (data) => {
        setCompletedContracts(data);
    };

    const onSelect = (id) => {
        if (selectedContract === completedContracts.find(x => x.id === id)) {
            setSelectedContract('');
        } else {
            setSelectedContract(completedContracts.find(x => x.id === id));
        }
    };

    const onCloseDetails = () => {
        setSelectedContract('');
    };
    return <>
        <Helmet>
            <title>GPG App - History</title>
        </Helmet>
        <div className="history">
            <Box className="page">
                {(selectedContract === '') && <div>
                    <div className="filters">
                        <div className="flex-align-horizontal-middle select">
                            <span className="fs-14 additional_grey_2">{t('show_by')}: </span>
                            <FormControl>
                                <Select
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    id="layout-select"
                                    value={layoutVariant}
                                    onChange={(e) => setLayoutVariant(e.target.value)}
                                >
                                    <MenuItem value={0}>All time</MenuItem>
                                    <MenuItem value={1}>{t('restaurant')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <HistoryList contracts={completedContracts}
                        onLoadContract = {loadCompletedContracts}
                        onSelect={onSelect}
                    />
                </div>}
                {selectedContract?.length !== 0 && <HistoryDetails contract={selectedContract} onClose={onCloseDetails}/>}

            </Box>


        </div>
    </>;
};

export default HistoryPage;
