import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import moment from 'moment';

import { AppContext } from '../../Contexts/AppContext';
import { getSkills } from '../../Misc/backend';
import { IS_DESKTOP_MEDIA_QUERY } from '../../Misc/constants';
import { Images } from '../../static/images';
import FooterColumn from '../FooterColumn/FooterColumn';

import './index.scss';

const Footer = () => {
    const [t, i18n] = useTranslation();
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const [language, setLanguage] = useState('en');
    const { appDispatch } = useContext(AppContext);

    useEffect(() => {
        async function reloadSkills(language) {
            const skills = await getSkills(language);
            appDispatch({
                type: 'setSkills',
                payload: skills,
            });
        }

        if (i18n.translator?.resourceStore.data.hasOwnProperty(i18n.language)) {
            moment.locale(i18n.language);
            setLanguage(i18n.language);
            reloadSkills(i18n.language);
        }
    }, [i18n.language]);

    return (
        <div className="footer roboto">
            <div className="menu">
                <FooterColumn title={t('site')}>
                    <NavLink to="/" exact>
                        <Box mb={1.5} className="additional_grey_2 fs-14">
                            {t('published_gigs')}
                        </Box>
                    </NavLink>
                    <NavLink to="/restaurants" exact>
                        <Box mb={1.5} className="additional_grey_2 fs-14">
                            {t('restaurants')}
                        </Box>
                    </NavLink>
                    <NavLink to="/history" exact>
                        <Box mb={1.5} className="additional_grey_2 fs-14">
                            {t('archive')}
                        </Box>
                    </NavLink>
                </FooterColumn>
                <FooterColumn title={t('my_gigsly')}>
                    <NavLink to="/profile" exact>
                        <Box mb={1.5} className="additional_grey_2 fs-14">
                            {t('personal_info')}
                        </Box>
                    </NavLink>
                    <NavLink to="/report" exact>
                        <Box mb={1.5} className="additional_grey_2 fs-14">
                            {t('reports')}
                        </Box>
                    </NavLink>
                </FooterColumn>
                <FooterColumn title={t('support')}>
                    <a href="mailto:support@gigsly.app" target="_blank">
                        <Box mb={1.5} className="additional_grey_2 fs-14">
                            {t('help_centre')}
                        </Box>
                    </a>
                    <NavLink to="/privacy" exact>
                        <Box mb={1.5} className="additional_grey_2 fs-14">
                            {t('privacy')}
                        </Box>
                    </NavLink>
                    <NavLink to="/terms" exact>
                        <Box mb={1.5} className="additional_grey_2 fs-14">
                            {t('terms')}
                        </Box>
                    </NavLink>
                </FooterColumn>
                {language && <Box mb={2}>
                    <FormControl fullWidth>
                        <Select
                            fullwidth
                            native={!isDesktop}
                            value={language}
                            onChange={event => i18n.changeLanguage(event.target.value)}
                            inputProps={{
                                name: 'language',
                            }}
                        >
                            {!isDesktop && <option value=""></option>}
                            {!isDesktop && <option value="en">English</option>}
                            {!isDesktop && <option value="sv">Svenska</option>}
                            {isDesktop && <MenuItem value=''></MenuItem>}
                            {isDesktop && <MenuItem value='en'>English</MenuItem>}
                            {isDesktop && <MenuItem value='sv'>Svenska</MenuItem>}
                        </Select>
                    </FormControl>
                </Box>}
            </div>
            <Divider />
            <Box mt={2} className="additional_grey_2 fs-14">
                © 2024 GPG App, Inc. {t('all_rights_reserved')}
            </Box>
        </div>
    );
};

export default Footer;
