import React, {useEffect, useContext, useState} from 'react';
import {useFormik} from 'formik';
import {Box, Grid} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import LocationSearchInput from '../../Components/LocationSearchInput/LocationSearchInput';
import Button from '@material-ui/core/Button';
import {Send} from '../../Misc/backend';
import {restaurantMainInfoTemplate} from '../../Misc/restaurant';
import * as yup from 'yup';
import GoogleMapReact from 'google-map-react';
import {ReactComponent as Pin} from '../../static/images/pin.svg';
import InputMask from 'react-input-mask';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useTranslation} from 'react-i18next';
import {mapServerValidationToFormik} from '../../Misc/validationsErrors';
import {useMediaQuery} from 'react-responsive';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';
import {ArrowLeftIcon} from '@material-ui/pickers/_shared/icons/ArrowLeftIcon';
import {AppContext} from '../../Contexts/AppContext';
import {ArrowRightIcon} from '@material-ui/pickers/_shared/icons/ArrowRightIcon';

const URLRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
const orgNumberRegex = /^[^_]+$/;

const googleApiKey = typeof process.env.REACT_APP_GOOGLE_API_KEY === 'string'
&& process.env.REACT_APP_GOOGLE_API_KEY.length
    ? process.env.REACT_APP_GOOGLE_API_KEY
    : null;

const RestaurantMainInfo = ({onSubmitSuccess, errors, handleBack, ...rest}) => {
    const [t] = useTranslation();
    const [phoneLength, setPhoneLength] = useState(11);
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);

    const validationSchema = yup.object({
        name: yup
            .string()
            .required(t('name_is_required')),
        phoneNumber: yup
            .string()
            .matches(new RegExp(`\\d{${phoneLength}}`), t('invalid_phone_number'))
            .required(t('phone_is_required')),
        website: yup
            .string()
            .nullable()
            .matches(URLRegex, t('invalid_website_url')),
        address: yup
            .string()
            .required('Please select a valid address'),
        orgNr: yup
            .string()
            .matches(orgNumberRegex, t('invalid_organisation_number'))
            .required(t('organisation_number_is_required')),
        description: yup
            .string()
            .max(2000)
            .nullable()
            .required(t('description_is_required')),
    });

    useEffect(()=>{
        if(errors){
            formik.setErrors(errors);
        }
    }, [errors]);

    const formik = useFormik({
        initialValues: restaurantMainInfoTemplate,
        validationSchema: validationSchema,
        onSubmit: onSubmitSuccess,
    });

    const selectAddress = (value) => {
        formik.setFieldValue('address', value.address);
        formik.setFieldValue('latitude', value.lat);
        formik.setFieldValue('longitude', value.lng);
    };

    const handleAddressChange = () => {
        if(formik.values.latitude === 0 && formik.values.latitude === 0){
            formik.setFieldError('address', 'Please select valid address');
        }
    };

    return (
        <div className="restaurant" {...rest}>
            <form onSubmit={formik.handleSubmit}>
                <div className="main-info">
                    <Grid container spacing={3}>
                        <Grid item sm={6} sx={12}>
                            <Box my={0}>
                                <TextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label={t('restaurant_name')}
                                    variant="outlined"
                                    color="secondary"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Box>
                        </Grid>
                        <Grid item sx={12} sm={6}>
                            <Box my={0}>
                                <PhoneInput
                                    country={'se'}
                                    value={formik.values.phoneNumber}
                                    onChange={phone => formik.setFieldValue('phoneNumber', phone)}
                                    placeholder={''}
                                    preferredCountries={['us', 'se']}
                                    isValid={(value, country) => {
                                        const requiredLength = country.format.match(/\./g).length;
                                        setPhoneLength(requiredLength);
                                        if (value.length !== requiredLength && formik.touched.phoneNumber) {
                                            return false;
                                        }
                                        else {
                                            return true;
                                        }
                                    }}
                                />
                                {(formik.errors.phoneNumber && formik.touched.phoneNumber) &&
                                    <FormHelperText style={{marginLeft: 14}} error>{formik.errors.phoneNumber}</FormHelperText>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                    <Box my={2}>
                        <LocationSearchInput
                            fullWidth
                            id="address"
                            name="address"
                            label={t('address')}
                            variant="outlined"
                            color="secondary"
                            onSelect={selectAddress}
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            onBlur={handleAddressChange}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                        />
                        {(formik.errors.address && formik.touched.address) &&
                            <FormHelperText style={{marginLeft: 14}} error>{formik.errors.address}</FormHelperText>
                        }
                    </Box>
                    <Box my={2} className="map-wrapper">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: googleApiKey }}
                            center={{
                                lat: formik.values.latitude,
                                lng: formik.values.longitude,
                            }}
                            defaultZoom={13}
                        >
                            <Pin
                                lat={formik.values.latitude}
                                lng={formik.values.longitude}
                                text={formik.values.name}
                                style={{transform: 'translate(-50%, -90%)'}}
                            />
                        </GoogleMapReact>
                    </Box>
                    <Box my={2}>
                        <TextField
                            fullWidth
                            id="website"
                            name="website"
                            label={t('website')}
                            variant="outlined"
                            color="secondary"
                            value={formik.values.website}
                            onChange={formik.handleChange}
                            error={formik.touched.website && Boolean(formik.errors.website)}
                            helperText={formik.touched.website && formik.errors.website}
                        />
                    </Box>
                    <Box my={2}>
                        <InputMask
                            mask="999999-9999"
                            value={formik.values.orgNr}
                            onChange={(e) => formik.setFieldValue('orgNr', e.target.value)}
                        >
                            {() => <TextField
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                id="orgNr"
                                name="orgNr"
                                label={t('organisation_number')}
                                error={formik.touched.orgNr && Boolean(formik.errors.orgNr)}
                                helperText={formik.touched.orgNr && formik.errors.orgNr}
                            />}
                        </InputMask>
                    </Box>
                    <Box my={2}>
                        <TextField
                            multiline
                            rows={4}
                            rowsMax={4}
                            fullWidth
                            id="description"
                            name="description"
                            label={t('description')}
                            variant="outlined"
                            color="secondary"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                        />
                    </Box>
                </div>
                <div className="align-right">
                    {isDesktop && <Box my={2} className="space-between">
                        <Button color="secondary" variant="outlined" onClick={handleBack} size="large">
                            <ArrowLeftIcon/> {t('back')}
                        </Button>
                        <Button color="primary" variant="contained" size="large" type="submit">
                            <span>{t('next_step')}</span> <ArrowRightIcon/>
                        </Button>
                    </Box>}
                    {!isDesktop && <Box my={2} className="flex-column">
                        <Button color="secondary" variant="outlined" onClick={handleBack} size="large">
                            <ArrowLeftIcon/> {t('back')}
                        </Button>
                        <Button color="primary" variant="contained" type="submit" size="large">
                            {t('next_step')}
                            <ArrowRightIcon/>
                        </Button>
                    </Box>}
                </div>
            </form>
        </div>

    );
};

export default RestaurantMainInfo;
