import React, {useState, Fragment, useEffect, useContext} from 'react';
import {Images} from '../../static/images';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import Box from '@material-ui/core/Box';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Button from '@material-ui/core/Button';
import {AppContext} from '../../Contexts/AppContext';
import { useMediaQuery } from 'react-responsive';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';

import './index.scss';


const RestaurantAccordionItem = ({
    restaurant,
    children,
    onItemAddClick,
    customActions,
    addressPrepend,
    expanded = false,
}) => {
    const [showList, setShowList] = useState(false);
    const {appState} = useContext(AppContext);
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);


    useEffect(()=>{
        if(expanded) {
            setShowList(true);
        } else {
            setShowList(false);
        }
    }, [expanded]);

    return <div className="restaurant-accordion-item">
        <Box className="restaurant-accordion-item-header">
            <div className="restaurant-accordion-item-header-info">
                <Box mr={2} className="image-circle">
                    <img src={restaurant?.coverPhotoHash ? appState.staticHost+'/'+restaurant.coverPhotoHash+'.jpg?size=160' : Images.RestaurantLogoFish}/>
                </Box>
                <div>
                    <div className="primary_black fs-18">
                        <span> {restaurant?.name} </span>
                        {children?.length > 0 &&
                        <Fragment>
                            {isDesktop && <>:&nbsp;<span className="additional_grey_2">{children?.length}</span></>}
                            {showList
                                ? <KeyboardArrowUpIcon onClick={()=>setShowList(false)} className="pointer"/>
                                : <KeyboardArrowDownIcon onClick={()=>setShowList(true)} className="pointer"/>
                            }
                        </Fragment>
                        }

                    </div>
                    <div className="additional_grey_2 fs-14 restaurant-location">
                        {addressPrepend}
                        <span><LocationOnOutlinedIcon className="location-icon"/>&nbsp;{restaurant?.address}</span>

                    </div>
                </div>
            </div>

            <div className="restaurant-accordion-item-header-actions">
                {typeof onItemAddClick === 'function' && <Button
                    onClick={() => onItemAddClick(restaurant)}
                    color="primary"
                    variant="contained"
                    size="large"
                >
                    <AddOutlinedIcon/>&nbsp;Add a gig
                </Button>}
                {customActions}
            </div>
        </Box>

        {showList && <div className="tile-list">
            {children}
        </div>}
    </div>;
};

export default RestaurantAccordionItem;
