class CUser {
    Id = null;

    Type = null;

    FullName = null;

    ContactInfo = null;

    Email = null;

    Rating = 0;

    AvatarImg = '';

    constructor() {
        this.Reload();
    }

    SaveUser(user) {
        window.localStorage.setItem('user', JSON.stringify(user));
        this.Reload();
    }

    SetUser(user) {
        this.Id = user && user.id;
        this.Type = user && user.type;
        this.FullName = user && user.fullName;
        this.Email = user && user.email;
        this.ContactInfo = user && user.contactInfo;
        this.Rating = user && user.rating;
    }

    GetUser() {
        try {
            return JSON.parse(window.localStorage.getItem('user'));
        } catch (err) {
            return {};
        }
    }

    Reload() {
        try {
            const user = JSON.parse(window.localStorage.getItem('user'));
            this.SetUser(user);
        } catch (err) {

        }
    }

    IsEmployer() {
        return this.Type === 'employer';
    }

    IsEmployee() {
        return this.Type === 'employee';
    }

    IsAuthorized() {
        try {
            const token = window.localStorage.getItem('token');
            if (token) return true;
        } catch (err) {}
        return false;
    }
}

const user = new CUser();

export default user;


export const userTemplate = {
    fullName: '',
    phoneNumber: '',
    dateOfBirth: null,
    gender: '',
};
