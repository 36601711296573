import React from 'react';
import moment from 'moment';
import {timespanStringToMomentDuration} from './timespan';
import {Images} from '../static/images';
import {FINISHED, IN_PROGRESS, NOT_YET_STARTED} from './constants';
import i18n from '../i18n';

export const getGigStatus = (gig) => {
    const duration = timespanStringToMomentDuration(gig.duration);
    const start = moment.utc(gig.start);
    const finish = start.clone().add(duration);
    const current = moment();


    if(current.isAfter(finish)) {
        return {
            background: '#20E5DB',
            status: i18n.t('finished'),
            icon: Images.FinishedIcon,
            code: FINISHED,
        };
    }

    if(current.isAfter(start) && current.isBefore(finish)) {
        const progress = Math.round(moment.duration(current.diff(start)).asSeconds() / duration.asSeconds() * 100);
        const background = `linear-gradient(90deg, #20E5DB ${progress}%, #DFE4ED ${progress}%)`;
        return {
            background,
            status: i18n.t('in_progress'),
            icon: Images.InProgressIcon,
            code: IN_PROGRESS,
        };
    }

    return {
        background: '#FFE476',
        status: i18n.t('hiring'),
        icon: Images.HiringIcon,
        code: NOT_YET_STARTED,
    };
};
