import React, {Fragment, useContext} from 'react';
import ReactDOM from 'react-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { KeyboardTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';

import {EmployerContext} from '../../Contexts/EmployerContext';
import draftTemplate from '../../Misc/draft';

import './index.scss';
import {AppContext} from '../../Contexts/AppContext';
import {Send} from '../../Misc/backend';

const validationSchema = yup.object({
    restaurantId: yup
        .string()
        .required('Restaurant is required'),
    start: yup
        .date()
        .required('Start time is required'),
    duration: yup
        .string()
        .required('Duration is required'),
    price: yup
        .number()
        .required('Price is required'),
    description: yup
        .string()
        .required('Description is required'),
    skillTagId: yup
        .string()
        .required('Professional type is required'),
});


const DraftForm = ({onClose, entity = draftTemplate}) => {
    const {employerState, employerDispatch} = useContext(EmployerContext);
    const {appState} = useContext(AppContext);

    const handleDraftSave = (draft, replace = false) => {
        if (replace) {
            employerDispatch({
                type: 'setDrafts',
                payload: employerState.drafts.map(item => {
                    return item.id === draft.id ? draft : item;
                }),
            });
        } else {
            employerDispatch({
                type: 'setDrafts',
                payload: [...employerState.drafts, draft],
            });
        }
    };

    const formik = useFormik({
        initialValues: entity,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try{
                const duration = moment(values.duration).utc(true).format('0.HH:mm:00');
                handleDraftSave(await saveDraft({...entity, ...values, duration}), !!entity.id);
                onClose();
            } catch (e) {
                console.log(e);
            }
        },
    });

    return(
        <OverlayLoader loading={formik.isSubmitting}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle id="form-dialog-title">{entity.id ? 'Edit Draft' : 'Add Draft'}</DialogTitle>
                <DialogContent>
                    <Box my={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="restaurantId-label">Choose Restaurant</InputLabel>
                            <Select
                                fullWidth
                                labelId="restaurantId-label"
                                id="restaurantId"
                                name="restaurantId"
                                color="secondary"
                                value={formik.values.restaurantId}
                                onChange={formik.handleChange}
                                error={formik.touched.restaurantId && Boolean(formik.errors.restaurantId)}
                                helperText={formik.touched.restaurantId && formik.errors.restaurantId}
                                label="Choose Restaurant"
                            >
                                <MenuItem value="">
                                    <em className="additional_grey_2">Choose Restaurant</em>
                                </MenuItem>
                                {employerState?.restaurants?.map(item => {
                                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                })}
                            </Select>
                            <FormHelperText id="restaurantId-helper-text">This field will be used as Gig’s name for Employee</FormHelperText>
                        </FormControl>
                    </Box>
                    <Box my={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="skillTagId-label">Type of professional</InputLabel>
                            <Select
                                fullWidth
                                labelId="skillTagId-label"
                                id="skillTagId"
                                name="skillTagId"
                                color="secondary"
                                value={formik.values.skillTagId}
                                onChange={formik.handleChange}
                                error={formik.touched.skillTagId && Boolean(formik.errors.skillTagId)}
                                helperText={formik.touched.skillTagId && formik.errors.skillTagId}
                                label="Type of professional"
                            >
                                <MenuItem value="">
                                    <em className="additional_grey_2">Type of professional</em>
                                </MenuItem>
                                {appState?.skills?.map(item => {
                                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box my={2}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                multiline
                                rows={4}
                                rowsMax={4}
                                fullWidth
                                id="description"
                                name="description"
                                label="Description"
                                variant="outlined"
                                color="secondary"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                            <FormHelperText id="description-helper-text">Add information about work</FormHelperText>
                        </FormControl>
                    </Box>
                    <Box my={2} className="datetime-row">
                        <KeyboardDateTimePicker
                            ampm={false}
                            disablePast
                            autoOk
                            id="start"
                            format="yyyy-DD-MM HH:mm"
                            inputVariant="outlined"
                            label="Start Date"
                            color="secondary"
                            minutesStep={30}
                            placeholder={moment().format('yyyy-DD-MM')}
                            value={formik.values.start}
                            onChange={(value) => formik.setFieldValue('start', value)}
                        />
                        <KeyboardTimePicker
                            autoOk
                            ampm={false}
                            label="Duration"
                            inputVariant="outlined"
                            minutesStep={30}
                            color="secondary"
                            value={formik.values.duration}
                            onChange={(value) => formik.setFieldValue('duration', value)}
                        />
                    </Box>
                    <Box my={2}>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="price">Price per hour</InputLabel>
                            <OutlinedInput
                                id="price"
                                name="price"
                                label="Price per hour"
                                type="number"
                                variant="outlined"
                                labelId="price-label"
                                color="secondary"
                                endAdornment={<InputAdornment position="end">SEK</InputAdornment>}
                                value={formik.values.price}
                                onChange={formik.handleChange}
                                error={formik.touched.price && Boolean(formik.errors.price)}
                                helperText={formik.touched.price && formik.errors.price}
                            />
                        </FormControl>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary">
                            Cancel
                    </Button>
                    <Button color="primary" variant="contained" fullWidth type="submit">
                        {entity.id ? 'Save' : 'Add Draft'}
                    </Button>

                </DialogActions>
            </form>
        </OverlayLoader>
    );
};

const saveDraft = async (data) => {
    let method = 'post';
    let url = '/employers/me/drafts';
    if(data.id) {
        method = 'put';
        url += '/'+ data.id;
    }
    return await Send({
        method,
        url,
        data,
    });
};

export default DraftForm;
