import React, { Fragment,useState } from 'react';
import { Helmet } from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import queryString from 'query-string';
import * as yup from 'yup';

import { Send } from '../../Misc/backend';
import { Images } from '../../static/images';
import { ReactComponent as FacebookLogo } from '../../static/images/facebook.svg';
import { ReactComponent as GoogleLogo } from '../../static/images/google.svg';
import OverlayLoader from '../OverlayLoader/OverlayLoader';

import './index.scss';

const validationSchema = yup.object({
    email: yup.string().email().required('Email is required'),
});

const sendMagicLink = async (email, original_url) => {
    return await Send({
        method: 'get',
        url: `/auth/employer/email-magic-link/challenge?email=${email}${
            original_url ? '&original_url=' + original_url : ''
        }`,
    });
};

const handleGoogleAuth = async (original_url) => {
    window.location =
        process.env.REACT_APP_PUBLIC_API_DOMAIN +
        `/auth/employer/google${
            original_url ? '?original_url=' + original_url : ''
        }`;
};

const handleFacebookAuth = async (original_url) => {
    window.location =
        process.env.REACT_APP_PUBLIC_API_DOMAIN +
        `/auth/employer/facebook${
            original_url ? '?original_url=' + original_url : ''
        }`;
};

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const params = queryString.parse(window.location.search);
    const [t] = useTranslation();

    const formik = useFormik({
        initialValues: { email: '' },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                await sendMagicLink(values.email, params.original_url);
                setLoading(false);
                setSuccess(true);
            } catch (e) {
                setLoading(false);
            }
        },
    });

    return (
        <OverlayLoader loading={loading}>
            <Helmet>
                <title>GPG App - Login</title>
            </Helmet>
            <div className="component login">
                {!success && (
                    <Fragment>
                        {/*<div>
                            <img alt="Gigsly Logo" src={Images.LogoLarge} />
                        </div>*/}
                        <Box>
                            <h1>Get started with GPG App.</h1>
                        </Box>
                        <Box mb={4} className="description">
                            Staffing made easy.
                        </Box>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="full-width">
                                <Box mb={1}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        value={formik.values?.email}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched?.email &&
                                            Boolean(formik.errors?.email)
                                        }
                                        helperText={
                                            formik.touched?.email &&
                                            formik.errors?.email
                                        }
                                    />
                                </Box>
                                <Button
                                    fullWidth
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Continue
                                </Button>
                            </div>
                        </form>
                    </Fragment>
                )}
                {success && (
                    <Fragment>
                        {/*<div>
                            <img alt="Gigsly Logo" src={Images.LogoLarge} />
                        </div>*/}
                        <Box>
                            <h1>Email has been sent.</h1>
                        </Box>
                        <Box mb={4} className="description">
                            Please check your inbox and follow the instructions.
                        </Box>
                        <Box mb={4} className="description">
                            <Link
                                color="primary"
                                onClick={() => setSuccess(false)}
                            >
                                Resend
                            </Link>
                        </Box>
                    </Fragment>
                )}


            </div>
            <div className="login-footer">
                <NavLink to="/privacy" exact className="additional_grey_2 fs-14">
                    {t('privacy')}
                </NavLink>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/terms" exact className="additional_grey_2 fs-14">
                    {t('terms')}
                </NavLink>
            </div>
        </OverlayLoader>
    );
};

export default Login;
