import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import './index.scss';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {v4} from 'uuid';

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: '', error: null};
    }

    componentDidMount(){
        if(typeof this.props.value !== 'undefined'){
            this.setState({address: this.props.value});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.value !== this.props.value) this.setState({address: this.props.value});
    }

    handleChange = address => {
        this.setState({ address });
        if(this.state.error === true) {
            this.setState({ error: null });
        }
    };

    handleSelect = address => {
        this.props?.onChange(address);
        this.setState({ address });
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.props?.onSelect({address, ...latLng});
            })
            .catch(error => console.error('Error', error));
    };

    handleError = (status, clearSuggestions) => {
        if (status === 'ZERO_RESULTS') {
            this.setState({ error: true });
        }
        clearSuggestions();
        console.log(status);
    }

    render() {
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                shouldFetchSuggestions={this.state.address.length >= 3}
                debounce={500}
                onError={this.handleError}
                searchOptions={{
                    types: ['address'],
                }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className="relative">
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="address" color={this.props.color}>Address</InputLabel>
                            <OutlinedInput
                                fullWidth={this.props.fullWidth}
                                variant={this.props.variant}
                                helperText={this.props.helperText}
                                label={this.props.label}
                                color={this.props.color}
                                autoComplete="off"
                                error={this.props.error}
                                endAdornment={<InputAdornment position="end"><LocationOnOutlinedIcon style={{color: '#6b7583', fontSize: 28}}/></InputAdornment>}
                                {...getInputProps({
                                    placeholder: 'Address',
                                    className: 'location-search-input',
                                })}
                            />
                        </FormControl>
                        {suggestions?.length > 0 ? <div className="autocomplete-dropdown-container card">
                            {loading && <div>Loading...</div>}
                            {suggestions?.map(suggestion => {
                                const className = suggestion.active
                                    ? 'pointer suggestion-item fs-16 additional_grey_2 active'
                                    : 'pointer suggestion-item fs-16 additional_grey_2';
                                return suggestion.types.includes('street_address') || suggestion.types.includes('premise') || suggestion.types.includes('route') ? (
                                    <div key={v4()}
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                )
                                    : <div>No Results</div>;
                            })}
                        </div> : this.state.error !== null ? <div className="autocomplete-dropdown-container card">No Results</div> : null}
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default LocationSearchInput;
