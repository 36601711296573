import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/core/styles';
import {CompareArrowsOutlined, CompareArrowsTwoTone} from '@material-ui/icons';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

import '../Integration.scss';

const IntegrationsTable = ({staff, moonlighters, setNewBindings, newBindings}) => {
    const newBindingsInternalIds = Object.values(newBindings);

    const handleChangeSelect = (externalId, internalId) => {
        setNewBindings((prevState)=>{
            prevState[externalId] = internalId;
            return {...prevState};
        });
    };

    return (<div>
        {staff?.map(({firstName, lastName, email, userPicUrl, externalId, personalNumber}) => {
            return <div key={externalId}>
                <div className="space-between">
                    <div className="left-side-staff">
                        <div className="avatar">
                            {userPicUrl
                                ? <img src={userPicUrl + '.jpg'}/>
                                : <AccountCircleOutlinedIcon/>
                            }
                        </div>
                        <div className="integration-staff">
                            <div className="primary_black fs-16">
                                {firstName}&nbsp;{lastName}
                            </div>
                            <div className="additional_grey_2 fs-12">
                                {email}
                            </div>
                            <div className="additional_grey_2 fs-12">
                                id:&nbsp;{personalNumber}
                            </div>

                        </div>
                    </div>
                    <div className="right-side-gigsler">
                        <FormControl fullWidth variant="outlined">
                            <InputLabel
                                id="gigslerId-label"
                                color="secondary"
                            >
                                Gigsler
                            </InputLabel>
                            <Select
                                fullWidth
                                labelId="gigslerId-label"
                                id="gigslerId"
                                name="gigslerId"
                                color="secondary"
                                //value={value}
                                onChange={(e) => handleChangeSelect(externalId, e.target.value)}
                                label='Gigsler'
                            >
                                <MenuItem value="">
                                    <em className="additional_grey_2">
                                        Gigsler
                                    </em>
                                </MenuItem>
                                {moonlighters?.map(({fullName, id, email}) => {
                                    return <MenuItem disabled={newBindingsInternalIds.includes(id)} value={id}>
                                        <div className="integration-select">
                                            <div className="primary_black fs-16">
                                                {fullName}
                                            </div>
                                            <div className="additional_grey_2 fs-12">
                                                {email}
                                            </div>
                                            <div className="additional_grey_2 fs-12">
                                                id:&nbsp;{id}
                                            </div>
                                        </div>
                                    </MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <Divider/>
            </div>;
        })}

    </div>
    );
};

export default IntegrationsTable;
