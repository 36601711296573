import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Box} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import moment from 'moment';

import {AppContext} from '../../../Contexts/AppContext';

import './UserCards.scss';

const UsersTable = ({users, remove}) => {
    const [t] = useTranslation();
    const {appState} = useContext(AppContext);
    const isAdmin = appState?.user.roles.indexOf('Admin') !== -1;


    return (
        <Box>
            {users?.map((item) => (
                <div
                    key={item.name}
                    className="invite-card"
                >
                    <div className="avatar">
                        {item.manager?.userPicHash
                            ? <img src={appState.staticHost + '/' + item.manager?.userPicHash + '.jpg'}/>
                            : <AccountCircleOutlinedIcon/>
                        }
                    </div>
                    <div className="invite-card-info">
                        <div className="poppins additional_grey_1 fs-16" style={{marginBottom: '3px'}}>
                            {item.fullName}
                        </div>
                        <div className="fs-16">
                            {item.email}
                        </div>
                        <div>
                            {item.invitedAt && moment(item.invitedAt).format('YYYY-MM-DD')}
                        </div>
                        <div>
                            {!item.invitedById && <span className="badge default">{t('owner')}</span> }
                        </div>
                    </div>
                    <div style={{marginLeft: 'auto'}}>
                        {item.invitedById && isAdmin &&
                            <Button
                                onClick={() => remove(item.id)}
                                color="secondary"
                                title={t('remove_manager')}
                                className="invite-card--delete"
                                style={{margin: 0, padding: 0}}
                            >
                                <DeleteOutlineOutlinedIcon/>
                            </Button>
                        }
                    </div>
                </div>
            ))}
        </Box>
    );
};

export default UsersTable;
