import React from 'react';
import {Box} from '@material-ui/core';

import SnackbarQueue from '../../Components/SnackbarQueue/SnackbarQueue';
import {Images} from '../../static/images';

import './index.scss';


const UnauthorizedLayout = ({children}) => {
    return <div className="layout unauthorized relative">
        <SnackbarQueue/>
        <div className="left-banner">
            <div className="overlay z-0"/>
            <div className="z-1" style={{maxWidth: 310}}>
                <Box className="primary_white" mb={3}>
                    <div className="primary_white fs-24"><span className="bold">GPG App</span> offers restaurants, arenas and venues a free and simple service for staffing</div>
                </Box>
                <div className="additional_grey_3 fs-16">No more worries when you are short on staff, GPG App got your back</div>
            </div>
        </div>
        <div className="overflow-scroll">
            {children}
        </div>

    </div>;
};

export default UnauthorizedLayout;
