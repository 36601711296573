import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { KeyboardDatePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import { useFormik } from 'formik';
import moment from 'moment';
import queryString from 'query-string';
import * as yup from 'yup';

import { AppContext } from '../../Contexts/AppContext';
import { EmployerContext } from '../../Contexts/EmployerContext';
import { getGig, getPaymentMethods, Send } from '../../Misc/backend';
import {
    getEmployeeTaxes,
    getEmployerTaxes,
    getTotalCost,
    roundAccurately,
} from '../../Misc/calculations';
import { IS_DESKTOP_MEDIA_QUERY } from '../../Misc/constants';
import { availableTime } from '../../Misc/constants';
import draftTemplate from '../../Misc/draft';
import customHistory from '../../Misc/history';
import { normalizeTime } from '../../Misc/time';
import { momentDurationToTimespanString } from '../../Misc/timespan';
import { mapServerValidationToFormik } from '../../Misc/validationsErrors';
import BackButton from '../BackButton/BackButton';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import RestaurantHeader from '../RestaurantHeader/RestaurantHeader';
import GigslyToggle from '../UI-KIT/Toggle';

import './index.scss';

const GigEdit = (props) => {
    const id = useRouteMatch('/gig/edit/:id');
    const [loading, setLoading] = useState(false);
    const [fixedPrice, setFixedPrice] = useState(null);
    const [endTimeDate, setEndTimeDate] = useState(null);
    const [duration, setDuration] = useState(null);
    const [taxes, setTaxes] = useState(null);
    const { employerState, employerDispatch } = useContext(EmployerContext);
    const { appState, appDispatch } = useContext(AppContext);
    const [gig, setGig] = useState(draftTemplate);
    const params = queryString.parse(props.location.search);
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const [showNoPaymentMethodWarning, setShowNoPaymentMethodWarning] =
        useState(false);
    const [t] = useTranslation();
    const [checkboxState, setCheckboxState] = useState(false);
    const [factor, setFactor] = useState(1);
    const [gigNumber, setGigNumber] = useState(null);

    const validationSchema = yup.object({
        restaurantId: yup.string().required(t('restaurant_is_required')),
        startDate: yup
            .date()
            .typeError('Must be a valid date')
            .required(t('start_time_is_required')),
        price: yup
            .number()
            .typeError('Must be a valid number')
            .min(152.5, 'Price value must be greater or equal 152.5 per hour')
            .required(t('price_is_required'))
            //TODO: fix this shit
            .max(3000, 'Price can\'t be more than 3000 SEK'),
        description: yup
            .string()
            .max(2000, 'Should have less than 2000 characters')
            .required(t('description_is_required')),
        skillTagId: yup.string().required(t('professional_type_is_required')),
    });

    const formik = useFormik({
        initialValues: gig,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                await handleGigSave(values);
                customHistory.back();
            } catch (e) {
                console.log(e);
                if (e.errors) {
                    formik.setErrors(mapServerValidationToFormik(e.errors));
                }
            }
        },
    });

    useEffect(() => {
        async function loadGig(gigId) {
            setLoading(true);
            try {
                setGig(await getGig(gigId));
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }

        if (id?.params?.id && id.params.id !== 'new') {
            loadGig(id?.params?.id);
        } else {
            formik.setFieldValue('restaurantId', params.restaurantId);
        }
    }, [id?.params?.id]);

    useEffect(() => {
        async function loadRestaurantSettings(restaurantId) {
            setLoading(true);
            try {
                setTaxes(await getTaxes(restaurantId));
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }

        if (formik.values.restaurantId) {
            loadRestaurantSettings(formik.values.restaurantId);
        }
    }, [formik.values.restaurantId]);

    useEffect(() => {
        if (formik.values.startDate) {
            let endDate = moment(formik.values.startDate).startOf('date');
            if (formik.values.endTime <= formik.values.startTime) {
                endDate = endDate.add(1, 'day');
            }

            const endDateTime = moment(endDate).add(
                moment.duration(formik.values.endTime),
            );
            const startDateTime = moment(formik.values.startDate)
                .startOf('date')
                .add(moment.duration(formik.values.startTime));
            setDuration(moment.duration(endDateTime.diff(startDateTime)));
            setEndTimeDate(endDateTime);
        }
    }, [
        formik.values.startDate,
        formik.values.startTime,
        formik.values.endTime,
    ]);

    useEffect(() => {
        if (duration && !checkboxState) setFactor(duration.asHours());
    }, [duration]);

    const handleGigSave = async (
        values,
        replace = false,
        saveAsDraft = false,
    ) => {
        //prepare values
        let _values = { ...values };
        const start = moment(
            _values.startDate.format('YYYY-MM-DD') + ' ' + _values.startTime,
        )
            .local()
            .utc()
            .toISOString();
        delete _values.startDate;
        delete _values.startTime;
        const price = roundAccurately(_values.price * factor, 2);
        const data = {
            ...gig,
            ..._values,
            start,
            price,
            duration: momentDurationToTimespanString(duration),
        };

        //save gig
        //const savedGig = await saveGig(data);
        const failSafeGigNumber =
            gigNumber === '' ||
            gigNumber === null ||
            typeof gigNumber === 'undefined'
                ? 1
                : gigNumber;
        const gigs = [];
        for (let i = 0; i < failSafeGigNumber; i++) {
            gigs.push(saveGig(data));
        }
        try {
            const savedGigs = await Promise.all(gigs);
            const publishedGigsPromises = [];
            savedGigs.forEach((savedGig) => {
                //this logic handles edit/save new entity
                if (replace) {
                    employerDispatch({
                        type: 'setDrafts',
                        payload: employerState.drafts.map((item) => {
                            return item.id === savedGig.id ? savedGig : item;
                        }),
                    });
                } else {
                    employerDispatch({
                        type: 'setDrafts',
                        payload: [...employerState.drafts, savedGig],
                    });
                }

                //if it's not draft -> call publish endpoint
                if (!saveAsDraft) {
                    //TODO: it is workaround, should be replaced with straight gig post
                    publishedGigsPromises.push(handlePublish(savedGig));
                } else {
                    appDispatch({
                        type: 'addSnack',
                        payload: {
                            variant: 'success',
                            message: 'The draft have been saved.',
                        },
                    });
                }
            });
            await Promise.all(publishedGigsPromises);

            appDispatch({
                type: 'addSnack',
                payload: {
                    variant: 'success',
                    message: 'The changes have been saved.',
                },
            });
        } catch (e) {}
    };

    const handlePublish = async (draft) => {
        await publishDraft(draft.id);
        employerDispatch({
            type: 'setDrafts',
            payload: employerState.drafts.filter(
                (item) => item.id !== draft.id,
            ),
        });
        employerDispatch({
            type: 'setGigs',
            payload: [...employerState.gigs, draft],
        });
    };

    const handleFixedPriceChange = (event) => {
        formik.setFieldTouched('price', true, true);
        if (event.target.value === '') {
            setFixedPrice(null);
            formik.setFieldValue('price', null);
        } else if (
            /^(\d)+[,\.]?((\d){1,2})?$/.test(event.target.value) &&
            parseFloat(event.target.value) < 9999999999999
        ) {
            const value = roundAccurately(parseFloat(event.target.value), 2);
            setFixedPrice(parseFloat(event.target.value));
            formik.setFieldValue('price', value);
        }
    };

    const handleTimeChange = (e, fieldName) => {
        const newValue = normalizeTime(
            e.target.value,
            formik.values[fieldName],
        );
        formik.setFieldValue(fieldName, newValue);
    };

    const handleTimeInputBlur = (e, fieldName) => {
        const newValue = normalizeTime(
            e.target.value,
            formik.values[fieldName],
        );
        formik.setFieldValue(fieldName, newValue);
    };

    const handleCheckboxChange = () => {
        if (checkboxState) {
            setFactor(duration.asHours());
        } else {
            setFactor(1);
        }
        setCheckboxState(!checkboxState);
    };

    const changeGigNumberHandler = (e) => {
        if (e.target.value === '') {
            setGigNumber('');
            return;
        }
        let number = Number(e.target.value);
        if (number < 1) {
            setGigNumber(1);
        } else {
            setGigNumber(e.target.value);
        }
    };

    return (
        <OverlayLoader loading={formik.isSubmitting || loading}>
            <Helmet>
                <title>GPG App - Gig edit</title>
            </Helmet>
            <div className="page gig-edit">
                <BackButton />
                <div className="page-header">
                    <RestaurantHeader restaurantId={params?.restaurantId} />
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="card main-info">
                        <div className="poppins fs-16 bold">
                            {t('main_information')}
                        </div>
                        <Box my={2}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel
                                    id="skillTagId-label"
                                    color="secondary"
                                >
                                    {t('type_of_professional')}
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="skillTagId-label"
                                    id="skillTagId"
                                    name="skillTagId"
                                    color="secondary"
                                    value={formik.values.skillTagId}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.skillTagId &&
                                        Boolean(formik.errors.skillTagId)
                                    }
                                    helperText={
                                        formik.touched.skillTagId &&
                                        formik.errors.skillTagId
                                    }
                                    label={t('type_of_professional')}
                                >
                                    <MenuItem value="">
                                        <em className="additional_grey_2">
                                            {t('type_of_professional')}
                                        </em>
                                    </MenuItem>
                                    {appState?.skills?.map((item) => {
                                        return (
                                            <MenuItem value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {formik.errors.skillTagId &&
                                    formik.touched.skillTagId && (
                                    <FormHelperText error>
                                        {formik.errors.skillTagId}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        <Box my={2}>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    multiline
                                    rows={4}
                                    rowsMax={4}
                                    fullWidth
                                    id="description"
                                    name="description"
                                    label={t('description')}
                                    variant="outlined"
                                    color="secondary"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.description &&
                                        Boolean(formik.errors.description)
                                    }
                                    helperText={
                                        formik.touched.description &&
                                        formik.errors.description
                                    }
                                />
                                <FormHelperText id="description-helper-text">
                                    {t('add_information_about_work')}
                                </FormHelperText>
                            </FormControl>
                        </Box>
                        <div className="poppins fs-16 bold">{t('time')}</div>
                        <Box
                            my={2}
                            className="datetime-row align-middle fs-32 additional_grey_3 date"
                        >
                            <KeyboardDatePicker
                                disablePast
                                autoOk
                                id="startDate"
                                format="YYYY-MM-DD"
                                inputVariant="outlined"
                                label={t('start_date')}
                                color="secondary"
                                minutesStep={30}
                                placeholder={moment().format('YYYY-MM-DD')}
                                value={formik.values.startDate}
                                onChange={(value) =>
                                    formik.setFieldValue('startDate', value)
                                }
                                error={
                                    formik.touched.startDate &&
                                    Boolean(formik.errors.startDate)
                                }
                                helperText={
                                    formik.touched.startDate &&
                                    formik.errors.startDate
                                }
                            />
                            <div className="time-wrapper">
                                <FormControl variant="outlined">
                                    {appState.timeInputFieldType === 0 ? (
                                        <>
                                            <InputLabel
                                                id="start-time-label"
                                                color="secondary"
                                            >
                                                {t('start_time')}
                                            </InputLabel>
                                            <Select
                                                native={!isDesktop}
                                                fullWidth
                                                labelId="start-time-label"
                                                id="startTime"
                                                name="startTime"
                                                color="secondary"
                                                defaultValue="00:00"
                                                value={formik.values.startTime}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.startTime &&
                                                    Boolean(
                                                        formik.errors.startTime,
                                                    )
                                                }
                                                helperText={
                                                    formik.touched.startTime &&
                                                    formik.errors.startTime
                                                }
                                                label={t('start_time')}
                                                className="time-input"
                                            >
                                                {isDesktop &&
                                                    availableTime.map(
                                                        (item) => {
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        item +
                                                                        ':00'
                                                                    }
                                                                >
                                                                    {item}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}

                                                {!isDesktop &&
                                                    availableTime.map(
                                                        (item) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        item +
                                                                        ':00'
                                                                    }
                                                                >
                                                                    {item}
                                                                </option>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                        </>
                                    ) : (
                                        <TextField
                                            id="startTime"
                                            name="startTime"
                                            label={t('start_time')}
                                            variant="outlined"
                                            color="secondary"
                                            value={formik.values.startTime}
                                            onChange={(e) =>
                                                handleTimeChange(e, 'startTime')
                                            }
                                            onBlur={(e) =>
                                                handleTimeInputBlur(
                                                    e,
                                                    'startTime',
                                                )
                                            }
                                            error={
                                                formik.touched.startTime &&
                                                Boolean(formik.errors.startTime)
                                            }
                                            helperText={
                                                formik.touched.startTime &&
                                                formik.errors.startTime
                                            }
                                        />
                                    )}
                                    <FormHelperText id="description-helper-text">
                                        {formik?.values?.startDate &&
                                            moment(
                                                formik.values.startDate,
                                            ).format('YYYY-MM-DD')}
                                    </FormHelperText>
                                </FormControl>

                                <div className="text-element">
                                    &nbsp;&mdash;&nbsp;
                                </div>
                                <FormControl variant="outlined">
                                    {appState.timeInputFieldType === 0 ? (
                                        <>
                                            <InputLabel
                                                id="start-time-label"
                                                color="secondary"
                                            >
                                                {t('end_time')}
                                            </InputLabel>
                                            <Select
                                                native={!isDesktop}
                                                fullWidth
                                                labelId="end-time-label"
                                                id="endTime"
                                                name="endTime"
                                                color="secondary"
                                                defaultValue="00:00"
                                                disabled={
                                                    !formik.values.startTime &&
                                                    !formik.values.startDate
                                                }
                                                value={formik.values.endTime}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.endTime &&
                                                    Boolean(
                                                        formik.errors.endTime,
                                                    )
                                                }
                                                helperText={
                                                    formik.touched.endTime &&
                                                    formik.errors.endTime
                                                }
                                                label={t('end_time')}
                                                className="time-input"
                                            >
                                                {isDesktop &&
                                                    availableTime.map(
                                                        (item) => {
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        item +
                                                                        ':00'
                                                                    }
                                                                >
                                                                    {item}
                                                                </MenuItem>
                                                            );
                                                        },
                                                    )}

                                                {!isDesktop &&
                                                    availableTime.map(
                                                        (item) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        item +
                                                                        ':00'
                                                                    }
                                                                >
                                                                    {item}
                                                                </option>
                                                            );
                                                        },
                                                    )}
                                            </Select>
                                        </>
                                    ) : (
                                        <TextField
                                            id="endTime"
                                            name="endTime"
                                            label={t('end_time')}
                                            variant="outlined"
                                            color="secondary"
                                            value={formik.values.endTime}
                                            onChange={(e) =>
                                                handleTimeChange(e, 'endTime')
                                            }
                                            onBlur={(e) =>
                                                handleTimeInputBlur(
                                                    e,
                                                    'endTime',
                                                )
                                            }
                                            error={
                                                formik.touched.endTime &&
                                                Boolean(formik.errors.endTime)
                                            }
                                            helperText={
                                                formik.touched.endTime &&
                                                formik.errors.endTime
                                            }
                                        />
                                    )}
                                    <FormHelperText id="description-helper-text">
                                        {endTimeDate &&
                                            endTimeDate.format('YYYY-MM-DD')}
                                    </FormHelperText>
                                </FormControl>
                            </div>
                            {duration && (
                                <div className="additional_grey_2 fs-16 align-middle text-element">
                                    &nbsp; {t('total')}:{' '}
                                    {roundAccurately(duration.asHours(), 2)}h
                                </div>
                            )}
                        </Box>
                    </div>
                    <div className="card prices">
                        <Box mb={2} mt={0}>
                            <div className="poppins fs-16 bold">
                                {t('price_per_hour')}
                                {/*{!checkboxState
                                    ? t('price_per_hour')
                                    : t('fixed_price')}*/}
                            </div>
                        </Box>
                        <Box mb={2}>
                            <div className="flex-align-horizontal-middle">
                                {/*<GigslyToggle
                                    checked={checkboxState}
                                    setChecked={handleCheckboxChange}
                                    disabled={!duration}
                                />*/}
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    //style={{ marginLeft: '20px' }}
                                >
                                    <InputLabel
                                        htmlFor="price"
                                        color="secondary"
                                    >
                                        Gig price
                                    </InputLabel>
                                    <OutlinedInput
                                        id="price"
                                        name="price"
                                        label="Gig price"
                                        type="number"
                                        variant="outlined"
                                        labelId="price-label"
                                        color="secondary"
                                        disabled={!duration}
                                        defaultValue={152.5}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                SEK
                                            </InputAdornment>
                                        }
                                        value={fixedPrice}
                                        onChange={handleFixedPriceChange}
                                        //onBlur={handleFixedPriceBlur}
                                        error={
                                            formik.touched.price &&
                                            Boolean(formik.errors.price)
                                        }
                                        helperText={
                                            formik.touched.price &&
                                            formik.errors.price
                                        }
                                    />
                                </FormControl>
                            </div>
                            {!duration && (
                                <FormHelperText
                                    style={{ position: 'absolute' }}
                                >
                                    {t('please_select_date_and_time')}
                                </FormHelperText>
                            )}
                            {formik.errors.price && formik.touched.price && (
                                <FormHelperText
                                    style={{ position: 'absolute' }}
                                    error
                                >
                                    {formik.errors.price}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box className="price-row" mt={3} mb={1.5}>
                            <div
                                className={classNames({
                                    'fs-14': true,
                                    additional_grey_2: true,
                                })}
                            >
                                {t('gig_price')}
                            </div>
                            <div>
                                <span className="primary_black">
                                    {!formik.errors.price ? (
                                        <>
                                            <span className="primary_black">
                                                {getTotalCost(
                                                    formik.values.price * factor,
                                                    taxes?.maxEmployerTax,
                                                    taxes?.employeeTax
                                                        ?.localTaxRateTotal,
                                                    appState.config.totalFee,
                                                )}
                                            </span>
                                            &nbsp;
                                            <span className="additional_grey_2">
                                            SEK
                                            </span>
                                        </>
                                    ) : (
                                        <span className={'system_error'}>-</span>
                                    )}
                                </span>
                            </div>
                        </Box>
                        <Box className="price-row" mb={1.5}>
                            <div className="fs-14 additional_grey_2">
                                {t('estimated_employee_tax')} (
                                {taxes?.employeeTax?.localTaxRateTotal}%)
                            </div>
                            <div>
                                {!formik.errors.price ? (
                                    <>
                                        <span className="primary_black">
                                            {getEmployeeTaxes(
                                                formik.values.price * factor,
                                                taxes?.maxEmployerTax,
                                                taxes?.employeeTax
                                                    ?.localTaxRateTotal,
                                            )}
                                        </span>
                                        &nbsp;
                                        <span className="additional_grey_2">
                                            SEK
                                        </span>
                                    </>
                                ) : (
                                    <span className={'system_error'}>-</span>
                                )}
                            </div>
                        </Box>
                        <Box className="price-row" mb={1.5}>
                            <div className="fs-14 primary_black bold">
                                {t('estimated_total_cost')}:
                            </div>
                            <div>
                                {!formik.errors.price ? (
                                    <>
                                        <span className="primary_black bold">
                                            {roundAccurately(
                                                formik.values.price * factor,
                                            )}</span>
                                        &nbsp;
                                        <span className="additional_grey_2 bold">
                                                SEK
                                        </span>
                                    </>
                                ) : (
                                    <span className={'system_error'}>
                                            -
                                    </span>
                                )}

                            </div>
                        </Box>
                        <Box my={2}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="price" color="secondary">
                                    Number of gigs at once
                                </InputLabel>
                                <OutlinedInput
                                    id="gigNumber"
                                    name="gigNumber"
                                    label="Number of gigs at once"
                                    type="number"
                                    defaultValue="1"
                                    variant="outlined"
                                    color="secondary"
                                    value={gigNumber}
                                    onChange={changeGigNumberHandler}
                                />
                            </FormControl>
                        </Box>

                        {isDesktop ? (
                            <div className="actions">
                                <Button
                                    size="large"
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => customHistory.back()}
                                >
                                    {t('cancel')}
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    size="large"
                                    disabled={loading}
                                >
                                    {gig.id ? t('save') : t('create')}
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <Button
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    size="large"
                                    disabled={loading}
                                >
                                    {gig.id ? t('save') : t('create')}
                                </Button>
                                <Box mt={2}>
                                    <Button
                                        fullWidth
                                        size="large"
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() => customHistory.back()}
                                    >
                                        {t('cancel')}
                                    </Button>
                                </Box>
                            </div>
                        )}
                    </div>
                </form>
            </div>
            <Dialog
                open={showNoPaymentMethodWarning}
                onClose={() => customHistory.back()}
                aria-labelledby="form-dialog-title"
                className="confirmation"
                fullScreen={!isDesktop}
            >
                <DialogContent>
                    <OverlayLoader loading={loading}>
                        <Box my={3} className="primary_black fs-24 poppins">
                            {t('no_payment_method_defined')}.
                        </Box>

                        <div className="flex-row warning">
                            <Box mr={2} className="primary_neon">
                                <ErrorOutlineIcon />
                            </Box>
                            <div>
                                {t('please_define_at_least_one_payment_method')}
                                .&nbsp;
                                {t('then_you_will_be_able_to_create_a_gig')}.
                            </div>
                        </div>
                        <Box my={2} className="actions">
                            <Button
                                fullWidth
                                size="large"
                                color="secondary"
                                variant="outlined"
                                type="submit"
                                onClick={() => customHistory.back()}
                                className="w-20"
                            >
                                {t('cancel')}
                            </Button>
                            <Box ml={2}>
                                <NavLink
                                    to={`/restaurant/edit/${formik.values.restaurantId}#1`}
                                >
                                    <Button
                                        fullWidth
                                        size="large"
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                    >
                                        {t('go_to_payment_settings')}
                                    </Button>
                                </NavLink>
                            </Box>
                        </Box>
                    </OverlayLoader>
                </DialogContent>
            </Dialog>
        </OverlayLoader>
    );
};

const saveGig = async (data) => {
    let method = 'post';
    let url = '/employers/me/drafts';
    if (data.id) {
        method = 'put';
        url += '/' + data.id;
    }
    return await Send({
        method,
        url,
        data,
    });
};

const publishDraft = async (id) => {
    let method = 'post';
    let url = '/employers/me/gigs';

    return await Send({
        method,
        url,
        data: `\"${id}\"`,
    });
};

const getTaxes = async (restaurantId) => {
    let method = 'get';
    let url = `/employers/me/drafts/preliminaryTaxes?restaurantId=${restaurantId}`;

    return await Send({
        method,
        url,
    });
};

export default GigEdit;
