import React, {useContext,useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import {useRouteMatch} from 'react-router-dom';
import {Box,Chip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import SmartphoneIcon from '@material-ui/icons/Smartphone';

import {AppContext} from '../../Contexts/AppContext';
import {EmployerContext} from '../../Contexts/EmployerContext';
import {Send} from '../../Misc/backend';
import {roundAccurately} from '../../Misc/calculations';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';
import {Images} from '../../static/images';
import EmployeeInfoBar from '../EmployeeInfoBar/EmployeeInfoBar';
import GigInfoBar from '../GigStatusBar/GigStatusBar';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import RestaurantAccordionItem from '../RestaurantAccordionItem/RestaurantAccordionItem';

import DetailsRating from './components/DetailsRating/DetailsRating';
import DetailsTabs from './components/DetailsTabs/DetailsTabs';

import './HistoryDetails.scss';

const useStyles = makeStyles({
    root: {
        backgroundColor: '#F3F5F9',
        fontWeight: 400,
        fontSize: '12px',
        color: '#353A46',
    },
});

const HistoryDetails = ({
    contract,
    onClose,
}) => {
    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [payment, setPayment] = useState(null);
    const {appState} = useContext(AppContext);
    const {employerState} = useContext(EmployerContext);
    const classes = useStyles();
    const [t] = useTranslation();
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const restaurant = employerState?.restaurants.find(x => x.id === contract?.gigSubject?.restaurantId);





    useEffect(()=>{
        async function loadStatus(contract){
            setLoading(true);
            try{
                setReviews(await getMoonlighterReviews(contract.moonlighter.id));
                const payment = await getPayment(contract.id);
                setPayment(payment);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }

        if(contract.id && typeof contract?.moonlighter?.id !== 'undefined'){
            loadStatus(contract);
        }
    }, [contract]);

    return (
        <OverlayLoader loading={loading}>
            <Box mb={3} className="history-details-contract">
                <Box mb={1} onClick={onClose} className="fs-14 additional_grey_2 pointer">
                    <><ArrowBackIosIcon className="fs-12"/>{t('back')}</>
                </Box>
                <div className="page-header">
                    <span className="poppins">{appState?.skillsMap?.get(contract.gigSubject?.skillTagId)}</span>
                </div>
                <div className="restaurant-details-wrapper">
                    {restaurant && <RestaurantAccordionItem
                        restaurant={restaurant}
                    />}
                </div>
                <div className="history-details-contract-item card">
                    <div className="details-header ">
                        <div className="fs-16 primary_black poppins">
                            <div className="left-column">
                                <Box mr={1.5} className="image-circle small">
                                    {contract?.moonlighter?.userPicHash
                                        ? <img src={appState.staticHost+'/'+contract?.moonlighter?.userPicHash+'.jpg'}/>
                                        : <AccountCircleOutlinedIcon/>}
                                </Box>

                                <div>
                                    <div className="primary_black fs-16 bold poppins truncate status">
                                        {contract.moonlighter?.fullName}
                                        &nbsp;
                                        {(contract.cancelledAt) && <span className="badge error">
                                    cancelled
                                        </span>}
                                        {(!contract?.moonlighterFeedback && !contract.cancelledAt) && <span className="badge default">
                                        Waiting for review
                                        </span>}
                                    </div>
                                    <DetailsRating rating={contract.moonlighter?.rating} reviewsCount={reviews.length}/>
                                </div>

                            </div>

                        </div>
                        <div className="flex-align-horizontal-middle">
                            {contract.finishedAt && (
                                <GigInfoBar
                                    gig={contract.gigSubject}
                                    contract={contract}
                                />
                            )}
                        </div>

                    </div>
                    <DetailsTabs contract={contract} reviews={reviews} payment={payment}/>
                </div>




            </Box>
        </OverlayLoader>
    );
};


const getMoonlighterReviews = async (id) => {
    let method = 'get';
    let url = `/moonlighters/${id}/feedbacks?page=0&size=100000`;

    return await Send({
        method,
        url,
    });
};


const getPayment = async (contractId) => {
    let method = 'get';
    let url = `/employers/me/contracts/${contractId}/payment-details`;

    return await Send({
        method,
        url,
    });
};

export default HistoryDetails;
