import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {BlockOutlined, SearchOutlined} from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment/moment';

import OverlayLoader from '../../../Components/OverlayLoader/OverlayLoader';
import { AppContext } from '../../../Contexts/AppContext';
import { EmployerContext } from '../../../Contexts/EmployerContext';
import { getEmployeeBindings, getMoonlighters, Send } from '../../../Misc/backend';

import './MoonlightersPage.scss';

const MoonlightersPage = () => {
    const [loading, setLoading] = useState(false);
    const [moonlightersList, setMoonlightersList] = useState([]);
    const [t] = useTranslation();
    const { appState } = useContext(AppContext);
    const isAdmin = appState?.user.roles.indexOf('Admin') !== -1;
    const { employerState } = useContext(EmployerContext);
    const [internalIdRestaurantNamesMap, setInternalIdRestaurantNamesMap] = useState({});
    const [searchItem, setSearchItem] = useState('');
    const [filteredMoonlightersList, setFilteredMoonlightersList] = useState([]);

    const restIds = useMemo(() => employerState.restaurants.map((item) => item.id), [employerState.restaurants]);
    const restNames = useMemo(
        () =>
            employerState.restaurants.reduce((accumulator, currentValue) => {
                accumulator[currentValue.id] = currentValue.name;
                return accumulator;
            }, {}),
        [employerState.restaurants],
    );

    useEffect(() => {
        async function loadMoonlighters() {
            setLoading(true);
            try {
                const moonlighters = await getMoonlighters();
                setMoonlightersList(moonlighters);
                setFilteredMoonlightersList(moonlighters);
                const restaurantsBindings = {};
                for await (const restId of restIds) {
                    restaurantsBindings[restId] = (await getEmployeeBindings(restId)).map((item) => item.internalId);
                }

                const map = {};

                moonlighters.forEach((moonlighter) => {
                    const restaurantsOfCurrentMoonlighter = [];
                    for (let restId in restaurantsBindings) {
                        if (restaurantsBindings[restId].includes(moonlighter.id)) {
                            restaurantsOfCurrentMoonlighter.push(restId);
                        }
                    }

                    map[moonlighter.id] = restaurantsOfCurrentMoonlighter.map((item) => restNames[item]);
                });

                setInternalIdRestaurantNamesMap(map);
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }

        loadMoonlighters();
    }, []);

    const handleBlockMoonlighter = async (id) => {
        if (confirm('Do you really want to block this Moonlighter?') == true) {
            try {
                await blockMoonlighter(id);
            } catch (e) {
                console.log(e);
            }
        }
    };
    const handleInputChange = (e) => {
        const searchTerm = e.target.value;
        setSearchItem(searchTerm);

        const filteredItems = moonlightersList.filter((item) =>
            item.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.email.toLowerCase().includes(searchTerm.toLowerCase()),
        );

        setFilteredMoonlightersList(filteredItems);
    };

    return (
        <OverlayLoader loading={loading}>
            <Helmet>
                <title>GPG App - Moonlighters list</title>
            </Helmet>
            <div className="page">
                <div className="search-input" >

                    <TextField
                        id="moonlighters-search-field"
                        name="moonlighters-search-field"
                        variant="outlined"
                        color="secondary"
                        placeholder='Search gigslers...'
                        value={searchItem}
                        onChange={handleInputChange}
                        fullWidth
                    />
                </div>
                {filteredMoonlightersList.length === 0
                    ? <div className="additional_grey_2 lh-46 fs-16">{t('no_gigslers_found')}</div>
                    : <> {filteredMoonlightersList?.map(({ fullName, email, lastSignedIn, rating, id, isActive, userPicHash }) => {
                        return (
                            <div key={id} className="moonlighter-accordion-item">
                                <div className="space-between">
                                    <div className="moonlighter-info-wrapper center">
                                        <div className="moonlighter-info-column">
                                            <div className="flex-row space-between">
                                                <div className="additional_grey_1 bold fs-16">{t('name')}</div>
                                            </div>
                                            <div className="additional_grey_2 lh-46 fs-16">{fullName}</div>
                                        </div>
                                        <div className="moonlighter-info-column">
                                            <div className="flex-row space-between">
                                                <div className="additional_grey_1 bold fs-16">{t('email')}</div>
                                            </div>
                                            <div className="additional_grey_2 lh-46 fs-16">{email}</div>
                                        </div>
                                        <div className="moonlighter-info-column rating">
                                            <div className="flex-row">
                                                <div className="additional_grey_1 bold fs-16">{t('rating')}&nbsp;&nbsp;</div>
                                                <span className="additional_grey_2 fs-12 counter">{rating}&nbsp;/&nbsp;5</span>
                                            </div>
                                            <div className="rating additional_grey_2 lh-46 fs-16">
                                                <Rating value={rating} readOnly></Rating>
                                            </div>
                                        </div>
                                        <div className="moonlighter-info-column">
                                            <div className="flex-row space-between">
                                                <div className="additional_grey_1 bold fs-16">{t('last_signed_in')}</div>
                                            </div>
                                            <div className="additional_grey_2 lh-46 fs-16">
                                                {moment(lastSignedIn).format('YYYY-MM-DD, h:mm')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="space-between center">
                                        {!isActive && <span className="badge error">{t('blocked')}</span>}
                                        {isActive && (
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                size="small"
                                                title="Block Moonlighter"
                                                onClick={() => handleBlockMoonlighter(id)}
                                            >
                                                <BlockOutlined color="error" />
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                {internalIdRestaurantNamesMap[id]?.length > 0 && (
                                    <div className="additional_grey_2 fs-16 binded">
                                        {t('binded_with')}:&nbsp; <em className="bold">{internalIdRestaurantNamesMap[id]?.join(', ')}</em>
                                    </div>
                                )}
                            </div>
                        );
                    })}</>}
            </div>
        </OverlayLoader>
    );
};

const blockMoonlighter = async (id) => {
    let method = 'post';
    let url = `/employers/me/moonlighters/${id}/blocks`;

    return await Send({
        method,
        url,
        data: {},
    });
};

const getBlockedMoonlighters = async (id) => {
    let method = 'get';
    let url = `/employers/me/moonlighter-blocks/${id}`;

    return await Send({
        method,
        url,
    });
};
export default MoonlightersPage;
