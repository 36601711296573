import React from 'react';
import Box from '@material-ui/core/Box';
import {Images} from '../../static/images';
import {withStyles} from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';

import './index.scss';


const StyledRating = withStyles({
    iconFilled: {
        // color: '#3ef3e9',
    },
    iconHover: {
        // color: '#3ef3e9',
    },
})(Rating);

const Review = ({review, staticHost}) => {
    return <div className="review">
        <Box mb={1}>
            <Box mr={2} className="image-circle small">
                <img src={review?.coverPhotoHash ? staticHost+'/'+review.coverPhotoHash+'.jpg?size=80' : Images.RestaurantLogoFish}/>
            </Box>
            <Box mr={2}  className="no-text-wrap">
                <div className="fs-14 bold primary_black">
                    {review?.restaurantName}
                </div>
                <div className="additional_grey_2 fs-12 roboto">
                    <div className="fs-12 additional_grey_2">
                        <StyledRating
                            readOnly
                            name="simple-controlled"
                            value={review?.rating}
                            precision={0.1}
                            className="fs-16 align-top"
                        />
                    </div>
                </div>
            </Box>
        </Box>
        <div className="fs-14 additional_grey_2">
            {review?.comment}
        </div>
    </div>;
};

export default Review;
