export default (state, action) => {
    try {
        return actions[action.type](state, action.payload);
    }
    catch(err) {
        throw err;
    }
};

const actions = {
    setRestaurants(state, data) {
        return {...state, restaurants: data};
    },
    setStaticHost(state, data) {
        return {...state, staticHost: data};
    },
    setUser(state, data) {
        window.localStorage.setItem('user', JSON.stringify(data));
        return {...state, user: data};
    },
    setPaymentMethods(state, data) {
        return {...state, paymentMethods: data};
    },
    addSnack(state, data) {
        const snacks = [...state.snacks];
        snacks.push({persist: false, ...data});
        return {...state, snacks};
    },
    removeSnack(state, data) {
        const newState = state.snacks.filter(item => item.id !== data.id);
        return {...state, snacks: newState};
    },
    setSkills(state, data) {
        const skillsMap = new Map();
        data.forEach(item => {
            skillsMap.set(item.id, item.name);
        });
        return {...state, skills: data, skillsMap};
    },
    setStripePaymentActionsModalState(state, data) {
        return {...state, stripePaymentActionsModalState: data};
    },
    setConfig(state, data) {
        return {...state, config: {...data, totalFee: data.serviceFeeAmount+data.serviceFeeAmount*data.vatPercentage/100}};
    },
    setTimeInputFieldType (state, data) {
        return {...state, timeInputFieldType: data};
    },
};
