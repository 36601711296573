import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useRouteMatch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { ExpandMore } from '@material-ui/icons';
import classNames from 'classnames';

import SimpleAccordionRowMobile from '../../../Components/AccordionRow/SimpleAccordionRowMobile';
import BackButton from '../../../Components/BackButton/BackButton';
import { EDIT_VIEW_KEY } from '../../../Components/RestaurantEdit/RestaurantEdit';
import { INVITE_VIEW_KEY } from '../../../Components/RestaurantInvite/RestaurantInvite';
import { PAYMENT_OPTIONS_VIEW_KEY } from '../../../Components/RestaurantPaymentOptions/RestaurantPaymentOptions';
import { SharedViewContainer } from '../../../Components/SharedViews/SharedViews';
import { EmployerContext } from '../../../Contexts/EmployerContext';

const RestaurantEditPageMobile = () => {
    const { employerState } = useContext(EmployerContext);
    const [value, setValue] = useState(0);
    const [t] = useTranslation();
    const match = useRouteMatch('/restaurant/edit/:id');
    const { hash } = useLocation();
    const [restaurant, setRestaurant] = useState(null);
    const [subTitle, setSubTitle] = useState('');

    const handleChange = (newValue) => {
        if (newValue === value) {
            setValue(-1);
            window.location.hash = '';
        } else {
            setValue(newValue);
            window.location.hash = newValue;
        }
    };

    useEffect(() => {
        if (hash) {
            setValue(parseInt(hash.slice(1)));
        }
    }, [hash]);

    useEffect(() => {
        setRestaurant(
            employerState.restaurants.find((x) => x.id === match.params.id),
        );
    }, []);

    useEffect(() => {
        function getSubTitleByValue(value) {
            switch (value) {
            case 1:
                return 'Restaurant Payment Options';
            case 2:
                return 'Restaurant Invitations';
            default:
                return 'Restaurant Edit';
            }
        }

        setSubTitle(getSubTitleByValue(value));
    }, [value]);

    return (
        <div className="page restaurant">
            <Helmet>
                <title>GPG App - {subTitle}</title>
            </Helmet>
            <BackButton url="/restaurants" />
            <Box my={2} className="page-header">
                <span className="bold poppins primary_black">
                    {restaurant?.name || t('edit_restaurant')}
                </span>
            </Box>
            <div>
                <SimpleAccordionRowMobile
                    title={t('restaurant_info')}
                    onOpen={() => handleChange(0)}
                    open={value === 0}
                >
                    <SharedViewContainer
                        restaurantId={match.params.id}
                        viewKey={EDIT_VIEW_KEY}
                    />
                </SimpleAccordionRowMobile>
                {/*{match?.params?.id && match.params.id !== 'new' && <SimpleAccordionRowMobile*/}
                {/*    title={t('payment_options')}*/}
                {/*    onOpen={() => handleChange(1)}*/}
                {/*    open={value === 1}*/}
                {/*>*/}
                {/*    <SharedViewContainer*/}
                {/*        restaurantId={match.params.id}*/}
                {/*        successUrl={`/restaurant/edit/${match.params.id}?paymentAddSuccess=true#1`}*/}
                {/*        cancelUrl={`/restaurant/edit/${match.params.id}?paymentAddSuccess=false#1`}*/}
                {/*        viewKey={PAYMENT_OPTIONS_VIEW_KEY}*/}
                {/*    />*/}
                {/*</SimpleAccordionRowMobile>}*/}
                {/*{match?.params?.id && match.params.id !== "new" && (
                    <SimpleAccordionRowMobile
                        title={t("invitations")}
                        onOpen={() => handleChange(2)}
                        open={value === 2}
                    >
                        <SharedViewContainer
                            restaurantId={match.params.id}
                            viewKey={INVITE_VIEW_KEY}
                        />
                    </SimpleAccordionRowMobile>
                )}*/}
            </div>
        </div>
    );
};

export default RestaurantEditPageMobile;
