import React, {useContext} from 'react';
import {Box} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Images} from '../../static/images';
import {EmployerContext} from '../../Contexts/EmployerContext';

import './index.scss';

const RestaurantTile = ({restaurant, staticHost}) => {
    const [t] = useTranslation();

    const {employerState} = useContext(EmployerContext);

    return <NavLink to={`/restaurant/edit/${restaurant.id}`}>
        <Box className="restaurant tile roboto">
            <div className="restaurant-image-wrapper">
                <img src={restaurant?.coverPhotoHash ? staticHost+'/'+restaurant.coverPhotoHash+'.jpg' : Images.RestaurantLogoFish}/>
            </div>
            <div className="info">
                <Box my={2}>
                    <div className="poppins fs-16 bold primary_black">
                        {restaurant?.name}
                    </div>
                    <div className="additional_grey_2 fs-12">
                        {restaurant?.address}
                    </div>
                </Box>
                <Box mb={2} className="fs-14 additional_grey_2 pre-wrap">
                    {restaurant?.description}
                </Box>
                <div className="stats">
                    <div className="stat">
                        <div className="fs-12 additional_grey_2">
                            {t('open')} Gigs
                        </div>
                        <div className="fs-16 primary_black">
                            {employerState.gigs.filter(item => item.restaurantId === restaurant.id).length}
                        </div>
                    </div>
                    {/*<div className="stat">*/}
                    {/*    <div className="fs-12 additional_grey_2">*/}
                    {/*        {t('finished')} Gigs*/}
                    {/*    </div>*/}
                    {/*    <div className="fs-16 primary_black">*/}
                    {/*        {restaurant?.approvedContractsCount || 0}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </Box>
    </NavLink>;
};

export default RestaurantTile;
