import React, {useEffect, useState} from 'react';

import {Send} from '../../Misc/backend';
import OverlayLoader from '../OverlayLoader/OverlayLoader';

const Terms = () => {
    const [loading, setLoading] = useState(false);
    const [terms, setTerms] = useState('');

    useEffect(()=>{
        async function loadTerms(){
            setLoading(true);
            try{
                setTerms(await getTerms());
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }
        loadTerms();
    }, []);

    return(
        <OverlayLoader loading={loading}>
            <div className="page ">
                <div style={{width: 800, maxWidth: '100%'}} dangerouslySetInnerHTML={{__html: terms}} />
            </div>
        </OverlayLoader>
    );
};

const getTerms = async () => {
    let method = 'get';
    let url = '/legal-information/terms-and-conditions';

    return await Send({
        method,
        url,
    });
};

export default Terms;
