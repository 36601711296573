import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useFormik} from 'formik';
import OverlayLoader from '../../Components/OverlayLoader/OverlayLoader';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {Send} from '../../Misc/backend';
import restaurantTemplate, {restaurantPhotosTemplate} from '../../Misc/restaurant';
import Dropzone from 'react-dropzone';
import {usePrevious} from '../../Misc/usePrevious';
import {v4 as uuid} from 'uuid';
import ActionsOverlay from '../RemoveOverlay/ActionsOverlay';
import {availableTime, IS_DESKTOP_MEDIA_QUERY, PHONE_REGEX} from '../../Misc/constants';
import {useMediaQuery} from 'react-responsive';
import 'react-phone-input-2/lib/style.css';
import {useTranslation} from 'react-i18next';
import {ArrowRightIcon} from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import {ArrowLeftIcon} from '@material-ui/pickers/_shared/icons/ArrowLeftIcon';

const RestaurantPhotos = ({onSubmitSuccess, handleBack, ...rest}) => {

    const [photos, setPhotos] = useState([]);
    const previousPhotos = usePrevious(photos);
    const [photosAreBeingUploaded, setPhotosAreBeingUploaded] = useState([]);
    const [uuidHashTable , setUuidHashTable] = useState(new Map());
    const [t] = useTranslation();
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);


    useEffect(()=>{
        formik.setFieldValue('photoHashes', [...uuidHashTable.values()]);
    }, [uuidHashTable]);

    useEffect(()=>{
        async function upload(f){
            try{
                //set loader for this file
                setPhotosAreBeingUploaded((previous) => [...previous, f.uuid]);
                //save file in async
                let result = await savePhoto(f.file);
                //set relation between hash and uuid to handle this file later
                setUuidHashTable((previous) => {
                    const newState = new Map(previous);
                    newState.set(f.uuid, result);
                    return newState;
                });
                //turn off loader for this file
                setPhotosAreBeingUploaded((previous) => {
                    const start = previous.indexOf(f.uuid);
                    previous.splice(start,1);
                    return [...previous];
                });
            } catch (e) {
                setPhotosAreBeingUploaded((previous) => {
                    const start = previous.indexOf(f.uuid);
                    previous.splice(start,1);
                    return [...previous];
                });

                setPhotos([...photos].filter(item=>item.uuid !== f.uuid));
            }
        }

        photos.forEach(photo => {
            const previousState = previousPhotos.find(item => item.uuid === photo.uuid);
            if(!previousState && photo.file.path){
                upload(photo);
            }
        });
    },[photos]);


    const formik = useFormik({
        initialValues: restaurantPhotosTemplate,
        enableReinitialize: true,
        onSubmit: async (values) => {
            //set first picture as cover if cover is not specified
            if(typeof values?.coverPhotoHash === 'undefined'
                    || values.coverPhotoHash === null
                    || values.photoHashes?.indexOf(values.coverPhotoHash) === -1){
                if(values.photoHashes?.length > 0 ){
                    values.coverPhotoHash = values.photoHashes[0];
                }
            }

            onSubmitSuccess(values);
        },
    });


    const handleFiles = acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                // Do whatever you want with the file contents
                setPhotos((prevValue) => [...prevValue, {
                    src: reader.result,
                    uuid: uuid(),
                    file: file,
                }]);
            };
            reader.readAsDataURL(file);
        });
    };

    const setCover = (hash) => {
        formik.setFieldValue('coverPhotoHash', hash);
    };

    useEffect(()=>{
        if(formik.values.coverPhotoHash){
            photos.sort((a, b) => {
                if(uuidHashTable.get(a.uuid) === formik.values.coverPhotoHash) return -1;
                if(uuidHashTable.get(b.uuid) === formik.values.coverPhotoHash) return 1;
                return 0;
            });
        }
    }, [formik.values.coverPhotoHash]);

    const handlePhotoRemove = (uuid) => {
        const newHashTable = new Map(uuidHashTable);
        newHashTable.delete(uuid);
        setUuidHashTable(newHashTable);

        setPhotos([...photos].filter(item=>item.uuid !== uuid));
    };

    return (
        <div className="restaurant" {...rest}>
            <form onSubmit={formik.handleSubmit}>
                <div className="gallery">
                    <Box my={2}>
                        <div className="pointer">
                            <Dropzone onDrop={handleFiles}>
                                {({getRootProps, getInputProps}) => (
                                    <section >
                                        <div className="dropzone" {...getRootProps()}>
                                            <input {...getInputProps()} accept=".jpg,.jpeg,.png,.gif"/>
                                            {photos?.length === 0 && <div className="empty-dropzone-text">
                                                <div className="bold additional_grey_1 fs-16 poppins">{t('drag_n_drop')}</div>
                                            </div>}
                                            {photos?.map(item => <OverlayLoader
                                                loading={photosAreBeingUploaded.indexOf(item.uuid) !== -1}
                                            >
                                                <ActionsOverlay
                                                    removeHandler={() => handlePhotoRemove(item.uuid)}
                                                    setCoverHandler={
                                                        formik.values.coverPhotoHash === uuidHashTable.get(item.uuid)
                                                            ? null
                                                            : () => setCover(uuidHashTable.get(item.uuid))
                                                    }
                                                >
                                                    <img src={item.src}/>
                                                </ActionsOverlay>

                                                {formik.values.coverPhotoHash === uuidHashTable.get(item.uuid) &&
                                                <div className="badge new">
                                                    {t('cover')}
                                                </div>}

                                            </OverlayLoader>,
                                            )}
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                        {photos?.length !== 0 && <Box mt={1} className="bold additional_grey_1 fs-16 poppins align-center">{t('drag_n_drop')}</Box>}
                    </Box>

                    {isDesktop && <div className="space-between">
                        <Button color="secondary" variant="outlined" onClick={handleBack} size="large">
                            <ArrowLeftIcon/> {t('back')}
                        </Button>
                        <Button color="primary" variant="contained" type="submit" size="large">
                            <span>{t('next_step')}</span> <ArrowRightIcon/>
                        </Button>
                    </div>}

                    {!isDesktop && <div className="flex-column">
                        <Button color="secondary" variant="outlined" onClick={handleBack} size="large">
                            <ArrowLeftIcon/> {t('back')}
                        </Button>
                        <Button color="primary" variant="contained" type="submit" size="large">
                            <span>{t('next_step')}</span> <ArrowRightIcon/>
                        </Button>
                    </div>}
                </div>
            </form>
        </div>

    );
};


const savePhoto = async (file) => {
    const formData = new FormData();
    formData.append('uploadedFile', file);
    let method = 'post';
    let url = '/images';
    return await Send({
        method,
        url,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export default RestaurantPhotos;
