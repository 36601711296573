import React, {useContext, useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {Send} from '../../Misc/backend';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';
import {AppContext} from '../../Contexts/AppContext';
import customHistory from '../../Misc/history';
import {useMediaQuery} from 'react-responsive';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';


const RestaurantPaymentButton = ({restaurantId, successUrl, cancelUrl, ...rest})=>{
    const [loading, setLoading] = useState(false);
    const { appDispatch } = useContext(AppContext);
    const [t] = useTranslation();
    const params = queryString.parse(window.location.search);
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);

    useEffect(()=>{
        if(typeof params.paymentAddSuccess !== 'undefined'){
            if(params.paymentAddSuccess === 'true'){
                appDispatch({
                    type: 'addSnack',
                    payload: {
                        variant: 'success',
                        message: 'Payment method was successfully created.',
                    },
                });
                customHistory.replace('/', '');
            } else {
                appDispatch({
                    type: 'addSnack',
                    payload: {
                        variant: 'error',
                        autoHideDuration: 30000,
                        message: 'An error occurred while adding the payment method',
                    },
                });
                customHistory.replace({...customHistory.location, search: '', hash: '3'});
            }
        }
    }, [params.paymentAddSuccess]);

    const handleAdd = async () => {
        try{
            setLoading(true);
            const sessionId = await getSession(restaurantId, successUrl, cancelUrl);
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };

    return (
        <OverlayLoader loading={loading} {...rest}>
            <Box my={2}>
                {isDesktop && <Button
                    color="primary"
                    variant="contained"
                    onClick={handleAdd}
                    size="large"
                    style={{maxWidth: 220, marginBottom: 8}}
                >
                    {t('add_payment_method')}
                </Button>}
            </Box>

            {!isDesktop && <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleAdd}
                size="large"
            >
                {t('add_payment_method')}
            </Button>}
        </OverlayLoader>
    );
};

const getSession = async (restaurantId, successUrl, cancelUrl) => {
    let method = 'post';
    let url = `/employers/me/restaurants/${restaurantId}/card-payment-methods`;

    return await Send({
        method,
        url,
        data: {
            successUrl,
            cancelUrl,
        },
    });
};


export default RestaurantPaymentButton;
