import React, {useContext, useEffect, useState} from 'react';
import { createPortal } from 'react-dom';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {AppContext} from '../../Contexts/AppContext';
import {Send} from '../../Misc/backend';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';
import OverlayLoader from '../OverlayLoader/OverlayLoader';

import UserCards from './UserCards/UserCards';
import UsersTable from './UsersTable/UsersTable';

import './RestaurantInvite.scss';

export const INVITE_VIEW_KEY = 'inviteView';

const RestaurantInvite = () => {
    const [loading, setLoading] = useState(false);
    const [managers, setManagers] = useState([]);
    const [formData, setFormData] = useState({ email: '' });
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const [t] = useTranslation();
    const {appState} = useContext(AppContext);
    const isAdmin = appState?.user.roles.indexOf('Admin') !== -1;

    function updateFormData(fieldName, data) {
        setFormData(previous => {
            var newFormData = { ...previous };
            newFormData[fieldName] = data;

            return newFormData;
        });
    };

    const validationSchema = yup.object({
        email: yup
            .string()
            .email()
            .test(
                'unique',
                t('manager_with_the_same_email_already_exists'),
                value => managers.filter(x => x.email == value).length === 0,
            )
            .required(t('email_is_required')),
    });

    useEffect(()=>{
        async function loadManagers(){
            setLoading(true);
            try{
                setManagers(await getManagers());
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }
        loadManagers();
    }, []);

    const handleSend = async (values) => {
        setLoading(true);
        try {
            await sendInvites({email:values.email});
            updateFormData('email', '');
            setManagers(prev => ([...prev, {
                userPicHash: null,
                fullName: values.email,
                email: values.email,
                isConfirmed: false,
            }]));
        } catch (e) {
            console.log(e);
            if (e.errors) {
                formik.setErrors(mapServerValidationToFormik(e.errors));
            }
        }
        setLoading(false);
    };

    const handleDeleteManager = async (id) => {
        try {
            await deleteManagers(id);
            setManagers(prevValue => prevValue.filter(item => item.id !== id));
        } catch (e) {
            console.log(e);
        }
    };

    const formik = useFormik({
        initialValues: formData,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: handleSend,
    });

    return (
        <OverlayLoader loading={loading}>
            <div className="invites card card-large">
                <Box mb={2} className="invite-form">
                    <Box mb={2} className="fs-16 bold primary_black">{t('invite_by_email')}</Box>
                    <form onSubmit={formik.handleSubmit} >
                        {!isAdmin && <span className="invites-warning fs-16">Invitations are only available to administrators</span>}
                        {isAdmin && <Box mb={2} className="invite-input">
                            <Box>
                                <TextField
                                    id="email"
                                    name="email"
                                    className="invite-input-text"
                                    variant="outlined"
                                    color="secondary"
                                    placeholder="email@example.com"
                                    value={formData.email}
                                    onChange={value => updateFormData('email', value.target.value)}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    disabled={false}
                                />
                            </Box>
                            <Box ml={1} sx={{marginLeft: !isDesktop ? '0px' : '8px', marginTop: !isDesktop ? '10px' : '0px'}}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    className="invite-input-button"
                                >
                                    {t('send_invite')}
                                </Button>
                            </Box>
                        </Box>}
                    </form>
                </Box>
                <Box className="invite-grid">
                    <Box mb={2} className="fs-16 bold primary_black">{t('managers')}</Box>
                    {isDesktop
                        ? <UsersTable users={managers} remove={handleDeleteManager}/>
                        : <UserCards users={managers} remove={handleDeleteManager}/>}
                </Box>
            </div>
        </OverlayLoader>
    );
};

const sendInvites = async (invites) => {
    let method = 'post';
    let url = '/employers/me/managers';

    return await Send({
        method,
        url,
        data: invites,
    });
};

const getManagers = async () => {
    let method = 'get';
    let url = '/employers/me/managers';

    return await Send({
        method,
        url,
    });
};

const deleteManagers = async (id) => {
    let method = 'delete';
    let url = `/employers/me/manager/${id}`;

    return await Send({
        method,
        url,
    });
};

export default RestaurantInvite;
