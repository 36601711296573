import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation,useParams} from 'react-router-dom';
import {Divider} from 'antd';
import moment from 'moment';
import queryString from 'query-string';

import {Send} from '../../../Misc/backend';

import './index.scss';

const ReceiptPage = (props) => {
    const [receipt, setReceipt] = useState(null);
    const [params, setParams] = useState(null);

    const [t] = useTranslation();

    useEffect(()=>{
        async function loadReceipt (contractId){
            try {
                const result = await getReceipt(contractId);
                const url = window.URL.createObjectURL(new Blob([result]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Receipt.pdf');
                document.body.appendChild(link);
                link.click();
                setReceipt(result);
            } catch (e) {
                console.log(e);
            }
        }
        const params = queryString.parse(props.location.search);
        setParams(params);
        if(params?.contractId){
            loadReceipt(params?.contractId);
        }
    }, []);

    return <div className="page receipt">
        <div className="bold fs-22 align-center" style={{marginBottom: 24}}>{t('payment_information_sent_by')} GPG App AB via Swish AB.</div>
        <div className="receipt-row">
            <div className="additional_grey_2">{t('issued_for_payment_recipient')}</div>
            <div className="align-right">
                {params?.employee}&nbsp;
                Tvetgatan 4E
                44233 Kungalv , Sweden
                (16cf17b5f52fbdbc)
            </div>
        </div>
        <div className="receipt-row">
            <div className="additional_grey_2">{t('date_of_transaction')}:</div>
            <div className="align-right">{moment(params?.date || null).format('YYYY-MM-DD HH-mm')}</div>
        </div>

        <div className="receipt-row">
            <div className="additional_grey_2">{t('payment_issued_by')}:</div>
            <div className="align-right"> {params?.employer}(1762f13bdd46133f)</div>
        </div>

        <div className="receipt-row">
            <div className="additional_grey_2">{t('pay_for_help_provided_through_ad')}</div>
            <div className="align-right">
                "Skräp som behöver slängas"
                (176a889ed31e32db)
            </div>
        </div>

        <Divider/>

        <div className="receipt-row">
            <div className="additional_grey_2">{t('paid_with_stripe')} (SEK)</div>
            <div className="align-right">
                {params?.total}
            </div>
        </div>


        <div className="receipt-row">
            <div className="additional_grey_2">{t('employee_tax')} (SEK)</div>
            <div className="align-right">
                600,00
            </div>
        </div>

        <div className="receipt-row">
            <div className="additional_grey_2">Gigsly {t('fee')} {t('total')} (SEK)</div>
            <div className="align-right">
                19,00
            </div>
        </div>

        <div className="receipt-row">
            <div className="additional_grey_2">Gigsly {t('fee')} VAT (SEK)</div>
            <div className="align-right">
                3,80
            </div>
        </div>

        <div className="receipt-row">
            <div className="additional_grey_2 bold fs-18">{t('total')} (SEK)</div>
            <div className="align-right">
                1119
            </div>
        </div>
    </div>;
};


const getReceipt = async (contractId) => {
    let method = 'get';
    let url = `/reports/salary?contractId=${contractId}&isEmployerReport=true&format=pdf`;

    return await Send({
        method,
        url,
        responseType: 'arraybuffer',
    });
};

export default ReceiptPage;
