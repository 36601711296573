import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

import './index.scss';

const OverlayLoader = ({loading, children, className, ...rest}) => (
    <div className={classNames('overlay-loader', className)}  {...rest}>
        {loading && <div className="loader">
            <CircularProgress color="secondary"/>
        </div>}
        {children}
    </div>
);

export default OverlayLoader;
