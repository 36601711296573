import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';

import ContextLoader from '../../../Components/ContextLoader/ContextLoader';
import OverlayLoader from '../../../Components/OverlayLoader/OverlayLoader';
import RestaurantInvite from '../../../Components/RestaurantInvite/RestaurantInvite';
import {getManagers} from '../../../Misc/backend';

import './ManagersPage.scss';

const ManagersPage = () => {

    const [loading, setLoading] = useState(false);


    useEffect(()=>{
        async function loadManagers(){
            setLoading(true);
            try{
                const managers = await getManagers();
                setManagersList (managers);
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }
        loadManagers();
    }, []);

    return <OverlayLoader loading={loading}>
        <Helmet>
            <title>GPG App - Managers list</title>
        </Helmet>
        <div className="page">
            <RestaurantInvite/>
        </div>
    </OverlayLoader>;
};

export default ManagersPage;
