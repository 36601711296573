import React, {useEffect, useState, Fragment} from 'react';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import {NavLink} from 'react-router-dom';

import {timespanStringToMomentDuration} from '../../Misc/timespan';
import {getGigStatus} from '../../Misc/gig';
import {Images} from '../../static/images';
import {useTranslation} from 'react-i18next';


import './index.scss';
import {roundAccurately} from '../../Misc/calculations';

const GigTile = ({
    gig,
    skills,
    hasNewApplicants,
}) => {
    const [status, setStatus] = useState(null);
    const [duration, setDuration] = useState(null);
    const [t] = useTranslation();

    useEffect(()=>{
        if(gig){
            setDuration(timespanStringToMomentDuration(gig.duration));
            setStatus(getGigStatus(gig));
        }
    }, [gig]);

    return <Fragment>
        {gig ? <NavLink to={`/gig/${gig.id}`}>
            <Box className="tile" >
                <div className="tile-content">
                    <div className="tile-content-header">
                        <div className="left-column">
                            <Box mr={1.5}>
                                <img src={status?.icon}/>
                            </Box>
                            <div className="status">
                                <div className="primary_black fs-16 poppins">{skills?.get(gig?.skillTagId)}</div>
                                <div className="fs-12 additional_grey_2">{status?.status}</div>
                            </div>
                        </div>
                        {(gig.start && duration) && <div className="right-column additional_grey_1">
                            <div>{moment.utc(gig.start).local().format('DD MMM YYYY')}</div>
                            <div>
                                {moment.utc(gig.start).local().format('HH:mm')} –
                                {moment.utc(gig.start).local().add(duration?.asSeconds(), 'seconds').format('HH:mm')} /
                                {duration?.humanize()}
                            </div>
                        </div>}
                    </div>
                    <div className="tile-content-body">
                        <Box my={2.5} className="additional_grey_2 pre-wrap">
                            {gig.description}
                        </Box>
                        <div className="stats">
                            <div className="stat">
                                <div className="fs-12 additional_grey_2">
                                    {t('total_price')}
                                </div>
                                <div className="fs-16 primary_black">
                                    {gig.price} SEK
                                </div>
                            </div>
                            <div className="stat">
                                <div className="fs-12 additional_grey_2">
                                    {t('answers')}
                                </div>
                                <div className="fs-16 primary_black">
                                    {gig?.applicationsCount || 0}
                                    {hasNewApplicants && <span className="badge new">New!</span> }
                                </div>
                            </div>
                            <div className="stat">
                                <div className="fs-12 additional_grey_2">
                                    {t('approved')}
                                </div>
                                <div className="fs-16 primary_black">
                                    {gig?.approvedContractsCount || 0}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="progressbar" style={{background: status?.background}}/>
            </Box>
        </NavLink>
            : null}
    </Fragment>;
};

export default GigTile;
