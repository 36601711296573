import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Box from '@material-ui/core/Box';

import ApplicationsBanner from '../../../Components/ApplicationsBanner/ApplicationsBanner';
import EmployerProfile from '../../../Components/EmployerProfile/EmployerProfile';
import { IS_DESKTOP_MEDIA_QUERY } from '../../../Misc/constants';

import './index.scss';

const UserProfilePage = () => {
    const [t] = useTranslation();
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);

    return (
        <>
            <Helmet>
                <title>GPG App - Profile</title>
            </Helmet>
            <div className="page">
                <Box mb={2} className="page-header">
                    {t('profile')}
                </Box>
                <div className="profile">
                    <EmployerProfile />
                    {isDesktop && <ApplicationsBanner />}
                </div>
            </div>
        </>
    );
};

export default UserProfilePage;
