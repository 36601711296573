import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import moment from 'moment';

import {AppContext} from '../../../Contexts/AppContext';

import './UsersTable.scss';

const UsersTable = ({users, remove}) => {
    const [t] = useTranslation();
    const {appState} = useContext(AppContext);
    const isAdmin = appState?.user.roles.indexOf('Admin') !== -1;


    return (
        <TableContainer>
            <Table sx={{minWidth: 650}} aria-label="simple table" className="invite-table">
                <TableHead className="invite-table-header">
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Invited</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users?.map((item) => (
                        <TableRow
                            key={item.fullName}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            className="invite-table-row"
                        >
                            <TableCell component="th" scope="row">
	                            <div className="user-row">
		                            <div className="avatar">
			                            {item.userPicHash
				                            ? <img src={appState.staticHost + '/' + item.userPicHash + '.jpg'}/>
				                            : <AccountCircleOutlinedIcon/>
			                            }
		                            </div>
		                            <div className="info">
			                            <div className="poppins additional_grey_1 fs-16">
				                            {item.fullName}
				                            {!item.invitedById && <span className="badge default">{t('owner')}</span> }
			                            </div>
		                            </div>
	                            </div>
                            </TableCell>
                            <TableCell>
	                            <div className="fs-16">
		                            {item.email}
                                </div>
                            </TableCell>
                            <TableCell>
	                            {item.invitedAt && moment(item.invitedAt).format('YYYY-MM-DD')}
                            </TableCell>
                            <TableCell align="right">
	                            {item.invitedById && isAdmin &&
		                            <Button
			                            onClick={() => remove(item.id)}
			                            color="secondary"
			                            style={{height: 44, marginRight: 8, marginLeft: 8}}
			                            title={t('remove_manager')}
		                            >
			                            <DeleteOutlineOutlinedIcon/>
		                            </Button>
	                            }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UsersTable;
