import React, {useContext,useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRouteMatch} from 'react-router-dom';
import {Box} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import {SendWithPagination} from '../../Misc/backend';
import {PER_PAGE} from '../../Misc/constants';
import HistoryContract from '../HistoryContract/HistoryContract';
import OverlayLoader from '../OverlayLoader/OverlayLoader';

import './index.scss';

const HistoryList = ({contracts, onSelect, onLoadContract, ...props}) => {
    const [pageCount, setPageCount] = useState(1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [t] = useTranslation();

    const loadCompletedContracts = async (pageNumber = 1) => {
        setLoading(true);
        try {
            const [data, itemCount] = await getCompletedContracts(pageNumber);
            const number = Math.ceil(itemCount / PER_PAGE);
            setPageCount(number);
            onLoadContract(data);
            setPage(pageNumber);
        } catch (e) {
            console.log(e);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadCompletedContracts();
    }, []);

    const changePageHandler = (page) => {
        loadCompletedContracts(page);
    };

    return (<OverlayLoader loading={loading}>
        <Box className="list">
            {contracts.map(item=>{
                return <HistoryContract
                    key={`history-contract-${item.id}`}
                    contract={item}
                    gig={item.gigSubject}
                    onSelect={onSelect}
                />;
            })}

            {!pageCount
                ? <h2 className="poppins additional_grey_1 left-column"> {t('no_data')} <br/> {t('try_to_create_a_gig_first')} </h2>
                : <Pagination count={pageCount} page={page} shape="rounded" onChange={(event, page)=> changePageHandler(page)}/>
            }
        </Box>
    </OverlayLoader>
    );
};

const getCompletedContracts = async (page = 1) => {
    let method = 'get';
    let url = `/employers/me/completed-contracts?page=${page-1}&size=${PER_PAGE}`;

    let result =  await SendWithPagination({
        method,
        url,
    });
    return [result.data, result.headers['x-total-count'] ? parseInt(result.headers['x-total-count']) : 0];
};

export default HistoryList;
