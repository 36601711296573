import React from 'react';

export const employerInitState = {
    restaurants: [],
    restaurantsMap: new Map(),
    gigs: [],
    gigsMap: new Map(),
    drafts: [],
};

export const EmployerContext = React.createContext(employerInitState);
