import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Rating from '@material-ui/lab/Rating';
import {useFormik} from 'formik';
import * as yup from 'yup';

import {AppContext} from '../../Contexts/AppContext';
import {Send} from '../../Misc/backend';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';
import reviewTemplate from '../../Misc/review';
import OverlayLoader from '../OverlayLoader/OverlayLoader';

import './index.scss';

const StyledRating = withStyles({
    iconFilled: {
        color: '#ffb400',
    },
    iconHover: {
        color: '#ffb400',
    },
})(Rating);

const validationSchema = yup.object({
    comment: yup
        .string()
        .max(2048),
    rating: yup
        .number()
        .moreThan(0, 'Please set the rating.')
        .required('Rating is required.'),
});

const ReviewForm = ({contractId, onSubmitSuccess, feedback}) => {
    const [review , setReview] = useState(null);
    const [reviewSubmitted, setReviewSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const {appDispatch} = useContext(AppContext);
    const [t] = useTranslation();
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);

    useEffect(()=>{
        async function loadReviews(contractId){
            try{
                const existingReview = await getReviews(contractId);
                if(existingReview){
                    setReview(existingReview);
                }
            } catch (e) {
                console.log(e);
            }
        }

        if(!feedback) loadReviews(contractId);
    }, []);


    const formik = useFormik({
        initialValues: review ?? feedback ?? reviewTemplate,
        validationSchema: validationSchema,
        // enableReinitialize: true,
        onSubmit: async (values) => {
            setLoading(true);
            try{
                const result = await saveReview({...values, contractId});
                setReviewSubmitted(true);
                setReview(values);
                appDispatch({
                    type: 'addSnack',
                    payload: {
                        variant: 'success',
                        message: 'Thank you! Your review was sent.',
                    },
                });
                onSubmitSuccess(result);
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        },
    });

    return  <OverlayLoader loading={loading}>
        <form onSubmit={formik.handleSubmit} className="review-form">
            <div className="flex-align-vertical-middle align-center">
                <Box my={2} className="rating-wrapper">
                    <Box className="additional_grey_1 fs-16  bold">
                        Stars for work:
                    </Box>
                    <FormControl variant="outlined" color="secondary" disabled={reviewSubmitted}>
                        <StyledRating
                            value={formik.values.rating}
                            onChange={(e)=>{
                                formik.setFieldValue('rating', e.target._wrapperState.initialValue);
                            }}
                            className="align-top"
                            id={`rating-${contractId}`}
                            name={`rating-${contractId}`}
                            label="Rating"
                            error={formik.touched.rating && Boolean(formik.errors.rating)}
                            helperText={formik.touched.rating && formik.errors.rating}
                            disabled={reviewSubmitted || feedback || review}
                        />
                        {formik.errors.rating &&
                        <FormHelperText error>{formik.errors.rating}</FormHelperText>
                        }
                    </FormControl>
                </Box>
                <Box my={2} className="input-wrapper">
                    <FormControl fullWidth variant="outlined" color="secondary">
                        <TextField
                            multiline
                            rows={2}
                            rowsMax={2}
                            fullWidth
                            id={`comment-${contractId}`}
                            name="comment"
                            label={t('your_review')}
                            variant="outlined"
                            color="secondary"
                            disabled={reviewSubmitted || feedback || review}
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            helperText={formik.touched.comment && formik.errors.comment}
                        />
                    </FormControl>
                </Box>
            </div>
            {!feedback && !review && <div className="align-right">
                <Button fullWidth={!isDesktop}
                    color="primary"
                    variant="contained" type="submit" disabled={reviewSubmitted} size="large">
                    {t('save')} {t('review')}
                </Button>
            </div>}
        </form>
    </OverlayLoader>;
};


const getReviews = async (contractId) => {
    let method = 'get';
    let url = `/employers/me/contract-feedbacks/${contractId}`;

    return await Send({
        method,
        url,
    });
};


const saveReview = async (data) => {
    let method = 'post';
    let url = '/employers/me/contract-feedbacks';

    return await Send({
        method,
        url,
        data,
    });
};

export default ReviewForm;
