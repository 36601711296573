import React, { useContext, useEffect, useState, Fragment } from 'react';
import {userTemplate} from '../../Misc/user';
import { AppContext } from '../../Contexts/AppContext';
import { Send } from '../../Misc/backend';
import {useFormik} from 'formik';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import './index.scss';
import {normalizePhone} from '../../Misc/phone';
import {useTranslation} from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import FormHelperText from '@material-ui/core/FormHelperText';
import {mapServerValidationToFormik} from '../../Misc/validationsErrors';

const EmployerProfile = ({editState = false, ...rest}) => {
    const [t] = useTranslation();
    const {appState, appDispatch} = useContext(AppContext);
    const [editMode, setEditMode] = useState(false);
    const [phoneLength, setPhoneLength] = useState(11);

    useEffect(()=>{
        setEditMode(editState);
    }, [editState]);


    const validationSchema = yup.object({
        fullName: yup
            .string()
            .nullable()
            .required('First Name is required'),
        phoneNumber: yup
            .string()
            .nullable()
            .matches(new RegExp(`\\d{${phoneLength}}`), t('invalid_phone_number'))
            .required('Phone number is required'),
    });

    const formik = useFormik({
        initialValues: appState.user || userTemplate,
        validationSchema: validationSchema,
        onSubmit: async (values) => {

            try{
                await UpdateEmployer({...values, phoneNumber: values.phoneNumber[0] === '+' ? values.phoneNumber : '+'+values.phoneNumber});
                appDispatch({
                    type: 'setUser',
                    payload: values,
                });
                setEditMode(false);
            } catch (e) {
                console.log(e);
                if(e.response?.data?.errors){
                    formik.setErrors(mapServerValidationToFormik(e.response.data.errors));
                }
            }
        },
    });

    const handlePhoneChange = (e, fieldName) => {
        const phone = normalizePhone(e.target.value, formik.values[fieldName]);
        formik.setFieldValue(fieldName, phone);
    };

    return (
        <OverlayLoader loading={formik.isSubmitting} {...rest}>
            <div className="profile-wrapper">
                <form onSubmit={formik.handleSubmit} className="profile-form card">
                    <div className="profile-info align-left">
                        <div className="profile-info-row">
                            <div className="flex-row space-between">
                                <div className="additional_grey_1 bold fs-16">{t('email')}</div>
                            </div>
                            <div className="additional_grey_2 lh-46 fs-16">
                                {appState?.user?.email}
                            </div>
                        </div>
                        <div className="profile-info-row">
                            <div className="flex-row space-between">
                                <div className="additional_grey_1 bold fs-16">{t('full_name')}</div>
                            </div>
                            {!editMode
                                ? <div className="additional_grey_2 lh-46 fs-16">
                                    {appState?.user?.fullName || '-'}
                                </div>
                                : <TextField
                                    fullWidth
                                    id="fullName"
                                    name="fullName"
                                    variant="outlined"
                                    color="secondary"
                                    value={formik.values.fullName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                                    helperText={formik.touched.fullName && formik.errors.fullName}
                                />

                            }
                        </div>
                        <div className="profile-info-row">
                            <div className="flex-row space-between">
                                <div className="additional_grey_1 bold fs-16">{t('phone_number')}</div>
                            </div>
                            {!editMode
                                ? <div className="additional_grey_2 lh-46 fs-16">
                                    {appState?.user?.phoneNumber || '-'}
                                </div>

                                : <Fragment>
                                    <PhoneInput
                                        country={'se'}
                                        value={formik.values.phoneNumber}
                                        onChange={phone => formik.setFieldValue('phoneNumber', phone)}
                                        placeholder={''}
                                        preferredCountries={['us', 'se']}
                                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                        isValid={(value, country) => {
                                            const requiredLength = country.format.match(/\./g).length;
                                            setPhoneLength(requiredLength)
                                            if (value.length !== requiredLength) {
                                                return false;
                                            }
                                            else {
                                                return true;
                                            }
                                        }}
                                     />
                                    {(formik.errors.phoneNumber && formik.touched.phoneNumber) &&
                                        <FormHelperText style={{marginLeft: 14}} error>{formik.errors.phoneNumber}</FormHelperText>
                                    }
                                </Fragment>
                            }
                        </div>
                    </div>
                    {editMode && <Box my={2} className="edit-buttons">
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            size="large"
                            className="w-40"
                        >
                            {t('save')}
                        </Button>
                        <Button
                            color="secondary"
                            variant="outlined"
                            size="large"
                            className="w-40"
                            onClick={()=>setEditMode(!editMode)}
                        >
                            {t('cancel')}
                        </Button>
                    </Box>}
                    {!editMode && <Box my={2} className="edit-buttons">
                        <Button
                            color="secondary"
                            variant="outlined"
                            size="large"
                            className="w-40"
                            onClick={()=>setEditMode(!editMode)}
                            className="edit-btn"
                        >
                            {t('edit_profile_info')}
                        </Button>
                    </Box>}
                </form>

            </div>
        </OverlayLoader>
    );
};

const UpdateEmployer = async (employer) => {
    return await Send({
        method: 'put',
        url: '/employers/me',
        data: employer,
    });
};

export default EmployerProfile;
