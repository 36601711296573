import React, {useEffect, useState} from 'react';

import {Send} from '../../Misc/backend';
import OverlayLoader from '../OverlayLoader/OverlayLoader';

const Privacy = () => {
    const [loading, setLoading] = useState(false);
    const [privacy, setPrivacy] = useState('');

    useEffect(()=>{
        async function loadPrivacy(){
            setLoading(true);
            try{
                setPrivacy(await getPrivacy());
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }
        loadPrivacy();
    }, []);

    return(
        <OverlayLoader loading={loading}>
            <div className="page">
                <div style={{width: 800, maxWidth: '100%'}} dangerouslySetInnerHTML={{__html: privacy}} />
            </div>
        </OverlayLoader>
    );
};

const getPrivacy = async () => {
    let method = 'get';
    let url = '/legal-information/privacy-policy';

    return await Send({
        method,
        url,
    });
};

export default Privacy;
