import {useMediaQuery} from 'react-responsive';

import RestaurantEdit, { EDIT_VIEW_KEY } from '../../../Components/RestaurantEdit/RestaurantEdit';
import RestaurantInvite, { INVITE_VIEW_KEY } from '../../../Components/RestaurantInvite/RestaurantInvite';
import RestaurantPaymentOptions, { PAYMENT_OPTIONS_VIEW_KEY } from '../../../Components/RestaurantPaymentOptions/RestaurantPaymentOptions';
import { SharedView } from '../../../Components/SharedViews/SharedViews';
import { SharedViewsContextProvider } from '../../../Components/SharedViews/SharedViewsContext';
import {IS_DESKTOP_MEDIA_QUERY} from '../../../Misc/constants';

import RestaurantEditPageDesktop from './RestaurantEditPageDesktop';
import RestaurantEditPageMobile from './RestaurantEditPageMobile';

const RestaurantEditPage = () => {
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);

    return (<>
        <SharedViewsContextProvider>
            <SharedView viewKey={EDIT_VIEW_KEY} ContentView={RestaurantEdit} />


            {isDesktop ? <RestaurantEditPageDesktop /> : <RestaurantEditPageMobile />}
        </SharedViewsContextProvider>
    </>);
};
export default RestaurantEditPage;
