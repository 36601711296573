import LogoLarge from './logo_large.svg';
import LogoMiddle from './logo_middle.svg';
import LogoSmall from './logo_small.svg';
import Partner1 from './partner_1.png';
import Partner2 from './partner_2.png';
import Partner3 from './partner_3.png';
import Partner4 from './partner_4.png';
import RestaurantLogoFish from './left_banner.png';
import WaitingForApprove from './waiting_for_approve.svg';
import Draft from './draft.svg';
import FinishedIcon from './finished_icon.svg';
import InProgressIcon from './in_progress_icon.svg';
import HiringIcon from './hiring_icon.svg';
import IOSButton from './ios_button.png';
import AndroidButton from './android_button.png';
import Usr from './usr.png';
import Duration from './duration.svg';
import Payments from './payments.svg';
import visa from './visa.png';
import mastercard from './mastercard.png';
import {ReactComponent as StepActiveIcon} from './step-active.svg';
import {ReactComponent as StepCompletedIcon}  from './step-completed.svg';

export const Images = {
    LogoLarge,
    LogoMiddle,
    LogoSmall,
    Partner1,
    Partner2,
    Partner3,
    Partner4,
    RestaurantLogoFish,
    WaitingForApprove,
    Draft,
    FinishedIcon,
    InProgressIcon,
    HiringIcon,
    IOSButton,
    AndroidButton,
    Usr,
    Duration,
    Payments,
    visa,
    mastercard,
    StepActiveIcon,
    StepCompletedIcon,
};
