import React, { useContext, useEffect, useState } from 'react';
import { useHistory,useLocation } from 'react-router-dom';
import { LineOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Input,Modal, Spin } from 'antd';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import queryString from 'query-string';

import { AppContext } from '../../Contexts/AppContext';
import UnauthorizedLayout from '../../Layouts/UnauthorizedLayout/UnauthorizedLayout';
import { Send } from '../../Misc/backend';
import user from '../../Misc/user';
import Login from '../Login/Login';
import OverlayLoader from '../OverlayLoader/OverlayLoader';

import './Authorization.scss';


export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

const Authorization = (props) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const {appDispatch} = useContext(AppContext);

    const location = useLocation();




    useEffect(() => {
        let isAuth = location.pathname.split('/')[1] == 'auth';
        let isInvite = location.pathname.split('/')[5] == 'invitations';
        let query = queryString.parse(location.search);

        //we're on auth try page
        if (isAuth && typeof query.sessionId == 'string' && typeof query.token == 'string') {
            localStorage.removeItem('token');
            setLoading(true);

            let isSignedUp = false;

            if(typeof query.IsSignedUp == 'string' && query.IsSignedUp === 'true'){
                isSignedUp = true;
            }

            //TODO: exception handler is missing
            getJWT(location.pathname, query)
                .then(async r => {
                    window.localStorage.setItem('token', r.jwtSecurityToken);
                    isSignedUp = isSignedUp || r.isSignedUp;
                    let userInfo = await getEmployer();
                    if (typeof userInfo == 'object') {
                        userInfo.type = 'employer';
                        userInfo['roles'] = parseJwt(r.jwtSecurityToken).roles;
                        let date = moment().toISOString();
                        user.SaveUser(userInfo);
                        appDispatch({
                            type: 'setUser',
                            payload: userInfo,
                        });

                        window.localStorage.setItem('authDate', date);

                        setLoading(false);
                        //have a good time, bitches
                        if(query.original_url){
                            history.push(query.original_url);
                        } else if(isSignedUp){
                            if(isInvite){
                                history.push('/');
                            } else {
                                history.push('/onboarding');
                            }
                        } else{
                            history.push('/');
                        }

                        setTimeout(() => {
                            props.update();
                        }, 10);
                    }
                })
                .catch(()=>{
                    setLoading(false);
                });
        }

        if(isInvite){
            history.push('/?original_url='+encodeURIComponent(location.pathname+location.search));
        }

    }, []);


    return (
        <UnauthorizedLayout>
            <OverlayLoader loading={loading}>
                <Login/>
            </OverlayLoader>
        </UnauthorizedLayout>
    );
};

const getJWT = async (url, query) => {
    let queryArray = [];
    for (let key in query) {
        queryArray.push(`${key}=${query[key]}`);
    }

    return Send({
        method: 'get',
        url: `${url}?${queryArray.join('&')}`,
    });
};

const getEmployer = async () => {
    return await Send({
        method: 'get',
        url: '/employers/me',
    });
};

export default Authorization;
