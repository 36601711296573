import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ReceiptIcon from '@material-ui/icons/Receipt';
import moment from 'moment';

import {Send} from '../../Misc/backend';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';
import {timespanStringToMomentDuration} from '../../Misc/timespan';
import EmployeeInfoBar from '../EmployeeInfoBar/EmployeeInfoBar';

const GigInfoBar = ({gig, contract}) => {
    const [duration, setDuration] = useState(null);
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const [t] = useTranslation();
    const [hours, minutes, seconds] = gig?.duration.split(':');

    useEffect(()=>{
        if(gig){
            setDuration(timespanStringToMomentDuration(gig.duration));
        }
    }, [gig]);

    const downloadReceipt = async () => {
        try {
            const result = await getReceipt(contract.id);
            const url = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Receipt.pdf');
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className={`right-column flex-align-horizontal-middle additional_grey_1 ${!isDesktop ? 'flex-align-vertical-middle full-width' : ''}`}>

            {/*desktop view*/}

            {isDesktop && <>
                <Box mx={2}>
                    <EmployeeInfoBar contract={contract}/>
                </Box>
                {(contract?.moonlighter?.phoneNumber || contract?.moonlighter?.email) &&
                <Divider orientation="vertical" flexItem />}
                <Box mx={2} className="no-text-wrap">
                    <div className="fs-14 align-left">
                        <div>{hours > 0 ? Math.floor(gig.price/hours) : gig.price}&nbsp;SEK per hour</div>
                    </div>
                    <div className="fs-14 align-left">
                        {gig.price} SEK
                    </div>
                </Box>
                <Divider orientation="vertical" flexItem />
                {(gig.start && duration) && <Box mx={2} className="additional_grey_1 no-text-wrap">
                    <div className="fs-14 align-left">{moment.utc(gig.start).local().format('DD MMM YYYY')}</div>
                    <div className="fs-14 align-left">
                        {moment.utc(gig.start).local().format('HH:mm')} –
                        {moment.utc(gig.start).local().add(duration?.asSeconds(), 'seconds').format('HH:mm')}
                    </div>
                </Box>}

                {(contract.finishedAt) && <Box mx={2}>
                    <div onClick={downloadReceipt}>
                        <ReceiptIcon className="pointer neon additional_grey_2"/>
                    </div>
                </Box>}
            </>}

            {/*mobile view*/}

            {!isDesktop && <>
                <Box my={1} className="no-text-wrap flex-align-horizontal-middle space-between">
                    <div className="fs-14 align-left">
                        <div>{hours > 0 ? Math.floor(gig.price/hours) : gig.price}&nbsp;SEK per hour</div>
                    </div>
                    <div className="fs-14 align-left">
                        {gig.price} SEK
                    </div>
                </Box>
                <Divider/>
                {(gig.start && duration) && <Box my={1} className="additional_grey_1 no-text-wrap space-between">
                    <div className="fs-14 align-left">{moment.utc(gig.start).local().format('DD MMM YYYY')}</div>
                    <div className="fs-14 align-left">
                        {moment.utc(gig.start).local().format('HH:mm')} –
                        {moment.utc(gig.start).local().add(duration?.asSeconds(), 'seconds').format('HH:mm')}
                    </div>
                </Box>}
                <Divider/>
                <Box mt={1} mb={2} className="space-between">
                    <EmployeeInfoBar contract={contract}/>
                </Box>
            </>}
        </div>
    );
};


const getReceipt = async (contractId) => {
    let method = 'get';
    let url = `/reports/salary?contractId=${contractId}&isEmployerReport=true&format=pdf`;

    return await Send({
        method,
        url,
        responseType: 'arraybuffer',
    });
};


export default GigInfoBar;
