export const restaurantTemplate = {
    'name': '',
    'latitude': 0,
    'longitude': 0,
    'phoneNumber': '',
    'address': '',
    'website': '',
    'description': '',
    'email': '',
    'orgNr': '',
    'photoHashes': [],
};

export const restaurantPhotosTemplate = {
    'photoHashes': [],
};

export const restaurantMainInfoTemplate = {
    'name': '',
    'latitude': 0,
    'longitude': 0,
    'phoneNumber': '',
    'address': '',
    'website': '',
    'description': '',
    'orgNr': '',
};

export default restaurantTemplate;
