import React, {Fragment,useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import {useRouteMatch} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import {withStyles} from '@material-ui/core/styles';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Rating from '@material-ui/lab/Rating';

import {AppContext} from '../../Contexts/AppContext';
import {EmployerContext} from '../../Contexts/EmployerContext';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';
import GigInfoBar from '../GigStatusBar/GigStatusBar';

import './index.scss';

const StyledRating = withStyles({
    iconFilled: {
        color: '#ffb400',
    },
    iconHover: {
        // color: '#3ef3e9',
    },
})(Rating);

const HistoryContract = ({
    contract,
    gig,
    onSelect,
}) => {
    const {appState} = useContext(AppContext);
    const {employerState} = useContext(EmployerContext);
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const [t] = useTranslation();
    console.log(contract);

    return <>
        <Box mb={3} className="history-contract" onClick={() => onSelect(contract?.id)}>
            <div className="tile-content">
                <div className="tile-content-header fs-16 primary_black poppins">
                    <div className="left-column">
                        <Box mr={1.5} className="image-circle small">
                            {contract?.moonlighter?.userPicHash
                                ? <img src={appState.staticHost+'/'+contract?.moonlighter?.userPicHash+'.jpg'}/>
                                : <AccountCircleOutlinedIcon/>}
                        </Box>
                        <div className="status">
                            <div className="primary_black fs-16 bold poppins name-status">
                                <span>
                                    <span>{contract.moonlighter?.fullName}&nbsp;</span>
                                    <span className="additional_grey_2">on&nbsp;
                                        {contract && employerState?.restaurants && employerState?.restaurants.find(x => x.id === contract?.gigSubject?.restaurantId)?.name}
                                    </span>
                                </span>
                                <span>
                                    {(contract.cancelledAt) && <span className="badge error">
                                    cancelled
                                    </span>}
                                    {(!contract?.moonlighterFeedback && !contract.cancelledAt) && <span className="badge default">
                                        Waiting for review
                                    </span>}
                                </span>

                            </div>
                            <div className="align-top">
                                <StyledRating
                                    readOnly
                                    value={contract.moonlighter?.rating}
                                    name="simple-controlled"
                                    className="fs-16 align-top"
                                />
                                <span className="fs-12 additional_grey_2 align-top"> {contract.moonlighter?.rating}</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex-align-horizontal-middle">
                        {(contract.finishedAt) && <GigInfoBar gig={gig} contract={contract}/>}
                    </div>
                </div>
            </div>
        </Box>
    </>;
};

export default HistoryContract;
