import React, {useContext} from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import OverlayLoader from '../../Components/OverlayLoader/OverlayLoader';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import Header from '../../Components/Header/Header';
import SnackbarQueue from '../../Components/SnackbarQueue/SnackbarQueue';
import Footer from '../../Components/Footer/Footer';
import {AppContext} from '../../Contexts/AppContext';

import './index.scss';


const MainLayout = ({children}) => {
    const {appState} = useContext(AppContext);


    return <div className="layout main relative">
        <SnackbarQueue/>
        <Header/>
        <div className="content">
            {children}
        </div>
        <Footer/>
        {appState?.stripePaymentActionsModalState && <Dialog
            open={detailsVisible}
            onClose={()=>setDetailsVisible(false)}
            aria-labelledby="form-dialog-title"
            className="confirmation"
        >
            <DialogContent>
                <OverlayLoader loading={loading}>
                    <Box my={3} className="primary_black fs-24 poppins">
                        {t('hire_employee')}
                    </Box>

                    <Box my={2} className="actions">
                        <Button
                            fullWidth
                            size="large"
                            color="secondary"
                            variant="outlined"
                            type="submit"
                            className="w-20"
                        >
                            {t('cancel')}
                        </Button>

                        <Button
                            fullWidth
                            size="large"
                            color="primary"
                            variant="contained"
                            type="submit"
                            className="w-40"
                        >
                            {t('hire_employee')}
                        </Button>
                    </Box>
                </OverlayLoader>
            </DialogContent>
        </Dialog>}
    </div>;
};

export default MainLayout;
