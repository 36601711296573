import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {makeStyles, Paper} from '@material-ui/core';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        border: `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
	    maxWidth: '164px',
    },
    divider: {
        margin: '1px',
    },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: '1px',
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: '4px',
        },
        '&:first-child': {
            borderRadius: '4px',
        },
	    "&.Mui-selected, &.Mui-selected:hover": {
		    color: '#0A192D',
		    backgroundColor: '#0ED3C9'
	    },
	    "&.Mui-selected.Mui-disabled , &.Mui-disabled.Mui-selected:hover": {
		    color: 'rgba(0, 0, 0, 0.12)',
		    backgroundColor: 'rgba(0, 0, 0, 0.12)',
	    },
    },
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles((theme) => ({
    label: {
        fontSize: '14px',
        textTransform: 'capitalize',
    },
    smallSize: {
        padding: '9px 16px',
    },
}))(ToggleButton);

const GigslyToggle = ({checked, setChecked, ...props}) => {
    const [value, setValue] = React.useState(!checked ? 'hour' : 'fixed');

    const handleChange = (event, newValue) => {
		if (!newValue) {
			return;
		}
        if (newValue === value) {
            return;
        }
        setValue(newValue);
        setChecked();
    };

    const classes = useStyles();
    return (
        <div>
	        <Paper elevation={0} className={classes.paper}>
		        <StyledToggleButtonGroup
			        size="small"
			        value={value}
			        exclusive
			        onChange={handleChange}
			        aria-label="text alignment"
		        >
			        <StyledToggleButton value="fixed" aria-label="left aligned" disabled={props.disabled}>
				        Fixed
			        </StyledToggleButton>
			        <StyledToggleButton value="hour" aria-label="centered"disabled={props.disabled}>
				        Per hour
			        </StyledToggleButton>
		        </StyledToggleButtonGroup>
	        </Paper>
        </div>
    );
};

export default GigslyToggle;
