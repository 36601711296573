import React, {useContext, Fragment, useEffect} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useSnackbar } from 'notistack';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import {AppContext} from '../../Contexts/AppContext';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarQueue = () => {
    const {appState, appDispatch} = useContext(AppContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const closeSnack = (item) => {
        appDispatch({
            type: 'removeSnack',
            payload: item,
        });
    };

    useEffect(()=>{
        if(appState?.snacks.length > 0) {
            const key = enqueueSnackbar(
                appState?.snacks[0].message,
                {
                    autoHideDuration: appState?.snacks[0].autoHideDuration || 15000,
                    variant: appState?.snacks[0].variant,
                    persist: appState?.snacks[0].persist,
                    action: (key) => <CloseOutlinedIcon className="pointer" onClick={()=> {
                        closeSnackbar(key);

                    }}/>,
                    onClick: () => {
                        closeSnackbar(key);
                    },
                });
            appDispatch({
                type: 'removeSnack',
                payload: appState?.snacks[0],
            });
        }
    }, [appState.snacks]);

    return (
        <Fragment>
            <div className="snackbar-queue"/>
        </Fragment>

    );
};

export default SnackbarQueue;
