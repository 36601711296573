import moment from 'moment';

export const timespanStringToMomentDuration = (timespan) => {
    // const [hours, minutes, seconds] = timespan.split(':');
    // return  moment.duration(hours*3600 + minutes*60 + seconds*1, 'seconds');
    return  moment.duration(timespan);
};

export const momentDurationToTimespanString = (duration) => {
    const hours = duration.asHours();
    return hours === 24 ? '1.00:00:00' : moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
};
