import React, {Fragment, useContext, useEffect, useState} from 'react';
import {EmployerContext} from '../../Contexts/EmployerContext';
import {useFormik} from 'formik';
import OverlayLoader from '../../Components/OverlayLoader/OverlayLoader';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import LocationSearchInput from '../../Components/LocationSearchInput/LocationSearchInput';
import Button from '@material-ui/core/Button';
import {Send} from '../../Misc/backend';
import {useLocation, useRouteMatch} from 'react-router-dom';
import restaurantTemplate from '../../Misc/restaurant';
import * as yup from 'yup';
import BackButton from '../../Components/BackButton/BackButton';
import GoogleMapReact from 'google-map-react';
import {ReactComponent as Pin} from '../../static/images/pin.svg';
import Dropzone from 'react-dropzone';
import {usePrevious} from '../../Misc/usePrevious';
import {v4 as uuid} from 'uuid';
import classNames from 'classnames';
import customHistory from '../../Misc/history';

import ActionsOverlay from '../RemoveOverlay/ActionsOverlay';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import {AppContext} from '../../Contexts/AppContext';
import {availableTime, IS_DESKTOP_MEDIA_QUERY, PHONE_REGEX} from '../../Misc/constants';
import {useMediaQuery} from 'react-responsive';
import InputMask from 'react-input-mask';
import {normalizePhone} from '../../Misc/phone';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import {mapServerValidationToFormik} from '../../Misc/validationsErrors';
import './RestaurantEdit.scss';
import RestaurantPhotosList from './RestaurantPhotosList/RestaurantPhotosList';
import { createPortal } from 'react-dom';
import { useSharedViewContainerValue } from '../SharedViews/SharedViewsContext';

const URLRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
const orgNumberRegex = /^[^_]+$/;

const googleApiKey = typeof process.env.REACT_APP_GOOGLE_API_KEY === 'string'
&& process.env.REACT_APP_GOOGLE_API_KEY.length
    ? process.env.REACT_APP_GOOGLE_API_KEY
    : null;

const hours = [
    {
        start: '00:00',
        end: '00:00',
    },
    {
        start: '00:00',
        end: '00:00',
    },
    {
        start: '00:00',
        end: '00:00',
    },
    {
        start: '00:00',
        end: '00:00',
    },
    {
        start: '00:00',
        end: '00:00',
    },
    {
        start: '00:00',
        end: '00:00',
    },
    {
        start: '00:00',
        end: '00:00',
    },
];

export const EDIT_VIEW_KEY = 'editView';

const RestaurantEdit = ({restaurantId}) => {
    const portalContainer = useSharedViewContainerValue(EDIT_VIEW_KEY);
    const {employerState , employerDispatch} = useContext(EmployerContext);
    const {appState, appDispatch} = useContext(AppContext);
    const [restaurant, setRestaurant] = useState(restaurantTemplate);
    const previousRestaurant = usePrevious(restaurant);
    const [loading, setLoading] = useState(false);
    const [photos, setPhotos] = useState([]);
    const previousPhotos = usePrevious(photos);
    const [photosAreBeingUploaded, setPhotosAreBeingUploaded] = useState([]);
    const [uuidHashTable , setUuidHashTable] = useState(new Map());
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const [t] = useTranslation();
    const [phoneLength, setPhoneLength] = useState(11);

    const updateRestaurant = (fieldName, data) => {
        setRestaurant(previous => {
            var newRestaurant = { ...previous };
            newRestaurant[fieldName] = data;

            return newRestaurant;
        });
    };

    const updatePhotoHashes = values => {
        updateRestaurant('photoHashes', [...values]);
    };

    const updatePhotoHashesOrder = updatedPhotos => {
        const newPhotoHashes = updatedPhotos.map(photo => uuidHashTable.get(photo.uuid));
        updatePhotoHashes(newPhotoHashes);
    };
    
    const validationSchema = yup.object({
        name: yup
            .string()
            .required(t('name_is_required')),
        phoneNumber: yup
            .string()
            .matches(new RegExp(`\\d{${phoneLength}}`), t('invalid_phone_number'))
            .required(t('phone_is_required')),
        website: yup
            .string()
            .nullable()
            .matches(URLRegex, t('invalid_website_url')),
        address: yup
            .string()
            .required(t('address_is_required')),
        email: yup
            .string()
            .email()
            .required(t('email_is_required')),
        orgNr: yup
            .string()
            .matches(orgNumberRegex, t('invalid_organisation_number'))
            .required(t('organisation_number_is_required')),
        description: yup
            .string()
            .max(2000)
            .nullable()
            .required(t('description_is_required')),
    });

    useEffect(()=>{
        async function loadRestaurant(restaurantId) {
            setLoading(true);
            try{
                setRestaurant(await getRestaurant(restaurantId));
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }
        if(restaurantId && restaurantId!=='new'){
            loadRestaurant(restaurantId);
        }
    }, [restaurantId]);


    useEffect(()=>{
        function loadPhotos(currentRestaurant) {
            if (currentRestaurant.photoHashes?.length > 0) {
                const newHashTable = new Map();

                //set photos
                setPhotos(currentRestaurant.photoHashes.map(item => {
                    const uuid1 = uuid();

                    newHashTable.set(uuid1, item);

                    return {
                        src: appState.staticHost+'/'+item+'.jpg',
                        uuid: uuid1,
                        file: {name: item+'.jpg'},
                    };
                }));

                //set hash table
                setUuidHashTable(newHashTable);
            }
        }

        if(typeof previousRestaurant?.id === 'undefined'){
            loadPhotos(restaurant);
        }
    }, [restaurant.id]);

    useEffect(()=>{
        updatePhotoHashes(uuidHashTable.values());
    }, [uuidHashTable]);

    useEffect(()=>{
        async function upload(f){
            try{
                //set loader for this file
                setPhotosAreBeingUploaded((previous) => [...previous, f.uuid]);
                //save file in async
                let result = await savePhoto(f.file);
                //set relation between hash and uuid to handle this file later
                setUuidHashTable((previous) => {
                    const newState = new Map(previous);
                    newState.set(f.uuid, result);
                    return newState;
                });
                //turn off loader for this file
                setPhotosAreBeingUploaded((previous) => {
                    const start = previous.indexOf(f.uuid);
                    previous.splice(start,1);
                    return [...previous];
                });
            } catch (e) {
                setPhotosAreBeingUploaded((previous) => {
                    const start = previous.indexOf(f.uuid);
                    previous.splice(start,1);
                    return [...previous];
                });

                setPhotos([...photos].filter(item=>item.uuid !== f.uuid));
            }
        }

        const newPhotos = photos.filter(item => previousPhotos.find(prevItem => prevItem.uuid === item.uuid) == undefined);
        if (newPhotos.length > 0) {
            newPhotos.forEach(photo => {
                if(photo.file.path) {
                    upload(photo);
                }
            });
        } else {
            updatePhotoHashesOrder(photos);
        }
    },[photos]);

    const handleSaveRestaurant = (restaurant, replace = false) => {
        if (replace) {
            employerDispatch({
                type: 'setRestaurants',
                payload: employerState.restaurants.map(item => {
                    return item.id === restaurant.id ? restaurant : item;
                }),
            });
        } else {
            employerDispatch({
                type: 'setRestaurants',
                payload: [...employerState.restaurants, restaurant],
            });
        }
    };

    const formik = useFormik({
        initialValues: restaurant,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try{
                //set first picture as cover if cover is not specified
                if(typeof values?.coverPhotoHash === 'undefined'
                    || values.coverPhotoHash === null
                    || values.photoHashes?.indexOf(values.coverPhotoHash) === -1){
                    if(values.photoHashes?.length > 0 ){
                        values.coverPhotoHash = values.photoHashes[0];
                    }
                }

                const data = {...restaurant, ...values, phoneNumber: values.phoneNumber[0] === '+' ? values.phoneNumber : '+'+values.phoneNumber};
                handleSaveRestaurant(await saveRestaurant(data), !!restaurant.id);
                appDispatch({
                    type: 'addSnack',
                    payload: {
                        variant: 'success',
                        message: 'The changes have been saved.',
                    },
                });
                customHistory.back();
            } catch (e) {
                console.log(e);
                if(e.errors){
                    formik.setErrors(mapServerValidationToFormik(e.errors));
                }
            }
        }
    });

    const selectAddress = (value) => {
        updateRestaurant('address', value.address);
        updateRestaurant('latitude', value.lat);
        updateRestaurant('longitude', value.lng);
    };

    const handleFiles = acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                // Do whatever you want with the file contents
                setPhotos((prevValue) => [...prevValue, {
                    src: reader.result,
                    uuid: uuid(),
                    file: file,
                }]);
            };
            reader.readAsDataURL(file);
        });
    };

    const checkIsPhotoCovered = photo => restaurant.coverPhotoHash === uuidHashTable.get(photo.uuid);

    const setCover = (hash) => {
        updateRestaurant('coverPhotoHash', hash);
        updatePhotoHashesOrder(photos);
    };

    useEffect(()=>{
        if(restaurant.coverPhotoHash){
            photos.sort((a, b) => {
                if(uuidHashTable.get(a.uuid) === restaurant.coverPhotoHash) return -1;
                if(uuidHashTable.get(b.uuid) === restaurant.coverPhotoHash) return 1;
                return 0;
            });
        }
    }, [restaurant.coverPhotoHash, photos]);

    const handlePhotoRemove = (uuid) => {
        const newHashTable = new Map(uuidHashTable);
        newHashTable.delete(uuid);
        setUuidHashTable(newHashTable);

        setPhotos([...photos].filter(item=>item.uuid !== uuid));
    };

    const handlePhoneChange = (e, fieldName) => {
        const phone = normalizePhone(e.target.value, restaurant[fieldName]);
        updateRestaurant(fieldName, phone);
    };

    return (portalContainer && createPortal(
        <OverlayLoader loading={formik.isSubmitting || loading}>
            <div className="restaurant">
                <form onSubmit={formik.handleSubmit}>
                    <div className="card card-large main-info">
                        <Box className="poppins fs-16 bold">
                            {t('main_information')}
                        </Box>

                        <Box my={2}>
                            <TextField
                                fullWidth
                                id="name"
                                name="name"
                                label={t('name')}
                                variant="outlined"
                                color="secondary"
                                value={restaurant.name}
                                onChange={e => updateRestaurant('name', e.target.value)}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Box>
                        <Box my={2}>
                            <PhoneInput
                                country={'se'}
                                value={restaurant.phoneNumber}
                                onChange={phone => updateRestaurant('phoneNumber', phone)}
                                placeholder={''}
                                preferredCountries={['us', 'se']}
                                isValid={(value, country) => {
                                    const requiredLength = country.format.match(/\./g).length;
                                    if(requiredLength !== phoneLength) {
                                        setPhoneLength(requiredLength);
                                    }

                                    if (value.length !== requiredLength) {
                                        return false;
                                    }
                                    else {
                                        return true;
                                    }
                                }}
                            />
                            {(formik.errors.phoneNumber && formik.touched.phoneNumber) &&
                                <FormHelperText style={{marginLeft: 14}} error>{formik.errors.phoneNumber}</FormHelperText>
                            }
                        </Box>
                        <Box my={2}>
                            <LocationSearchInput
                                fullWidth
                                id="address"
                                name="address"
                                label={t('address')}
                                variant="outlined"
                                color="secondary"
                                onSelect={selectAddress}
                                value={restaurant.address}
                                onChange={value => updateRestaurant('address', value)}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                            />
                        </Box>

                        <Box my={2} className="map-wrapper">
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: googleApiKey }}
                                center={{
                                    lat: restaurant.latitude,
                                    lng: restaurant.longitude,
                                }}
                                defaultZoom={13}
                            >
                                <Pin
                                    lat={restaurant.latitude}
                                    lng={restaurant.longitude}
                                    text={restaurant.name}
                                    style={{transform: 'translate(-50%, -90%)'}}
                                />
                            </GoogleMapReact>
                        </Box>
                        <Box my={2}>
                            <TextField
                                fullWidth
                                id="website"
                                name="website"
                                label={t('website')}
                                variant="outlined"
                                color="secondary"
                                value={restaurant.website}
                                onChange={e => updateRestaurant('website', e.target.value)}
                                error={formik.touched.website && Boolean(formik.errors.website)}
                                helperText={formik.touched.website && formik.errors.website}
                            />
                        </Box>
                        <Box my={2}>
                            <TextField
                                fullWidth
                                id="email"
                                name="email"
                                label={t('email')}
                                variant="outlined"
                                color="secondary"
                                value={restaurant.email}
                                onChange={e => updateRestaurant('email', e.target.value)}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Box>
                        <Box my={2}>
                            <InputMask
                                mask="999999-9999"
                                value={restaurant.orgNr}
                                onChange={e => updateRestaurant('orgNr', e.target.value)}
                            >
                                {() => <TextField
                                    fullWidth
                                    variant="outlined"
                                    color="secondary"
                                    id="orgNr"
                                    name="orgNr"
                                    label={t('organisation_number')}
                                    error={formik.touched.orgNr && Boolean(formik.errors.orgNr)}
                                    helperText={formik.touched.orgNr && formik.errors.orgNr}
                                />}
                            </InputMask>
                        </Box>
                        <Box my={2}>
                            <TextField
                                multiline
                                rows={4}
                                rowsMax={4}
                                fullWidth
                                id="description"
                                name="description"
                                label={t('description')}
                                variant="outlined"
                                color="secondary"
                                value={restaurant.description}
                                onChange={e => updateRestaurant('description', e.target.value)}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                        </Box>
                        {/*<Box mt={4}>*/}
                        {/*    <div className="poppins fs-16 bold">*/}
                        {/*        {t('opening_hours')}*/}
                        {/*    </div>*/}
                        {/*</Box>*/}
                        {/*<Box my={2} className="align-middle fs-32 additional_grey_3">*/}
                        {/*    <FormControl variant="outlined">*/}
                        {/*        <InputLabel id="open-time-label" color="secondary">{t('open')}</InputLabel>*/}
                        {/*        <Select*/}
                        {/*            native={!isDesktop}*/}
                        {/*            fullWidth*/}
                        {/*            labelId="open-time-label"*/}
                        {/*            id="open"*/}
                        {/*            name="open"*/}
                        {/*            color="secondary"*/}
                        {/*            defaultValue="00:00"*/}
                        {/*            value={formik.values.open}*/}
                        {/*            onChange={formik.handleChange}*/}
                        {/*            error={formik.touched.open && Boolean(formik.errors.open)}*/}
                        {/*            helperText={formik.touched.open && formik.errors.open}*/}
                        {/*            label={t('open')}*/}
                        {/*        >*/}
                        {/*            {isDesktop && availableTime.map(item => {*/}
                        {/*                return <MenuItem value={item}>{item}</MenuItem>;*/}
                        {/*            })}*/}

                        {/*            {!isDesktop && availableTime.map(item => {*/}
                        {/*                return <option value={item}>{item}</option>;*/}
                        {/*            })}*/}
                        {/*        </Select>*/}
                        {/*    </FormControl>*/}

                        {/*    &nbsp;&mdash;&nbsp;*/}
                        {/*    <FormControl variant="outlined">*/}
                        {/*        <InputLabel id="close-time-label" color="secondary">{t('close')}</InputLabel>*/}
                        {/*        <Select*/}
                        {/*            native={!isDesktop}*/}
                        {/*            fullWidth*/}
                        {/*            labelId="close-time-label"*/}
                        {/*            id="close"*/}
                        {/*            name="close"*/}
                        {/*            color="secondary"*/}
                        {/*            defaultValue="00:00"*/}
                        {/*            value={formik.values.close}*/}
                        {/*            onChange={formik.handleChange}*/}
                        {/*            error={formik.touched.close && Boolean(formik.errors.close)}*/}
                        {/*            helperText={formik.touched.close && formik.errors.close}*/}
                        {/*            label={t('close')}*/}
                        {/*        >*/}
                        {/*            {isDesktop && availableTime.map(item => {*/}
                        {/*                return <MenuItem value={item}>{item}</MenuItem>;*/}
                        {/*            })}*/}

                        {/*            {!isDesktop && availableTime.map(item => {*/}
                        {/*                return <option value={item}>{item}</option>;*/}
                        {/*            })}*/}
                        {/*        </Select>*/}
                        {/*    </FormControl>*/}

                        {/*    /!*<Select*!/*/}
                        {/*    /!*    native={!isDesktop}*!/*/}
                        {/*    /!*>*!/*/}
                        {/*    /!*    {isDesktop && <Fragment>*!/*/}
                        {/*    /!*        <MenuItem value={0}>Everyday</MenuItem>*!/*/}
                        {/*    /!*        <MenuItem value={1}>Weekdays/Weekend</MenuItem>*!/*/}
                        {/*    /!*        <MenuItem value={2}>By Day</MenuItem>*!/*/}
                        {/*    /!*    </Fragment>}*!/*/}

                        {/*    /!*    {!isDesktop && <Fragment>*!/*/}
                        {/*    /!*        return <option value={0}>Everyday</option>;*!/*/}
                        {/*    /!*        return <option value={1}>Weekdays/Weekend</option>;*!/*/}
                        {/*    /!*        return <option value={2}>By Day</option>;*!/*/}
                        {/*    /!*    </Fragment>}*!/*/}
                        {/*    /!*</Select>*!/*/}
                        {/*</Box>*/}
                    </div>
                    <div className="card card-large gallery">
                        <div className="poppins fs-16 bold">
                            {t('gallery')}
                        </div>

                        <Box my={2}>
                            <div className="pointer">
                                <Dropzone onDrop={handleFiles}>
                                    {({getRootProps, getInputProps}) => (
                                        <section >
                                            <div className="dropzone" {...getRootProps()}>
                                                <input {...getInputProps()} accept=".jpg,.jpeg,.png,.gif"/>
                                                {photos?.length === 0 && <div className="empty-dropzone-text">
                                                    <div className="bold additional_grey_1 fs-16 poppins">{t('drag_n_drop')}</div>
                                                </div>}
                                                {photos?.map(item => <OverlayLoader
                                                    loading={photosAreBeingUploaded.indexOf(item.uuid) !== -1}
                                                    key={item.uuid}
                                                >
                                                    <ActionsOverlay removeHandler={() => handlePhotoRemove(item.uuid)}>
                                                        <img src={item.src}/>
                                                    </ActionsOverlay>
                                                </OverlayLoader>,
                                                )}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                        </Box>

                        {photos?.length > 0 && <Box my={2}>
                            <RestaurantPhotosList
                                photos={photos}
                                isPhotoCoveredFn={checkIsPhotoCovered}
                                onListUpdate={setPhotos}
                                onListItemClick={photoUuid => setCover(uuidHashTable.get(photoUuid))}
                                photosAreBeingUploaded={photosAreBeingUploaded}
                            />
                        </Box>}

                        {isDesktop
                            ? <div className="actions">
                                <Button size="large" color="secondary" variant="outlined"
                                    onClick={() => customHistory.back()}
                                >
                                    {t('cancel')}
                                </Button>
                                <Button color="primary" variant="contained" fullWidth type="submit" size="large">
                                    {restaurant.id ? t('save') : t('create')}
                                </Button>
                            </div>
                            : <div>
                                <Button fullWidth color="primary" variant="contained" type="submit" size="large">
                                    {restaurant.id ? t('save') : t('create')}
                                </Button>
                                <Box mt={2}>
                                    <Button fullWidth size="large" color="secondary" variant="outlined"
                                        onClick={() => customHistory.back()}>
                                        {t('cancel')}
                                    </Button>
                                </Box>
                            </div>}
                    </div>


                </form>
            </div>

        </OverlayLoader>
    , portalContainer));
};


const getRestaurant = async (id) => {
    let method = 'get';
    let url = '/employers/me/restaurants/'+ id;

    return await Send({
        method,
        url,
    });
};

const saveRestaurant = async (data) => {
    let method = 'post';
    let url = '/employers/me/restaurants';
    if(data.id) {
        method = 'put';
        url += '/'+ data.id;
    }
    return await Send({
        method,
        url,
        data,
    });
};


const savePhoto = async (file) => {
    const formData = new FormData();
    formData.append('uploadedFile', file);
    let method = 'post';
    let url = '/images';
    return await Send({
        method,
        url,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};


export default RestaurantEdit;