import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import customHistory from './Misc/history';
import App from './App';

const AppWrapper = () => {
    return <Router history={customHistory}>
        <App/>
    </Router>;
};

export default AppWrapper;
