import React, { useContext,useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import classNames from 'classnames';

import { AppContext } from '../../../../Contexts/AppContext';
import { IS_DESKTOP_MEDIA_QUERY } from '../../../../Misc/constants';
import Review from '../../../Review/Review';

import ReviewCompleted from './Review/ReviewCompleted';
import ReviewForm from './Review/ReviewForm';

import './DetailTabs.scss';

const DetailsTabs = ({ contract, payment, reviews }) => {
    const [value, setValue] = useState(0);
    const [expandedReviews, setExpandedReviews] = useState(false);
    const [t] = useTranslation();
    const { appState } = useContext(AppContext);
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleReviewSubmitSuccess = (newReview) => {
        //setReviews(prev => [...prev, newReview]);
        // setValue(1);
    };

    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                style={{ maxWidth: '100%' }}
                className="details-tabs"

            >
                {contract.finishedAt && (
                    <Tab
                        value={0}
                        className="poppins additional_grey_1 bold fs-16"
                        label={t('review')}
                    />
                )}
                <Tab
                    value={1}
                    className="poppins additional_grey_1 bold fs-16"
                    label={t('description')}
                />
            </Tabs>
            <div
                className={classNames({
                    'display-none': value !== 0,
                    'tab-body additional_grey_2 fs-14': true,
                })}
            >
                {!contract.moonlighterFeedback ? (
                    <ReviewForm
                        key={`review-form-for-${contract.id}`}
                        contract={contract}
                        onSubmitSuccess={handleReviewSubmitSuccess}
                    />
                ) : (
                    <ReviewCompleted contract={contract} />
                )}
            </div>

            <div
                className={classNames({
                    'display-none': value !== 1,
                    'tab-body additional_grey_2 fs-14': true,
                })}
            >
                {contract.moonlighter?.description === null && (
                    <Box my={2}>{t('no_description_was_provided')}.</Box>
                )}
                {contract.moonlighter?.description}
            </div>
        </>
    );
};

export default DetailsTabs;
