import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useRouteMatch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import BackButton from '../../../Components/BackButton/BackButton';
import Integration from '../../../Components/Integration/Integration';
import { EDIT_VIEW_KEY } from '../../../Components/RestaurantEdit/RestaurantEdit';
import { INVITE_VIEW_KEY } from '../../../Components/RestaurantInvite/RestaurantInvite';
import { PAYMENT_OPTIONS_VIEW_KEY } from '../../../Components/RestaurantPaymentOptions/RestaurantPaymentOptions';
import { SharedViewContainer } from '../../../Components/SharedViews/SharedViews';
import { EmployerContext } from '../../../Contexts/EmployerContext';
import {Send} from '../../../Misc/backend';

const RestaurantEditPageDesktop = () => {
    const { employerState } = useContext(EmployerContext);
    const [value, setValue] = useState(0);
    const [t] = useTranslation();
    const match = useRouteMatch('/restaurant/edit/:id');
    const { hash } = useLocation();
    const [restaurant, setRestaurant] = useState(null);
    const [subTitle, setSubTitle] = useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        window.location.hash = newValue;
    };

    useEffect(() => {
        if (hash) {
            setValue(parseInt(hash.slice(1)));
        }
    }, [hash]);

    useEffect(() => {
        setRestaurant(
            employerState.restaurants.find((x) => x.id === match.params.id),
        );
    }, []);



    useEffect(() => {
        function getSubTitleByValue(value) {
            switch (value) {
            case 1:
                return 'Restaurant Integration';
            case 2:
                return 'Restaurant Invitations';
            default:
                return 'Restaurant Edit';
            }
        }

        setSubTitle(getSubTitleByValue(value));
    }, [value]);

    return (
        <div className="page restaurant">
            <Helmet>
                <title>GPG App - {subTitle}</title>
            </Helmet>
            <BackButton url="/restaurants" />
            <Box className="page-header">
                <span className="poppins primary_black">
                    {restaurant?.name || t('edit_restaurant')}
                </span>
            </Box>
            <Box my={3}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    indicatorColor="primary"
                    style={{ maxWidth: '100%' }}
                >
                    <Tab
                        value={0}
                        className="poppins additional_grey_1 bold fs-16"
                        label={t('restaurant_info')}
                    />
                    <Tab
                        value={1}
                        className="poppins additional_grey_1 bold fs-16"
                        label={t('integration')}
                    />
                </Tabs>
            </Box>
            {match?.params?.id && (
                <div>
                    <TabContent isVisible={value === 0}>
                        <SharedViewContainer
                            restaurantId={match.params.id}
                            viewKey={EDIT_VIEW_KEY}
                        />
                    </TabContent>
                    <TabContent isVisible={value === 1}>
                        <Integration
                            restaurantId={match.params.id}
                        />

                    </TabContent>
                </div>
            )}
        </div>
    );
};

const TabContent = ({ isVisible, children }) => {
    return (
        <div className={isVisible ? 'display-block' : 'display-none'}>
            {children}
        </div>
    );
};



export default RestaurantEditPageDesktop;
