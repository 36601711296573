import React from 'react';
import Box from '@material-ui/core/Box';
import {Images} from '../../static/images';
import Button from '@material-ui/core/Button';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import moment from 'moment';

import {timespanStringToMomentDuration} from '../../Misc/timespan';

import './index.scss';
import {roundAccurately} from '../../Misc/calculations';

const DraftTile = ({
    draft,
    skills,
    onItemEditClick,
    onItemDeleteClick,
    onItemPublishClick,
}) => {
    const duration = typeof draft?.duration !== 'undefined' ? timespanStringToMomentDuration(draft.duration) : null;
    return <Box mb={3} mr={3} className="tile">
        <div className="tile-content">
            <div className="tile-content-header">
                <div className="left-column">
                    <Box mr={1.5}>
                        <img src={Images.Draft}/>
                    </Box>
                    <div className="status">
                        <div className="primary_black fs-16 poppins">{skills?.get(draft?.skillTagId)}</div>
                        <div className="fs-12 additional_grey_2">Draft</div>
                    </div>
                </div>
                {(draft.start && duration) && <div className="right-column additional_grey_1">
                    <div>{moment.utc(draft.start).local().format('DD MMM YYYY')}</div>
                    <div>
                        {moment.utc(draft.start).local().format('HH:mm')} –
                        {moment.utc(draft.start).local().add(duration.asSeconds(), 'seconds').format('HH:mm')} /
                        {duration.humanize()}
                    </div>
                </div>}
            </div>
            <div className="tile-content-body">
                <Box my={2.5} className="additional_grey_2">
                    {draft.description}
                </Box>
                <div className="stats">
                    {(draft.start && duration) && <div className="stat">
                        <div className="fs-12 additional_grey_2">
                            Price per hour
                        </div>
                        <div className="fs-16 primary_black">
                            {draft.price} SEK/h
                        </div>
                    </div>}
                    <div className="stat">
                        <div className="fs-12 additional_grey_2">
                            Total
                        </div>
                        <div className="fs-16 primary_black">
                            {roundAccurately(draft.price * duration.asHours(), 2)} SEK
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="tile-actions">
            {typeof onItemPublishClick === 'function'
                ? <Button
                    fullWidth
                    size="large"
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={() => onItemPublishClick(draft)}
                >
                    Publish
                </Button>
                : <div/>
            }
            <div>
                {typeof onItemEditClick === 'function' && <CreateOutlinedIcon onClick={()=>onItemEditClick(draft)}/>}
                {typeof onItemDeleteClick === 'function' && <DeleteOutlineOutlinedIcon onClick={()=>onItemDeleteClick(draft.id)}/>}
            </div>
        </div>
    </Box>;
};

export default DraftTile;
