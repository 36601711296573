import axios from 'axios';
import {v4} from 'uuid';

export const Send = async (params) => {
    if (typeof params === 'object' && typeof params.method === 'string' && params.method.length) {
        const host = typeof process.env.REACT_APP_PUBLIC_API_DOMAIN === 'string' && process.env.REACT_APP_PUBLIC_API_DOMAIN.length ? process.env.REACT_APP_PUBLIC_API_DOMAIN : null;
        if (host) {
            const token = window.localStorage.getItem('token');

            let r = null;
            const headers = {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': params?.headers ? params.headers : 'application/json',
            };

            // if(typeof params?.data === 'undefined' || typeof params?.data === 'object'){
            //
            // }

            const options = {
                headers,

            };

            if(params.responseType){
                options.responseType = params.responseType;
            }

            try{
                if (params?.data) {
                    r = await axios[params.method](host + params.url, params.data, options);
                } else {
                    r = await axios[params.method](host + params.url, options);
                }

                if(r && r.data){
                    return r.data;
                } else{
                    return null;
                }



            } catch (e) {
                //common ui error was shown earlier in axios interceptor
                //throw error so component can decide what to do
                //for example: show async validation errors
                throw e;
            }

        }
    }
    return false;
};

export const SendWithPagination = async (params) => {
    if (typeof params === 'object' && typeof params.method === 'string' && params.method.length) {
        const host = typeof process.env.REACT_APP_PUBLIC_API_DOMAIN === 'string' && process.env.REACT_APP_PUBLIC_API_DOMAIN.length ? process.env.REACT_APP_PUBLIC_API_DOMAIN : null;
        if (host) {
            const token = window.localStorage.getItem('token');

            let r = null;
            const headers = {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Content-Type': params?.headers ? params.headers : 'application/json',
            };

            // if(typeof params?.data === 'undefined' || typeof params?.data === 'object'){
            //
            // }

            const options = {
                headers,

            };

            if(params.responseType){
                options.responseType = params.responseType;
            }

            try{
                if (params?.data) {
                    r = await axios[params.method](host + params.url, params.data, options);
                } else {
                    r = await axios[params.method](host + params.url, options);
                }

                if(r){
                    return r;
                } else{
                    null;
                }



            } catch (e) {
                //common ui error was shown earlier in axios interceptor
                //throw error so component can decide what to do
                //for example: show async validation errors
                throw e;
            }

        }
    }
    return false;
};


// const stripeKey = typeof process.env.REACT_APP_STRIPE_API_KEY === 'string'
// && process.env.REACT_APP_STRIPE_API_KEY.length
//     ? process.env.REACT_APP_STRIPE_API_KEY
//     : null;
//
// const stripePromise = loadStripe(stripeKey);

export const configureAxios = (history, appDispatch) => {
    const host = typeof process.env.REACT_APP_PUBLIC_API_DOMAIN === 'string' && process.env.REACT_APP_PUBLIC_API_DOMAIN.length ? process.env.REACT_APP_PUBLIC_API_DOMAIN : null;

    if(host){
        axios.defaults.baseURL = host;

        axios.interceptors.response.use(async response => {
            return response;
        }, error => {
            if(error.response) {
                const {config, response: {status = null, data = null}} = error;

                if (status === 401) {
                    appDispatch({
                        type: 'addSnack',
                        payload: {
                            variant: 'error',
                            autoHideDuration: 30000,
                            message: 'Sorry your session is expired, please request new one.',
                        },
                    });
                    localStorage.removeItem('token');
                    window.location.replace('/');
                }

                if (status === 500) {
                    appDispatch({
                        type: 'addSnack',
                        payload: {
                            id: v4(),
                            variant: 'error',
                            autoHideDuration: 30000,
                            message: 'Ooops, something wrong.',
                        },
                    });
                }

                if (status === 400) {
                    appDispatch({
                        type: 'addSnack',
                        payload: {
                            id: v4(),
                            variant: 'error',
                            autoHideDuration: 30000,
                            message: data?.detail || data?.title || 'Sorry something went wrong, please try again later.',
                        },
                    });
                    if(typeof data?.errors !== 'undefined'){
                        const entries = Object.entries(data.errors);
                        if (entries.length)
                            for (const [key, value] of entries) {
                                value.forEach(item => {
                                    appDispatch({
                                        type: 'addSnack',
                                        payload: {
                                            id: v4(),
                                            variant: 'error',
                                            autoHideDuration: 30000,
                                            message: `${key}: ${item}`,
                                        },
                                    });
                                });

                            }
                    }

                    throw data;
                }

                if (status === 409) {
                    appDispatch({
                        type: 'addSnack',
                        payload: {
                            id: v4(),
                            variant: 'error',
                            autoHideDuration: 30000,
                            message: data?.detail || 'Sorry current session is expired, please request new one.',
                        },
                    });
                }

                if (status === 413) {
                    appDispatch({
                        type: 'addSnack',
                        payload: {
                            id: v4(),
                            variant: 'error',
                            autoHideDuration: 30000,
                            message: 'File too large for upload.',
                        },
                    });
                }

                if (status === 404) {
                }

                if (status === 503) {
                    appDispatch({
                        type: 'addSnack',
                        payload: {
                            id: v4(),
                            variant: 'error',
                            autoHideDuration: 30000,
                            message: 'Sorry service temporary unavailable, please try again later.',
                        },
                    });
                }

                return Promise.reject(error);
            }
        });


        axios.interceptors.request.use((config)=>{
            return config;
        });
    }
};

export const getGigs = async () => {
    const gigs = await Send({
        method: 'get',
        url: '/employers/me/active-gigs?page=0&size=100000',
    });
    return gigs && Array.isArray(gigs) ? gigs : [];
};

export const getMoonlighters = async () => {
    let method = 'get';
    let url = '/employers/me/moonlighters?page=0&size=1000';

    return await Send({
        method,
        url,
    });
};

export const getManagers = async () => {
    let method = 'get';
    let url = '/employers/me/managers?page=0&size=1000';

    return await Send({
        method,
        url,
    });
};

export const getGig = async (id) => {
    let method = 'get';
    let url = '/employers/me/gigs/' + id;

    return await Send({
        method,
        url,
    });
};

export const createPayment = async (contractId) => {
    let method = 'post';
    let url = `/employers/me/contracts/${contractId}/payment-attempts`;

    return await Send({
        method,
        url,
        data: {},
    });
};

export const getPaymentMethods = async (restaurantId) => {
    const methods = await Send({
        method: 'get',
        url: `/employers/me/restaurants/${restaurantId}/card-payment-methods`,
    });

    return methods || [];
};

export const getRestaurants = async () => await Send({
    method: 'get',
    url: '/employers/me/restaurants',
});

export const getSkills = async (language) => {
    const skills = await Send({
        method: 'get',
        url: `/skills?language=${language}`,
    });

    return skills && Array.isArray(skills) ? skills : [];
};

export const getEmployeeBindings = async (restrauntId) => {
    let method = 'get';
    let url = `/employers/me/restaurants/${restrauntId}/employee-bindings`;

    return await Send({
        method,
        url,
    });
};
