import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Divider } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import { v4 } from 'uuid';
import * as yup from 'yup';

import ApplicationsBanner from '../../../Components/ApplicationsBanner/ApplicationsBanner';
import OverlayLoader from '../../../Components/OverlayLoader/OverlayLoader';
import { AppContext } from '../../../Contexts/AppContext';
import { EmployerContext } from '../../../Contexts/EmployerContext';
import { Send } from '../../../Misc/backend';
import { IS_DESKTOP_MEDIA_QUERY } from '../../../Misc/constants';
import { Images } from '../../../static/images';

import './index.scss';

const ReportPage = () => {
    const [t] = useTranslation();
    const { appDispatch } = useContext(AppContext);
    const { employerState } = useContext(EmployerContext);
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState(null);
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);

    const validationSchema = yup.object({
        restaurantId: yup.string().required(t('restaurant_is_required')),
        startDate: yup.date().typeError('Must be a valid date').required(t('start_time_is_required')),
        endDate: yup.date().typeError('Must be a valid date').required(t('end_time_is_required')),
    });

    const formik = useFormik({
        initialValues: {
            startDate: moment().add(-1, 'day'),
            endDate: moment(),
            groupByEmployee: false,
            restaurantId: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const result = await getReport(values);
                if (result.byteLength > 0) {
                    const url = window.URL.createObjectURL(new Blob([result]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `Report_${values.startDate.format('YYYY-MM-DD')}_${values.endDate.format('YYYY-MM-DD')}.pdf`,
                    );
                    document.body.appendChild(link);
                    link.click();
                } else {
                    appDispatch({
                        type: 'addSnack',
                        payload: {
                            id: v4(),
                            variant: 'error',
                            autoHideDuration: 30000,
                            message: 'Sorry, it seems report is empty. Please change filter parameters and try again.',
                        },
                    });
                }
            } catch (e) {
                console.log(e);
            }
        },
    });

    useEffect(() => {
        async function loadReport(contractId) {
            setLoading(true);
            try {
                setReport(await getReport(contractId));
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }
        loadReport();
    }, []);

    useEffect(() => {
        if (employerState?.restaurants?.length === 1) {
            formik.setFieldValue('restaurantId', employerState.restaurants[0].id);
        }
    }, [employerState.restaurants]);

    return (
        <OverlayLoader loading={loading}>
            <Helmet>
                <title>GPG App - Report</title>
            </Helmet>
            <div className="page">
                <Box mb={2} className="page-header">
                    Report
                </Box>
                <div className="report">
                    <form onSubmit={formik.handleSubmit} className="card report-wrapper">
                        <Box mb={2} className="fs-24 bold primary_black">
                            {t('generate_report')}
                        </Box>
                        <Box my={2}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="restaurantId-label" color="secondary">
                                    {t('choose_restaurant')}
                                </InputLabel>
                                <Select
                                    fullWidth
                                    labelId="restaurantId-label"
                                    id="restaurantId"
                                    name="restaurantId"
                                    color="secondary"
                                    value={formik.values.restaurantId}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.restaurantId)}
                                    helperText={formik.errors.restaurantId}
                                    label={t('choose_restaurant')}
                                >
                                    <MenuItem value="">
                                        <em className="additional_grey_2">{t('choose_restaurant')}</em>
                                    </MenuItem>
                                    {employerState?.restaurants?.map((item) => {
                                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                                    })}
                                </Select>
                                {formik.errors.restaurantId && <FormHelperText error>{formik.errors.restaurantId}</FormHelperText>}
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: isDesktop ? 'row' : 'column' }}>
                            <Box my={2} sx={{ maxWidth: isDesktop ? 220 : 'unset' }}>
                                <FormControl variant="outlined" fullWidth>
                                    <KeyboardDatePicker
                                        autoOk
                                        id="startDate"
                                        format="YYYY-MM-DD"
                                        inputVariant="outlined"
                                        label={t('start_date')}
                                        color="secondary"
                                        value={formik.values.startDate}
                                        onChange={(value) => formik.setFieldValue('startDate', value)}
                                        error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                        helperText={formik.touched.startDate && formik.errors.startDate}
                                    />
                                </FormControl>
                            </Box>
                            <Box my={2} sx={{ maxWidth: isDesktop ? 220 : 'unset' }}>
                                <FormControl variant="outlined" fullWidth>
                                    <KeyboardDatePicker
                                        autoOk
                                        id="endDate"
                                        format="YYYY-MM-DD"
                                        inputVariant="outlined"
                                        label={t('end_date')}
                                        color="secondary"
                                        value={formik.values.endDate}
                                        onChange={(value) => formik.setFieldValue('endDate', value)}
                                        error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                        helperText={formik.touched.endDate && formik.errors.endDate}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                        <Box my={2}>
                            <FormControl variant="outlined" fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="groupByEmployee"
                                            name="groupByEmployee"
                                            inputVariant="outlined"
                                            color="secondary"
                                            value={formik.values.groupByEmployee}
                                            onChange={formik.handleChange}
                                            error={formik.touched.groupByEmployee && Boolean(formik.errors.groupByEmployee)}
                                            helperText={formik.touched.groupByEmployee && formik.errors.groupByEmployee}
                                        />
                                    }
                                    label={t('group_by_employee')}
                                />
                            </FormControl>
                        </Box>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            size="large"
                            className="align-right"
                            style={{ maxWidth: 220 }}
                        >
                            {t('download_report')}
                        </Button>
                    </form>
                    {isDesktop && <ApplicationsBanner/>}
                </div>

            </div>

        </OverlayLoader>
    );
};

const getReport = async ({ restaurantId, startDate, endDate, moonlighters = [], groupByEmployee = false }) => {
    if (endDate.isSame(moment(), 'day')) {
        endDate = moment();
    } else {
        endDate = endDate.startOf('day');
    }

    let method = 'get';
    let url = `/reports/employer/summary?format=pdf&restaurantId=${restaurantId}&from=${startDate.startOf('day').toISOString(false)}&to=${endDate.endOf('day').toISOString(false)}&groupByEmployee=${groupByEmployee}`;

    return await Send({
        method,
        url,
        responseType: 'arraybuffer',
    });
};

export default ReportPage;
