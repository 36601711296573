import React, {Fragment,useContext, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import {NavLink} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import {AppContext} from '../../Contexts/AppContext';
import { EmployerContext } from '../../Contexts/EmployerContext';
import {Send} from '../../Misc/backend';
import {IS_DESKTOP_MEDIA_QUERY} from '../../Misc/constants';
import restaurantTemplate from '../../Misc/restaurant';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import RestaurantTile from '../Restaurant/RestaurantTile';
import RestaurantAccordionItem from '../RestaurantAccordionItem/RestaurantAccordionItem';
import RestaurantForm from '../RestaurantForm/RestaurantForm';

import './EmployerRestaurantList.scss';



const EmployerRestaurantList = (props) => {
    const [loading, setLoading] = useState(false);
    const {employerState, employerDispatch} = useContext(EmployerContext);
    const {appState, appDispatch} = useContext(AppContext);
    const [restaurantFormVisible, setRestaurantFormVisible] = useState(false);
    const [editRestaurant, setEditRestaurant] = useState(restaurantTemplate);
    const [t] = useTranslation();
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);



    const onRestaurantClick = (restaurant) => {
        setEditRestaurant(restaurant);
        setRestaurantFormVisible(true);
    };

    const handleFormClose = () => {
        setEditRestaurant(restaurantTemplate);
        setRestaurantFormVisible(false);
    };

    const customActions = (restaurant) => {
        return  <Fragment>
            <CreateOutlinedIcon onClick={() => onRestaurantClick(restaurant)}/>
            <DeleteOutlineOutlinedIcon onClick={() => onItemDeleteClick(restaurant.id)}/>
        </Fragment>;
    };


    const onItemDeleteClick = async (id) => {
        try{
            setLoading(true);
            await deleteRestaurant(id);
            employerDispatch({
                type: 'setRestaurants',
                payload: employerState.restaurants.filter(item => item.id !== id),
            });
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };

    return (
        <OverlayLoader loading={loading}>
            <Helmet>
                <title>GPG App - Restaurants</title>
            </Helmet>
            <div className="page restaurants">
                <div className="page-header-with-actions">
                    <div className="poppins page-header primary_black">
                        {t('my_restaurants')}: <span className="additional_grey_3">{employerState?.restaurants?.length}</span>
                    </div>
                    <div className="actions">
                        <NavLink to="/restaurant/edit/new">
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => setRestaurantFormVisible(true)}
                                size="large"
                            >
                                <AddOutlinedIcon/>
                                {isDesktop && <>&nbsp;{t('add_a_restaurant')}</>}
                            </Button>
                        </NavLink>

                    </div>
                </div>

                <Dialog
                    open={restaurantFormVisible}
                    onClose={handleFormClose}
                    aria-labelledby="form-dialog-title"
                    fullScreen={!isDesktop}
                >
                    <RestaurantForm
                        entity={editRestaurant}
                        onClose={handleFormClose}
                    />
                </Dialog>
                <div className="tile-list">
                    {employerState?.restaurants?.map(restaurant => {
                        // return <RestaurantAccordionItem restaurant={restaurant} customActions={customActions(restaurant)} />;
                        return <RestaurantTile
                            restaurant={restaurant}
                            staticHost={appState.staticHost}
                            customActions={customActions(restaurant)}
                        />;
                    })}
                </div>
            </div>
        </OverlayLoader>
    );
};

const deleteRestaurant = async (id) => {
    let method = 'delete';
    let url = '/employers/me/restaurants/'+ id;

    return await Send({
        method,
        url,
    });
};

export default EmployerRestaurantList;
